import React, { useState, useEffect } from "react";
import {
  ModalHeader,
  ModalFooter,
  Modal,
  ModalBody,
  FormGroup,
} from "reactstrap";
import InputGroup from "../../comp/components/InputGroup";
import CustomButton from "../../comp/components/Button";
import { apiURL } from "../../../redux/actions";
import { _fetchApi, _postApi, _updateApi } from "../../../redux/actions/api";
import { _customNotify, formatNumber } from "../../utils/helpers";

function EditItemModal({
  isOpen = false,
  toggle = (f) => f,
  selected = {},
  getInventory = (f) => f,
  getInventory2 = (f) => f,
}) {
  const [form, setForm] = useState({});
  // const [disabling, setDisabling] = useState()
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (selected) {
      setForm(selected);
    }
  }, [selected]);

  const handleInputChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  //     const disableProduct = () => {
  // setDisabling(true)

  // _updateApi(`${apiURL}/procurement/disable-product`, { })
  //     }

  const handleUpdateClick = () => {
    setUpdating(true);
    // console.log(item);
    if (form.new_price !== "") {
      let formdata = new FormData();
      Object.keys(form).forEach((i) => formdata.append(i, form[i]));
      if (form.item_image_upload && form.item_image_upload.length) {
        formdata.append("itemImage", form.item_image_upload[0]);
      }
      formdata.append("query_type", "update qty");
      // if (form.new_balance !== "" && form.new_price !== "") {

      fetch(`${apiURL()}/procurement/update-intentory-price`, {
        method: "POST",
        // headers: { 'Content-Type': 'multipart/formdata' },
        body: formdata,
      })
        .then((raw) => raw.json())
        .then((resp) => {
          //  alert('asdfsdf')
          setUpdating(false);
          toggle();
          console.log(resp);
          _customNotify("Item Updated");
          getInventory();
          getInventory2();
        })
        .catch((err) => {
          console.log(err);
        });

      // _postApi(
      //   `${apiURL()}/procurement/update-intentory-price`,
      //   { ...form, , new_balance: form.balance },
      //   (resp) => {
      //       // alert('asdfsdf')
      //       setUpdating(false)
      //       toggle()
      //     console.log(resp);
      //     _customNotify("Item Updated");
      //     getInventory();
      //     getInventory2();
      //   },
      //   (err) => console.log(err)
      // );
    }
  };

  return (
    <CustomModal
      header={`Edit ${form.item_name}`}
      isOpen={isOpen}
      toggle={toggle}
      footer={
        <>
          {/* <CustomButton color='danger' loading={disabling} onClick={disableProduct}>
          Disable Product
        </CustomButton> */}
          <CustomButton loading={updating} onClick={handleUpdateClick}>
            Update
          </CustomButton>
        </>
      }
    >
      {/* {JSON.stringify(form)} */}
      <div className="row">
        {/* {JSON.stringify(form)} */}

        {/* <InputGroup container='col-md-6' label='Available Quantity' name='new_balance' onChange={handleInputChange} value={form.new_balance} /> */}

        {/* <InputGroup container='col-md-6' label='' name='item' onChange={handleInputChange} value={form.item} /> */}
        <FormGroup className="col-md-6">
          <div>
            <img
              src={selected.item_image}
              alt="item_image"
              // height={200}
              width={200}
            />
          </div>
          <label>
            Update Item Image
            <input
              type="file"
              // className="form-control"
              name="item_image_upload"
              // value={group_code}
              onChange={(e) => {
                // console.log(e.target.files)
                setForm((p) => ({ ...p, item_image_upload: e.target.files }));
              }}
              accept="image/*"
            />
          </label>
        </FormGroup>
        <div className="col-md-6">
          <InputGroup
            container="col-md-12"
            label="Selling Price"
            name="new_price"
            onChange={handleInputChange}
            value={form.new_price}
          />
          <InputGroup
            container="col-md-12"
            label="Quantity per Case"
            name="new_balance"
            type="number"
            onChange={handleInputChange}
            value={form.new_balance}
          />
          <InputGroup
            container="col-md-12"
            label={`Unit Quantity (${formatNumber(
              (form.new_balance ? form.new_balance : form.available_quantity) *
                (parseInt(form.unit) ? parseInt(form.unit) : 1)
            )} )`}
            name="unit"
            type="number"
            onChange={handleInputChange}
            value={form.unit}
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default EditItemModal;

function CustomModal(props) {
  const {
    header = null,
    footer = null,
    isOpen = false,
    toggle = (f) => f,
  } = props;
  return (
    <Modal {...props}>
      <ModalHeader toggle={toggle}>{header}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
}

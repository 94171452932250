const allModule = {
  inventory: {
    name: "Stock Management",
    type: [
      "SKUs",
      "Manage Vendor",
      // "Store Record",
      "Good Transfer Form",
      "GRN",
      "Dashboard",
      // "Purchase Order",
      // "Internal Transfer",
    ],
  },

  account: {
    name: "Accounts",
    type: [
      // "Hospital Billings",
      "Create a Client Account",
      "Make Deposit",
      "Record Expenses",
      "Generate Account Report",
      "Account Statement",
      "Asset Register",
      "Rent Register",
      "Create/Edit Services",
      "Setup Account Chart",
      "Setup Transactions",
      "Cash Handover",
      // "Budget Module",
      "Bank Account Creation Form",
      "Purchase Record",
      "Update Balance",
    ],
  },
  admin: {
    name: "Admin",
    type: ["Create User", "Manage Admin", "Manage Users", "Settings"],
  },
  content: {
    name: "Content Management",
    type: ["Manage Blog", "Manage News"]
  },
  reports: {
    name: "Reports",
    type: [
      "Report Summary",
      "Inventory Overview",
      "Analytics",
      "Daily Sales",
      "Profit",
      "Suppliers Overview",
      "Customers Overview",
      "Sales on Credit Report",
      "Profit",
      "Expenses Breakdown",
    ],
  },
  // inventory: {
  //   name: "Inventory",
  //   type: ["Store Management", "Requisition", "Manage Suppliers"],
  // },
  administration: {
    name: "Administration",
    type: ["Purchase Order", "Item Description", "SKUs"],
  },
  engineering: {
    name: "Engineering",
    type: [
      "Equipment Registration Form",
      "Equipment Repairs Form",
      "Equipment Installation Form",
      "Invoice",
    ],
  },

  customer: {
    name: "Sales",
    type: [
      "Create Customer Account",
      "Customer Stock Balance",
      "Returned Item",
      // "Generate Receipt",
      // "Requisition List",
    ],
  },
  management: {
    name: "Executive",
    type: ["Pending Purchase Order","Pending Request"],
  },
  // audit: {
  //   name: "Audit",
  //   type: [
  //     "Management Dashboard",
  //     "Pending Expenses Requisition",
  //     "Pending Purchase Order",
  //   ],
  // },
  // reviewer: {
  //   name: "Reviewer",
  //   type: ["Pending Expenses Requisition", "Pending Purchase Order"],
  // },
};

export default allModule;

import React, { useState } from 'react'
import { Card, CardHeader, CardBody, Input, Row, Col } from 'reactstrap'
import CustomButton from '../comp/components/Button'
import InputGroup from '../comp/components/InputGroup'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Dropzone from 'react-dropzone-uploader'
import { apiURL } from '../../redux/actions'
import { _customNotify } from '../utils/helpers'
import { useSelector } from 'react-redux'

function NewBlog({ history }) {
  const user = useSelector((state) => state.auth.user)
  const [form, setForm] = useState({
    title: '',
    slug: '',
    body: '',
    type: 'Blog',
  })
  const [loading, setLoading] = useState(false)
  const [banner, setBanner] = useState({})
  const [mediaArray, setMediaArray] = useState([])

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }))

  //   // specify upload params and url for your files
  //   const getUploadParams = ({ meta }) => {
  //     console.log(meta)
  //     // return { url: 'https://httpbin.org/post' }
  //   }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    // console.log(status, meta, file)
    setMediaArray((p) => [...p, file])
  }

  //   // receives array of files that are done uploading when submit button is clicked
  //   const handleSubmit = (files, allFiles) => {
  //     console.log(files.map((f) => f.meta))
  //     allFiles.forEach((f) => f.remove())
  //   }

  const handleSubmit = () => {
    setLoading(true)
    let formData = new FormData()
    Object.keys(form).forEach((k) => formData.append(k, form[k]))
    formData.append('banner', banner)
    formData.append('created_by', user.username)

    fetch(`${apiURL()}/media/blog/new`, {
      method: 'POST',
      body: formData,
    })
      .then((raw) => raw.json())
      .then((d) => {
        if (mediaArray.length) {
          let mediaData = new FormData()
          mediaArray.forEach((m) => mediaData.append('files', m))
          mediaData.append('blog_id', d.results.blog_id)

          fetch(`${apiURL()}/media/content-media/new`, {
            method: 'POST',
            body: mediaData,
          })
            .then((raw) => raw.json())
            .then((md) => {
              setLoading(false)
              if (md && md.success) {
                _customNotify('Content Submitted Successfully')
                history.push('/me/content-management/blogs')
              }
            })
            .catch((e) => {
              setLoading(false)
              console.log(e)
            })
        } else {
          setLoading(false)
          if (d && d.success) {
            _customNotify('Content Submitted Successfully')
            history.push('/me/content-management/blogs')
          }
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  return (
    <Card>
      <CardHeader tag="h4">New Blog</CardHeader>
      <CardBody>
        {/* {JSON.stringify(user)} */}
        <Row className="m-0">
          <Col md={6} className="p-0">
            <InputGroup
              container="col-md-12"
              label="Title"
              name="title"
              value={form.title}
              onChange={({ target: { name, value } }) =>
                setForm((p) => ({
                  ...p,
                  [name]: value,
                  slug: value.toLowerCase().split(' ').join('-'),
                }))
              }
            />
          </Col>
          <Col md={6} className="p-0">
            <InputGroup
              container="col-md-12"
              label="Short Description"
              name="description"
              value={form.description}
              onChange={handleChange}
            />
          </Col>

          <Col md={12}>
            <label className="font-weight-bold">Content</label>
            <CKEditor
              editor={ClassicEditor}
              data={form.body}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor)
              }}
              onChange={(event, editor) => {
                const data = editor.getData()
                // console.log({ event, editor, data })
                setForm((p) => ({ ...p, body: data }))
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor)
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor)
              }}
            />
          </Col>
          <Col className="my-2">
            <label className="font-weight-bold">Post Banner</label>
            <Input
              type="file"
              onChange={({ target: { files } }) => setBanner(files[0])}
            />
          </Col>
          <Col className="my-2">
            <label className="font-weight-bold">Other Attachments</label>
            <Dropzone
              //   getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              //   onSubmit={handleSubmit}
              accept="image/*,audio/*,video/*"
            />
          </Col>
        </Row>

        <center className="mt-1">
          <CustomButton loading={loading} onClick={handleSubmit}>
            Submit
          </CustomButton>
        </center>
      </CardBody>
    </Card>
  )
}

export default NewBlog

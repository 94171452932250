import React  from "react";
import { Table, Badge, Row, Col } from "reactstrap";
import SearchBar from "../utils/SearchBar";

function PendingExpensises(props) {
  return (
    <div className="mt-2">
      <Row>
        <Col md={12}>
          <SearchBar />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table>
            <thead>
              <tr>
                <th>Request Date</th>
                <th>Request No</th>
                <th>Total Amount</th>
                <th>Status</th>
                <th>Remark</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>Otto</td>
                <td>
                  <Badge
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setstate(true);
                    }}
                  >
                    Process
                  </Badge>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

export default PendingExpensises;

import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Card, CardBody, CardHeader, Button } from 'reactstrap'
import { getRequisitionList } from '../../redux/actions/pharmacy'
import PendingReqList from './PendingReqList'
import Loading from '../comp/components/Loading'
import { _fetchApiGeneral, _updateApi } from '../../redux/actions/api'
import { apiURL } from '../../redux/actions'
import SearchBar from '../utils/SearchBar'
import { Route, Switch, useHistory, useLocation } from 'react-router'
import AccountEntriesDailySummary from '../account/account-entries/AccountEntriesDailySummary'
import AccountEntriesDetails from '../account/account-entries/AccountEntriesDetails'
import { getOnlineRequest, getRequestList } from '../../redux/actions/account'
import { _customNotify } from '../utils/helpers'

export default function OnlineRequest() {
  const user = useSelector((state) => state.auth.user.role)

  const dispatch = useDispatch()
  const [loading, setLoading] = useState('')
  const [preveiw, setPreview] = useState(false)
  const [, setRoles] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const location = useLocation()
  const history = useHistory()

  // const getUserRoles = useCallback(() => {
  //   let roles = user;
  //   // let newArr = [];
  //   _fetchApiGeneral(
  //     `${apiURL()}/account/users/status/roles?roles=${roles}`,
  //     ({ results }) => {
  //       // results.forEach((item) => newArr.push(item.user_status));
  //       // let final = newArr.splice("' ',");
  //       let newRes = results[0].user_status;
  //       console.log(results[0].user_status, "KDKDKDK");
  //       setRoles(newRes);

  //       getAllItemsBySub(newRes);
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }, [user]);

  const requisitionData = useSelector((state) => state.pharmacy.requisitionList)

  // const getAllItemsBySub = (_role) => {
  //   const callback = () => {
  //     console.log("NHGFD");
  //     setLoading(false);
  //   };
  //   dispatch(
  //     getRequisitionList(
  //       _role,
  //       null,
  //       user === "Admin"
  //         ? "all"
  //         : user === "Warehouse Manager"
  //         ? "warehouse"
  //         : user === "Dispatch"
  //         ? "dispatch"
  //         : "get",
  //       callback
  //     )
  //   );
  // };

  useEffect(() => {
    dispatch(getOnlineRequest())
  }, [dispatch])

  const deleteRequisition = (req_id) => {
    _updateApi(
      `${apiURL()}/account/branch_req_requisition/single-update`,
      {
        req_status: '',
        requisition_no: req_id,
        query_type: 'delete',
      },
      () => {
        _customNotify(`Request with ID: ${req_id} deleted successfully`)
        dispatch(getRequestList())
        console.log('success')
      },
      (err) => console.log(err),
    )
  }

  let rows = []
  requisitionData &&
    requisitionData.forEach((item, index) => {
      if (
        item.branch_name.toLowerCase().indexOf(searchTerm.toLowerCase()) ===
          -1 &&
        item.req_status.toLowerCase().indexOf(searchTerm.toLowerCase()) ===
          -1 &&
        item.requisition_no
          .toString()
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) === -1
      )
        return
      rows.push(
        <tr
          key={index}
          style={{
            backgroundColor:
              item.req_status === 'pending'
                ? '#f37021'
                : item.req_status.includes('Sale agent approved')
                ? 'blue'
                : item.req_status === 'Approved' ||
                  item.req_status === 'Incomplete Issued'
                ? '#d55d5d'
                : item.req_status.includes('Dispatched')
                ? '#00e600'
                : item.req_status.includes('Delivered')
                ? '#009900'
                : item.req_status.includes('Received')
                ? '#FFCC00'
                : item.req_status.includes('Completed')
                ? '#006600'
                : '',
            color: 'white',
          }}
        >
          <td className="text-center">{item.requisition_no}</td>
          <td>{item.branch_name}</td>
          <td className="text-center">{item.item_name}</td>
          <td>
            {item.req_status === 'Approved'
              ? 'Warehouse Approved'
              : item.req_status}
          </td>
          <td className="text-center">
            <Button
              size="sm"
              color="dark"
              onClick={() => {
                if (item.req_status === 'Sale agent approved') {
                  history.push(
                    `/me/inventory/pending-requests?requisition_no=${item.requisition_no}&shopName=${item.branch_name}&show_items_requested=true`,
                  )
                } else {
                  history.push(
                    `/me/auditor/pending-request/view?requisition_no=${item.requisition_no}&shopName=${item.branch_name}&status=${item.req_status}`,
                  )
                }
              }}
            >
              View
            </Button>
            <Button
              size="sm"
              color="danger"
              className="ml-1"
              onClick={() => {
                deleteRequisition(item.requisition_no)
              }}
            >
              Delete
            </Button>
          </td>
        </tr>,
      )
    })

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="text-center">
          Request List
        </CardHeader>
        <CardBody>
          <Switch>
            <Route exact path="/me/auditor/online-request">
              {loading ? <Loading /> : null}
              <SearchBar
                filterText={searchTerm}
                onFilterTextChange={(searchTerm) => setSearchTerm(searchTerm)}
                placeholder="Search by Shop name, status and request no."
              />
              <Table bordered striped size="sm">
                <thead>
                  <tr>
                    <th className="text-center">Requisition No</th>
                    <th className="text-center">Customer Name</th>
                    <th className="text-center">Quantity Requested </th>
                    <th className="text-center">Status </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            </Route>
            <Route exact path={`/me/auditor/pending-request/view`}>
              <PendingReqList getAllItemsBySub={''} />
            </Route>
            <Route exact path={`/me/auditor/pending-request/account-summary`}>
              <AccountEntriesDailySummary />
            </Route>
            <Route exact path={`/me/auditor/pending-request/account-details`}>
              <AccountEntriesDetails />
            </Route>
          </Switch>
        </CardBody>
      </Card>
    </>
  )
}

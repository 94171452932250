import React, { useState } from "react"
import {Card, CardBody, CardHeader, Form, FormGroup} from 'reactstrap'
import { Switch } from "evergreen-ui";

export default function NetworkSetting () {
    const [state, setState] = useState(false)
const handleChange = (e) => {
    setState(!state)
}
    return (
        <Card>
            <CardHeader tag="h">
            Network Configuration
            </CardHeader>
            <CardBody>
            <Switch height={24} checked 
            onChange={handleChange}
            />
            {state ?  
            <Form>
              <FormGroup row>
                <div className="col-md-12 col-lg-12">
                  <label>Ip address</label>
                  <input
                    className="form-control"
                    type="text"
                    name="firstname"
                    autoFocus
                    // value={firstname}
                    // onChange={handleChange}
                  />
                </div>
                </FormGroup>
            </Form> : null}
            </CardBody>
        </Card>
    )
}
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Card, Table, CardHeader, CardBody } from 'reactstrap'
import { apiURL } from '../../redux/actions'
import { _fetchApiGeneral } from '../../redux/actions/api'
import CustomButton from '../comp/components/Button'

function ManageNews({ history }) {
  const [blogList, setBlogList] = useState([])

  const getBlogs = () => {
    _fetchApiGeneral(
      `${apiURL()}/media/blog/list?query_type=all_news`,
      (d) => {
        if (d && d.results && d.results.length) {
          setBlogList(d.results)
        }
      },
      (e) => {
        console.log(e)
      },
    )
  }

  useEffect(() => {
    getBlogs()
  }, [])

  return (
    <Card>
      <CardHeader>Manage News</CardHeader>
      <CardBody>
        <CustomButton
          onClick={() => history.push('/me/content-management/news/new')}
        >
          Add New
        </CustomButton>
        {/* {JSON.stringify(blogList)} */}
        <Table size="sm" bordered className="mt-2">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Date</th>
              <th>Title</th>
              <th>Tags</th>
              <th>Likes</th>
              <th>Comments</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {blogList.map((a, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{moment(a.created_at).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{a.title}</td>
                  <td>{a.tags}</td>
                  <td className="text-center">{a.comments}</td>
                  <td className="text-center">{a.likes}</td>
                  <td className="text-center">
                    <CustomButton size="sm" className="mr-1" color="success">
                      Preview
                    </CustomButton>
                    <CustomButton size="sm" className="mr-1" color="primary">
                      Edit
                    </CustomButton>
                    <CustomButton size="sm" color="danger">
                      Delete
                    </CustomButton>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default ManageNews

import React from "react";
import { Carousel } from "react-responsive-carousel";
import LandingNavbar from "./LandingNavbar"
import logo1 from "../../images/logo1.png"
import logo2 from "../../images/simpleCollection.jpg"
import logo3 from "../../images/callWitDoc.jpg"

function Home() {
    return (
        <div>
            <LandingNavbar />
            <Carousel height="200000px">
                <div>
                    <img src={logo1}  />
                </div>
                <div>
                    <img src={logo2}  />
                </div>
                <div>
                    <img src={logo3} />
                </div>
            </Carousel>
        </div>
    )
}

export default Home
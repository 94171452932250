import React, { useState } from "react";
import { Button } from "reactstrap";
import { MdClose, MdEdit, MdViewList } from "react-icons/md";
import { useHistory, useLocation } from "react-router";
import {
  Table,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import SearchBar from "../utils/SearchBar";
import { formatNumber, _warningNotify } from "../utils/helpers";
import { IoMdCheckmarkCircle } from "react-icons/io";
import Scrollbars from "react-custom-scrollbars";

import { Textarea } from "evergreen-ui/commonjs/textarea";
import { _postApi } from "../../redux/actions/api";
import { apiURL } from "../../redux/actions";
import { useSelector } from "react-redux";
import { approvedStatus, pendingStatus, rejectedStatus } from "../utils/util";
import DaterangeSelector from "../comp/components/DaterangeSelector";

function ExpensiveTable({
  getExpenses,
  _updateStatusApprove,
  _updateStatusReject,
  _updateStatusAudited,
  _updateStatusAuditorReject,
  _updateStatusAcct,
  _updateStatusReviewed,
  _updateStatusReviewerReject,
  handleChange = (f) => f,
  form = {},
}) {
  const history = useHistory();
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [requestNo, setRequestNo] = useState("");

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [generalRemarks, setGeneralRemarks] = useState("");
  const { from, to } = form;

  const facilityId = useSelector((state) => state.facility.info.facility_id);

  const handleSubmitRemarks = (remarks_by) => {
    const callBack = (res) => {
      console.log(res);
    };

    const error = (err) => {
      _warningNotify("Error Occur");
      console.log(err);
    };

    const data = [];
    getExpenses.forEach((item) => {
      data.push({
        remarks_id: item.remarks_id + 1231,
        request_no: item.request_no,
        remarks: "",
        remarks_by,
        date: item.date,
        general_remarks: generalRemarks,
        facilityId,
      });
    });
    _postApi(
      `${apiURL()}/account/add-new/expenses-remarks-general`,
      data[0],
      callBack,
      error
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  let rows = [];
  getExpenses.length &&
    getExpenses.forEach((item, i) => {
      if (
        item.type_of_expenses &&
        item.type_of_expenses
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) === -1 &&
        item.request_no.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1
      )
        return;
      const expensesIsPending = item.status;
      rows.push(
        <tr
          key={i}
          style={{
            backgroundColor:
              expensesIsPending === "Pending"
                ? pendingStatus
                : expensesIsPending === "Approved"
                ? approvedStatus
                : expensesIsPending === "Auditor Rejected"
                ? rejectedStatus
                : expensesIsPending === "Disburse Money"
                ? approvedStatus
                : expensesIsPending === "Admin Resend"
                ? pendingStatus
                : expensesIsPending === "Rejected"
                ? rejectedStatus
                : expensesIsPending === "Acct_Audit"
                ? rejectedStatus
                : expensesIsPending === "Acct_Management"
                ? rejectedStatus
                : "#ccffcc",
          }}
        >
          <td>{item.date}</td>
          <td>{item.month}</td>
          <td>{item.request_no}</td>
          <td>{item.branch_name}</td>
          <td>{item.type_of_expenses}</td>
          <td className="text-right">{formatNumber(item.total)}</td>
          {/* <td>{item.status}</td> */}
          {location.pathname === `/me/purchase-order/expense` ? (
            <td className="d-flex">
              <button
                size="sm"
                className="mr-2 btn btn-primary"
                onClick={() =>
                  history.push(
                    `/me/purchase-order/expense/form/view/${item.request_no}`
                  )
                }
                data-toggle="tooltip"
                data-placement="top"
                title="View Expenses Requisition"
              >
                <MdViewList />
              </button>
              {item.status === "Pending" ||
              item.status === "Auditor Rejected" ||
              item.status === "Rejected" ? (
                <Button
                  size="sm"
                  className="mr-2 btn btn-success"
                  onClick={() =>
                    history.push(
                      `/me/purchase-order/expense/form/${item.request_no}`
                    )
                  }
                >
                  <MdEdit />
                  Edit
                </Button>
              ) : null}
            </td>
          ) : location.pathname === "/me/auditor/expenses" ? (
            <td>
              <button
                size="sm"
                className="mr-2 btn btn-primary"
                onClick={() =>
                  history.push(`/me/auditor/expenses/view/${item.request_no}`)
                }
                data-toggle="tooltip"
                data-placement="top"
                // title="View Expenses Requisition"
              >
                <MdViewList />
              </button>

              <button
                size="sm"
                className="mr-2 btn btn-success"
                onClick={() => _updateStatusAudited(item.request_no)}
                data-toggle="tooltip"
                data-placement="top"
                // title="Audited Expenses Requisition"
              >
                <IoMdCheckmarkCircle size="15" />
              </button>
              <button
                size="sm"
                className="mr-2 btn btn-danger"
                onClick={() => _updateStatusAuditorReject(item.request_no)}
                data-toggle="tooltip"
                data-placement="top"
                // title="Reject Expenses Requisition"
              >
                <MdClose size="15" />
              </button>
            </td>
          ) : location.pathname === "/me/auditorr/expenses" ? (
            <td className="d-flex">
              <button
                size="sm"
                className="mr-2 btn btn-primary"
                onClick={() =>
                  history.push(`/me/auditorr/expenses/view/${item.request_no}`)
                }
                data-toggle="tooltip"
                data-placement="top"
                title="View Expenses Requisition"
              >
                <MdViewList />
              </button>
              <button
                size="sm"
                className="mr-2 btn btn-success"
                onClick={() => _updateStatusApprove(item.request_no)}
                data-toggle="tooltip"
                data-placement="top"
                title="Approved Expenses Requisition"
              >
                <IoMdCheckmarkCircle size="15" />
              </button>
              <button
                size="sm"
                className="mr-2 btn btn-danger"
                onClick={() => _updateStatusReject(item.request_no)}
                data-toggle="tooltip"
                data-placement="top"
                title="Reject Expenses Requisition"
              >
                <MdClose size="15" />
              </button>
            </td>
          ) : location.pathname === "/me/account/expenditure" ? (
            <td className="d-flex">
              <button
                size="sm"
                className="mr-2 btn btn-primary"
                onClick={() =>
                  history.push(
                    `/me/account/expenditure/view/${item.request_no}`
                  )
                }
                data-toggle="tooltip"
                data-placement="top"
                title="View Expenses Requisition"
              >
                <MdViewList />
              </button>
              <UncontrolledButtonDropdown size="sm" className="mr-1 btn-danger">
                <DropdownToggle caret size="sm" color="warning">
                  <small>Send to</small>
                </DropdownToggle>
                <DropdownMenu size="sm">
                  <DropdownItem
                    size="sm"
                    onClick={() => {
                      toggle();
                      setRequestNo(item.request_no);
                    }}
                  >
                    <small>Auditor</small>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    size="sm"
                    onClick={() => {
                      toggle1();
                      setRequestNo(item.request_no);
                    }}
                  >
                    <small>Management</small>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              <Button
                size="sm"
                color="primary"
                onClick={() =>
                  history.push(
                    `/me/account/expenditure/form/${item.request_no}`
                  )
                }
              >
                <small>Payment</small>
              </Button>
            </td>
          ) : location.pathname === "/me/reviewer/expenses" ? (
            <td>
              <button
                size="sm"
                className="mr-2 btn btn-primary"
                onClick={() =>
                  history.push(`/me/reviewer/expenses/${item.request_no}`)
                }
                data-toggle="tooltip"
                data-placement="top"
                // title="View Expenses Requisition"
              >
                <MdViewList />
              </button>
              <button
                size="sm"
                className="mr-2 btn btn-success"
                onClick={() => _updateStatusReviewed(item.request_no)}
                data-toggle="tooltip"
                data-placement="top"
                // title="Audited Expenses Requisition"
              >
                <IoMdCheckmarkCircle size="15" />
              </button>
              <button
                size="sm"
                className="mr-2 btn btn-danger"
                onClick={() => _updateStatusReviewerReject(item.request_no)}
                data-toggle="tooltip"
                data-placement="top"
                // title="Reject Expenses Requisition"
              >
                <MdClose size="15" />
              </button>
            </td>
          ) : null}
        </tr>
      );
    });

  let total =
    getExpenses.length &&
    getExpenses.reduce((a, b) => a + parseFloat(b.total), 0);

  return (
    <div>
      <Card>
        <CardHeader tag="h5" className="text-center">
          Expenses Requisition Table
        </CardHeader>
        <CardBody>
          {/* {JSON.stringify({ from, to })} */}
          <Row>
            <Col md={12}>
              <DaterangeSelector
                from={from}
                to={to}
                handleChange={handleChange}
              />
              <SearchBar
                filterText={searchTerm}
                onFilterTextChange={(searchTerm) => setSearchTerm(searchTerm)}
                placeholder="Search by type of expenses or request no"
              />
            </Col>
          </Row>
          <h5 className="text-right">Total: ₦{formatNumber(total)}</h5>
          <Scrollbars style={{ height: "70vh" }} autoHide>
            <Table hover striped bordered>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Month</th>
                  <th>Request No</th>
                  <th>Branch</th>
                  <th>Type Of Expenses</th>
                  <th>Total Amount (₦)</th>
                  {/* <th>Status</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </Scrollbars>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Send it back to Auditor </ModalHeader>
        <ModalBody>
          <Label>Reason</Label>
          <Textarea
            type="text"
            name="quantity"
            value={generalRemarks}
            onChange={(e) => setGeneralRemarks(e.target.value)}
            placeholder="Why sending it back to Auditor"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggle();
              _updateStatusAcct(requestNo, "Acct_Audit");
              handleSubmitRemarks("Accountant-auditor");
            }}
          >
            Submit
          </Button>
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal1} toggle={toggle1}>
        <ModalHeader toggle={toggle1}>Send it back to Management </ModalHeader>
        <ModalBody>
          {/* {JSON.stringify(getExpenses)} */}
          <Label>Reason</Label>
          <Textarea
            placeholder="Why sending it back to management"
            type="text"
            name="quantity"
            value={generalRemarks}
            onChange={(e) => setGeneralRemarks(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggle1();
              _updateStatusAcct(requestNo, "Acct_Management");
              handleSubmitRemarks("Accountant-Management");
            }}
          >
            Submit
          </Button>
          <Button color="danger" onClick={toggle1}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ExpensiveTable;

import React, { lazy, useEffect } from 'react'
import { MdAccountBalance } from 'react-icons/md'
import VerticalMenu from '../comp/components/vertical-menu/VerticalMenu'
import ListMenuItem from '../comp/components/vertical-menu/ListMenuItem'
import { Route, Switch } from 'react-router'
import { Col, Row } from 'reactstrap'
import { FiCast, FiShoppingCart } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { canUseThis } from '../auth'
import { useHistory, useLocation } from 'react-router'
import { BsArrowReturnLeft } from 'react-icons/bs'
// import { RiFilePaperLine } from "react-icons/ri";
// import { GiPayMoney } from "react-icons/gi";
import { Suspense } from 'react'
import FullscreenLoading from '../comp/components/FullscreenLoading'
import RequisitionList from './RequisitionList'
import AddClient from '../account/AddClient'
// import CustomerCreditLimit from "./CustomerCreditLimit";
import CreditDetails from './CreditDetailsContainer'
import CustomerInventoryHistory from './CustomerInventoryHistory'
import CustomerOverview from '../pharmacy/dashboard/CustomerOverview'
const TotalSummary = lazy(() => import('../report/TotalSummary'))
const ItemsList2pos = lazy(() => import('../pharmacy/ItemsList2pos'))
const GenerateReceipt = lazy(() => import('../pharmacy/GenerateReceipt'))
const PointOfSales = lazy(() => import('../account/PointOfSales'))
const ReturnedDrugs = lazy(() => import('../pharmacy/ReturnedDrugs'))
const BranchRequisition = lazy(() => import('../pharmacy/BranchRequisition'))
const InvertoryOverView = lazy(() => import('../report/InventoryOverview'))
// const PatientDeposit = lazy(() => import("../account/PatientDeposit"));

const path = '/me/business'

export default function BusinessDashboard() {
  const user = useSelector((state) => state.auth.user)

  const history = useHistory()
  const location = useLocation()

  const navigateUser = (user) => {
    if (!location.pathname.includes('customer-stock-balance')) {
      // let functionality = user.functionality;
      // const acc = [
      //   "My Sales",
      //   "Generate Receipt",
      //   "Returned Item",
      //   "Sales Department",
      //   "Requisition List",
      // ];

      // let sel = null;
      // acc.forEach((i) => {
      //   if (functionality && functionality.findIndex((it) => it === i) !== -1)
      //     sel = i;
      //   return;
      // });
      // console.log(sel);
      // switch (sel) {
      //   case "My Sales":
      //     return history.push(`${path}/my-sales-summary`);
      //   case "Generate Receipt":
      //     return history.push(`${path}/receipt`);
      //   case "Returned Item":
      //     return history.push(`${path}/returned-drugs`);
      //   case "Sales Department":
      //     return history.push(`${path}/sale`);
      //   case "Requisition List":
      //     return history.push(`${path}/requisition-list`);
      //   default:
      return history.push(`${path}/create-customer-account`)
      // }
    }
  }

  useEffect(() => {
    if (user) {
      navigateUser(user)
    }
  }, [user])

  return (
    <>
      <Row className="m-0 p-0">
        <Col md={3}>
          <BusinessMenu user={user} />
        </Col>
        <Col md={9}>
          <BusinessRoute user={user} />
        </Col>
      </Row>
    </>
  )
}

function BusinessRoute({ user }) {
  return (
    <Switch>
      {/* <Redirect exact from={`${path}`} to={`${path}/sale`} /> */}
      {/* {user.accessTo
          ? canUseThis(user, ["Sales Department"]) && ( */}
      <Route path={`${path}/create-customer-account`} component={AddClient} />
      {/* )
           : null} */}

      {/* {user.accessTo
          ? canUseThis(user, ["Sales Department"]) && ( */}
      <Route
        exact
        path={`${path}/customer-stock-balance`}
        component={InvertoryOverView}
      />
      <Route
        exact
        path={`${path}/customer-stock-balance/history`}
        component={CustomerInventoryHistory}
      />
      {/* )
           : null} */}
      {user.accessTo
        ? canUseThis(user, ['Sales Department']) && (
            <Route path={`${path}/sale`} component={PointOfSales} />
          )
        : null}
      {user.accessTo
        ? canUseThis(user, ['Generate Receipt']) && (
            <Route path={`${path}/receipt`} component={GenerateReceipt} />
          )
        : null}
      {user.accessTo
        ? canUseThis(user, ['Transfer to POS']) && (
            <Suspense fallback={<FullscreenLoading />}>
              <Route path={`${path}/transfer-pos`} component={ItemsList2pos} />
            </Suspense>
          )
        : null}
      {user.accessTo
        ? canUseThis(user, ['My Sales']) && (
            <Suspense fallback={<FullscreenLoading />}>
              <Route
                path={`${path}/my-sales-summary`}
                render={() => <TotalSummary mode="perUser" />}
                exact
              />
            </Suspense>
          )
        : null}
      {user.accessTo
        ? canUseThis(user, ['Sales Department']) && (
            <Route
              path={`/me/business/requisition/req`}
              component={BranchRequisition}
              exact
            />
          )
        : null}
      <Route
        path={`/me/business/recovery-list`}
        render={(props) => <CustomerOverview {...props} showFull={true} />}
        exact
      />
      <Route
        path={`/me/business/customer-account-entries`}
        component={CreditDetails}
        exact
      />
      {user.accessTo
        ? canUseThis(user, ['Returned Item']) && (
            <Route
              path={`${path}/returned-drugs`}
              component={ReturnedDrugs}
              exact
            />
          )
        : null}
      {/* <Route path={`${path}/deposit`} component={PatientDeposit} /> */}

      {/* {user.accessTo
        ? canUseThis(user, ["Returned Item"]) && ( */}
      <Route
        path={`${path}/requisition-list`}
        component={RequisitionList}
        exact
      />
      {/* )
        : null} */}
    </Switch>
  )
}

function BusinessMenu({ user }) {
  return (
    <VerticalMenu title="What would you like to do?">
      {/* {user.accessTo
        ? canUseThis(user, ["Create Customer Account"]) && ( */}
      <ListMenuItem route="/me/business/create-customer-account">
        <FiCast size={26} style={{ marginRight: 10 }} />
        Create Customer Account
      </ListMenuItem>
      {/* )
        : null} */}

      {/*user.accessTo
        ? canUseThis(user, ["Make Deposit"]) && (*/}
      {/* <ListMenuItem route="/me/business/deposit">
        <GiPayMoney size={26} style={{ marginRight: 10 }} />
        Make Deposit
      </ListMenuItem> */}
      {/*)
        : null*/}

      {/* {user.accessTo
        ? canUseThis(user, ["Customer Stock Balance"]) && ( */}
      <ListMenuItem route="/me/business/customer-stock-balance">
        <FiShoppingCart size={26} style={{ marginRight: 10 }} />
        Customer Stock Balance
      </ListMenuItem>
      {/* )
        : null} */}

      {/* {user.accessTo
        ? canUseThis(user, ["Customer Stock Balance"]) && ( */}
      <ListMenuItem route="/me/business/customer-account-entries">
        <MdAccountBalance size={26} style={{ marginRight: 10 }} />
        Customer Account Statement
      </ListMenuItem>
      {/* )
        : null} */}

      <ListMenuItem route="/me/business/recovery-list">
        <MdAccountBalance size={26} style={{ marginRight: 10 }} />
        Recovery List
      </ListMenuItem>

      {/* {user.accessTo
        ? canUseThis(user, ["Sales Department"]) && (
            <ListMenuItem route="/me/business/sale">
              <FiShoppingCart size={26} style={{ marginRight: 10 }} />
              Sales Invoice
            </ListMenuItem>
          )
        : null} */}
      {/* {user.accessTo
        ? canUseThis(user, ["My Sales"]) && (
            <ListMenuItem route="/me/business/my-sales-summary">
              <RiFilePaperLine size={26} style={{ marginRight: 10 }} />
              My Sales
            </ListMenuItem>
          )
        : null} */}
      {/* {user.accessTo
        ? canUseThis(user, ["Generate Receipt"]) && (
            <ListMenuItem route="/me/business/receipt">
              <MdPages size={26} style={{ marginRight: 10 }} />
              Generate Receipt
            </ListMenuItem>
          )
        : null} */}

      {/* {user.accessTo
        ? canUseThis(user, ['Returned Item']) && (
            <ListMenuItem route="/me/business/returned-drugs">
              <BsArrowReturnLeft size={26} style={{ marginRight: 10 }} />
              Returned Items
            </ListMenuItem>
          )
        : null} */}
      {/* {user.accessTo
        ? canUseThis(user, ["Requisition List"]) && (
      <ListMenuItem route="/me/business/requisition-list">
        <MdReceipt size={26} style={{ marginRight: 10 }} />
        Requisition List
      </ListMenuItem>)
        : null} */}
    </VerticalMenu>
  )
}

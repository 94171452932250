import React from "react";
import BackButton from "../comp/components/BackButton";

import NewUser from "./NewUser";

export default function StaffReview() {
  return (
    <>
      <BackButton />
      <NewUser />
    </>
  );
}

import {
  GETTING_ACC_CHART,
  GET_ACC_CHART,
  GET_ACC_CHART_TREE,
  GET_ALL_PENDING_REQ,
  GET_ITEM_CHART_TREE,
  GET_REVENUE_ACC_HEADS,
} from '../actions/types';
import {
  GETTING_SUMMARY_REPORT,
  GET_SUMMARY_REPORT,
} from '../actions/actionTypes';

const initialState = {
  accountChart: [],
  accountChartLoading: true,
  accChartTree: [],
  reports: {
    generalAccountReport: [],
    summary: {
      income: [],
      expenses: [],
      loading: false,
    },
  },
  revenueHeads: [],
  itemChartTree: [],
  allBranchReq: []
  
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETTING_ACC_CHART:
      return {
        ...state,
        accountChartLoading: !state.accountChartLoading,
      };

    case GET_ACC_CHART:
      return {
        ...state,
        accountChart: action.payload,
      };

    case GET_ACC_CHART_TREE:
      return {
        ...state,
        accChartTree: action.payload,
      };
    case GETTING_SUMMARY_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          summary: {
            ...state.reports.summary,
            loading: !state.reports.summary.loading,
          },
        },
      };
    case GET_SUMMARY_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          summary: {
            ...state.reports.summary,
            ...action.payload,
          },
        },
      };
    case GET_REVENUE_ACC_HEADS:
      return {
        ...state,
        revenueHeads: action.payload
      };

      case GET_ITEM_CHART_TREE:
      return {
        ...state,
        itemChartTree: action.payload,
      };
      case GET_ALL_PENDING_REQ:
      return {
        ...state,
        allBranchReq: action.payload,
      };
    
    default:
      return state;
  }
};

export default accountReducer;

import React, { lazy, useEffect } from 'react'
// import { MdAddShoppingCart } from "react-icons/md";
import VerticalMenu from '../comp/components/vertical-menu/VerticalMenu'
import ListMenuItem from '../comp/components/vertical-menu/ListMenuItem'
import { Redirect, Route, Switch } from 'react-router'
import { Col, Row } from 'reactstrap'
// import ExpensesForm from "./Expenses";
import { FiList } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { canUseThis } from '../auth'
import { useHistory } from 'react-router'
import { Suspense } from 'react'
import FullscreenLoading from '../comp/components/FullscreenLoading'
import InventoryItemTab from '../pharmacy/InventoryItemTab'
import AddClient from '../account/AddClient'
import ImportPurchaseOrder from '../pharmacy/purchase-order/ImportPurchaseOrder'
import { MdAssignment, MdAssignmentTurnedIn } from 'react-icons/md'
import { GiPayMoney } from 'react-icons/gi'
import PatientDeposit from '../account/PatientDeposit'
import { AiFillProject } from 'react-icons/ai'
import Promo from './Promo'
import PaymentReport from './PaymentReport'
const PurchaseOrder = lazy(() => import('../pharmacy/PurchaseOrder'))
const CreateBranch = lazy(() => import('./CreateBranch'))
const CreateItems = lazy(() => import('./CreateItems'))
const ItemDescription = lazy(() => import('./ItemDescription'))

const path = '/me/purchase-order'
export default function AdmminstrationDashboard() {
  const user = useSelector((state) => state.auth.user)

  const history = useHistory()
  const navigateUser = (user) => {
    let functionality = user.functionality
    const acc = ['Purchase Order', 'Item Description', 'Create Branch', 'SKUs']
    let sel = null

    acc.forEach((i) => {
      if (functionality && functionality.findIndex((it) => it === i) !== -1)
        sel = i
      return
    })

    switch (sel) {
      case 'Purchase Order':
        return history.push(`${path}/make-purchases/table`)
      case 'Item Description':
        return history.push(`${path}/new-client`)
      case 'Client Creation':
        return history.push(`${path}/branch`)
      case 'SKUs':
        return history.push(`${path}/SKUs`)
      default:
        return history.push(`${path}/make-purchases/table`)
    }
  }

  useEffect(() => {
    if (user) {
      navigateUser(user)
    }
  }, [user])

  return (
    <>
      <Row className="m-0 p-0">
        <Col md={3}>
          <AdminstrationMenu user={user} />
        </Col>
        <Col md={9}>
          <AdmminstrationRoute />
        </Col>
      </Row>
    </>
  )
}

function AdmminstrationRoute() {
  return (
    <Suspense fallback={<FullscreenLoading />}>
      <Switch>
        <Redirect
          from={`${path}/make-purchases`}
          to={`${path}/make-purchases/table`}
          exact
        />
        <Route path={`${path}/SKUs`} component={InventoryItemTab} />

        <Route path={`${path}/branch`} component={CreateBranch} />
        <Route path={`${path}/deposit`} component={PatientDeposit} />

        <Route path={`${path}/create/item`} component={CreateItems} />
        <Route path={`${path}/item/description`} component={ItemDescription} />
        <Route path={`${path}/make-purchases/:id`} component={PurchaseOrder} />
        <Route path={`${path}/new-client`} component={AddClient} />
        <Route path={`${path}/promo`} component={Promo} />
        <Route path={`${path}/payment/report`} component={PaymentReport} />

        <Route
          path={`${path}/import-from-excel`}
          component={ImportPurchaseOrder}
        />
      </Switch>
    </Suspense>
  )
}

function AdminstrationMenu({ user }) {
  return (
    <VerticalMenu title="What would you like to do?">
      {/* {user.accessTo
        ? canUseThis(user, ["Purchase Order"]) && ( */}
      {/* <ListMenuItem route={`${path}/make-purchases`}> 
      <ListMenuItem route={`${path}/make-purchases/table`}>
        <MdAssignment size={26} style={{ marginRight: 10 }} />
        Purchase Order
      </ListMenuItem>
   : null}  */}

      <ListMenuItem route={`${path}/SKUs`}>
        <FiList size={26} style={{ marginRight: 10 }} />
        SKUs
      </ListMenuItem>

      <ListMenuItem route={`${path}/deposit`}>
        <GiPayMoney size={26} style={{ marginRight: 10 }} />
        Make Deposit
      </ListMenuItem>

      {/* <ListMenuItem route={`${path}/new-client`}>
        <FiCast size={26} style={{ marginRight: 10 }} />
        Create a customer account
      </ListMenuItem> */}

      {/* {user.accessTo
        ? canUseThis(user, ["Create Branch"]) && (
            <ListMenuItem route={`${path}/branch`}>
              <FiActivity size={26} style={{ marginRight: 10 }} />
              Create Branch
            </ListMenuItem>
          )
        : null} */}
      {/* {user.accessTo
        ? canUseThis(user, ['Item Description']) && (
          <ListMenuItem route={`${path}/item/description`}>
            <MdAssignmentTurnedIn size={26} style={{ marginRight: 10 }} />
            Item Description
          </ListMenuItem>
        )
        : null} */}

      {/* {user.accessTo
        ? canUseThis(user, ["SKUs"]) && ( */}

      {/* <ListMenuItem route={`${path}/promo`}>
        <AiFillProject size={26} style={{ marginRight: 10 }} />
        Add Promo
      </ListMenuItem> */}

      {/* <ListMenuItem route={`${path}/payment/report`}>
        <MdAssignment size={26} style={{ marginRight: 10 }} />
        Payment Report
      </ListMenuItem> */}
      {/* )
        : null} */}
    </VerticalMenu>
  )
}

import {
  GETTING_ACC_CHART,
  GET_ACC_CHART_TREE,
  GET_ALL_PENDING_REQ,
  GET_ITEM_CHART_TREE,
  GET_REVENUE_ACC_HEADS,
} from "./types";
import { _fetchApi, _fetchApiGeneral } from "./api";
import { apiURL } from ".";
import { GETTING_SUMMARY_REPORT, GET_SUMMARY_REPORT } from "./actionTypes";
import moment from "moment";
import store from "../store";
import { getRequisitionList } from "./pharmacy";

const endpoint = "account";

export function getTxnSummaryReport(fromDate, toDate) {
  return (dispatch) => {
    let from =
      fromDate === ""
        ? moment().format("YYYY-MM-DD")
        : moment(fromDate).format("YYYY-MM-DD");
    let to =
      toDate === ""
        ? moment().format("YYYY-MM-DD")
        : moment(toDate).format("YYYY-MM-DD");
    dispatch({ type: GETTING_SUMMARY_REPORT });
    _fetchApi(
      `${apiURL()}/transactions/reports/summary/${from}/${to}`,
      ({ results }) => {
        dispatch({ type: GET_SUMMARY_REPORT, payload: results });
        dispatch({ type: GETTING_SUMMARY_REPORT });
      },
      (err) => {
        console.log(err);
        dispatch({ type: GETTING_SUMMARY_REPORT });
      }
    );
  };
}

export function getAccChart() {
  return (dispatch) => {
    dispatch({ type: GETTING_ACC_CHART });
    _fetchApi(
      `${apiURL()}/${endpoint}/chart`,
      ({ results }) => {
        // dispatch({ type: GET_ACC_CHART, payload: results });
        dispatch({ type: GETTING_ACC_CHART });
        let arrInTree = unflatten(results);
        // console.log(arrInTree)
        dispatch({ type: GET_ACC_CHART_TREE, payload: arrInTree });
      },
      (err) => {
        dispatch({ type: GETTING_ACC_CHART });
        console.log(err);
      }
    );
  };
}

// needed
export function getRevenueHeads() {
  return (dispatch) => {
    _fetchApi(
      `${apiURL()}/account/revenue/heads`,
      ({ results }) => {
        dispatch({ type: GET_REVENUE_ACC_HEADS, payload: results });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function unflatten(arr) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.title] = arrElem;
    mappedArr[arrElem.title]["children"] = [];
  }

  for (var title in mappedArr) {
    // console.log(title, mappedArr)
    if (mappedArr.hasOwnProperty(title)) {
      mappedElem = mappedArr[title];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.subhead) {
        mappedArr[mappedElem["subhead"]]["children"].push(mappedElem);
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
}

export function getItemChart() {
  return (dispatch) => {
    dispatch({ type: GETTING_ACC_CHART });
    _fetchApi(
      `${apiURL()}/${endpoint}/new/item_chart`,
      ({ results }) => {
        // dispatch({ type: GET_ACC_CHART, payload: results });
        dispatch({ type: GETTING_ACC_CHART });
        let arrInTree = unflatten(results);
        dispatch({ type: GET_ITEM_CHART_TREE, payload: arrInTree });
      },
      (err) => {
        dispatch({ type: GETTING_ACC_CHART });
        console.log(err);
      }
    );
  };
}

export function getAllBranchRequest() {
  return (dispatch) => {
    _fetchApi(
      `${apiURL()}/account/get_all/branch_req`,
      ({ results }) => {
        dispatch({ type: GET_ALL_PENDING_REQ, payload: results });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function getRequestList(callback) {
  return (dispatch) => {
    const userRoles = store.getState().auth.user.role;
    // _fetchApiGeneral(
    //   `${apiURL()}/account/users/status/roles?roles=${userRoles}`,
    //   ({ results }) => {
    //     // results.forEach((item) => newArr.push(item.user_status));
    //     // let final = newArr.splice("' ',");
    //     let newRes = results[0].user_status;
    //     console.log(results[0].user_status, "KDKDKDK");
    //     // setRoles(newRes);

    //     // getAllItemsBySub(newRes);
    dispatch(getRequisitionList(userRoles, null, "get", callback));
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  };
}

export function getOnlineRequest() {
  return (dispatch) => {
    const userRoles = store.getState().auth.user.role;
    dispatch(getRequisitionList(userRoles, null, "online"));
  };
}

import React, { useState } from "react";
import PurchaseOrderTable from "../pharmacy/PurchaseOrderTable";
import { useLocation, useHistory } from "react-router";
import PurchaseOrderForm from "../pharmacy/purchase-order/PurchaseOrderForm";
import { _fetchApi, _postApi } from "../../redux/actions/api";
import { apiURL } from "../../redux/actions";
import { _customNotify, _warningNotify } from "../utils/helpers";
import { useSelector } from "react-redux";

function PendingPurchaseOrder() {
  const [formTitle, setFormTitle] = useState({
    PONo: "",
    date: "",
    type: "Local",
    vendor: "",
    client: "",
    general_remark: "",
  });
  const formData = {
    exchange_rate: 1,
    item_name: "",
    specification: "",
    propose_quantity: 0,
    quantity_available: "",
    price: 0,
    propose_amount: "",
    status: "pending",
    exchange_type: "",
    total_amount: "",
    identifier: "add",
  };
  const onInputChange = ({ target: { name, value } }) => {
    setFormTitle((prev) => ({ ...prev, [name]: value }));
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const [tableData, setTableData] = useState([]);
  const [form, setForm] = useState(formData);
  const history = useHistory();

  const getPurchaseList = (id) => {
    _fetchApi(
      `${apiURL()}/get/purchase/order/list/${id}`,
      (data) => {
        console.log(data);
        let arr = [];
        data.results.forEach((item) => {
          arr.push({ ...item, propose: item.price * item.propose_quantity });
        });
        setTableData(arr);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const handleSet = (item) => {
    setFormTitle({
      PONo: item.po_id,
      date: item.date,
      type: item.type,
      vendor: item.vendor,
      client: item.client,
      other_remark: item.other_remark,
      exchange_rate: item.exchange_rate,
      exchange_type: item.exchange_type,
    });
    // setTypeahead()
  };

  const facilityId = useSelector((state) => state.facility.info.facility_id);

  const [remarkData, setRemarkData] = useState([]);
  const [gRemarkData, setGRemarkData] = useState([]);
  const getRemarkByID = (requestNo) => {
    _fetchApi(
      `${apiURL()}/account/get-max/remarks/${requestNo}`,
      (data) => {
        const remarks = [];
        const gRemarks = [];
        data.results.forEach((item) => {
          if (item.remarks_id.toString() === item.request_no.toString()) {
            gRemarks.push(item);
          } else {
            remarks.push(item);
          }
        });
        setRemarkData(remarks);
        setGRemarkData(gRemarks);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const handleSubmitRemarks = (remarks_by) => {
    let newArray = [];
    remarkData.forEach((item) => {
      newArray.push({
        remarks_id: item.remarks_id,
        request_no: item.request_no,
        remarks: item.newRemarks,
        remarks_by,
        date: item.date,
        general_remarks: "",
        facilityId,
      });
    });
    const callBack = (res) => {
      console.log(res);
    };
    const error = (err) => {
      _warningNotify("Error Occur");
      console.log(err);
    };
    for (let i = 0; i < newArray.length; i++) {
      const element = newArray[i];
      _postApi(
        `${apiURL()}/account/add-new/expenses-remarks`,
        element,
        callBack,
        error
      );
    }
    const data = [];
    tableData.forEach((item) => {
      data.push({
        remarks_id: item.po_id,
        request_no: item.po_id,
        remarks: "",
        remarks_by,
        date: item.date,
        general_remarks: formTitle.general_remark,
        facilityId,
      });
    });
    _postApi(
      `${apiURL()}/account/add-new/expenses-remarks-general`,
      data[0],
      callBack,
      error
    );
  };
  const processed_by = useSelector((name) => name.auth.user.username);
  const auditorReject = () => {
    let data = { data: formTitle.PONo, processed_by: processed_by };
    const callBack = () => {
      handleSubmitRemarks("Auditor");
      _customNotify("Rejected");
      history.push("/me/auditor/purchase/table");
      // getAllPurchasesPending();
    };
    const error = () => {
      _warningNotify("Not Rejected");
    };
    _postApi(`${apiURL()}/reject/auditor`, data, callBack, error);
  };
  const handleTableInputChange = (name, value, index) => {
    let arr = [];
    remarkData.forEach((item, i) => {
      if (index === i) {
        arr.push({
          ...item,
          [name]: value,
        });
      } else {
        arr.push(item);
      }
    });
    setRemarkData(arr);
  };
  const handleUpdateAuditedFile = () => {
    const data = {
      tableData: tableData,
      auditorRemark: formTitle.remark,
      PONo: formTitle.PONo,
    };
    const callBack = () => {
      handleSubmitRemarks("Auditor");
      _customNotify("Approved");
      history.push("/me/auditor/purchase/table");
    };
    const error = () => {
      _warningNotify("Not Approved");
    };
    _postApi(`${apiURL()}/update/audited/file`, data, callBack, error);
  };
  const location = useLocation();
  return (
    <div>
      {location.pathname.includes("/auditor/purchase/table") ? (
        <PurchaseOrderTable
          PONo={formTitle.PONo}
          getPurchaseList={getPurchaseList}
          handleSet={handleSet}
          handleSubmitRemarks={handleSubmitRemarks}
          getRemarkByID={getRemarkByID}
        />
      ) : null}
      {location.pathname.includes("/auditor/purchase/form") ? (
        <PurchaseOrderForm
          handleTableInputChange={handleTableInputChange}
          onInputChange={onInputChange}
          tableData={tableData}
          formTitle={formTitle}
          form={form}
          auditorReject={auditorReject}
          handleUpdateAuditedFile={handleUpdateAuditedFile}
          setForm={setForm}
          remarkData={remarkData}
          gRemarkData={gRemarkData}
        />
      ) : null}
    </div>
  );
}

export default PendingPurchaseOrder;

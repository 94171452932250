import {
  GET_PURCHASE_ORDER_REPORT
} from '../actions/actionTypes';
import { TRACK_SELECTED_REQUISITION } from '../actions/types';

const initialState = {
  report: [],
  selectedRequisition: {}
};

const purchaseOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PURCHASE_ORDER_REPORT:
      return {
        ...state,
        report: action.payload,
      };
      case TRACK_SELECTED_REQUISITION: 
      return {
        selectedRequisition: action.payload
      }

    default:
      return state;
  }
};

export default purchaseOrderReducer;

import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { apiURL } from "../../../redux/actions";
import { _fetchApi, _updateApi } from "../../../redux/actions/api";
import CustomButton from "../../comp/components/Button";
import InputGroup from "../../comp/components/InputGroup";
import SelectInput from "../../comp/components/SelectInput";
import { _customNotify, _warningNotify } from "../../utils/helpers";
import { stateApi } from "./Api";

export default function ClientEdit() {
  const { accountNo } = useParams();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const facilityId = useSelector((state) => state.facility.info.facility_id);

  const getDetails = () => {
    _fetchApi(
      `${apiURL()}/account/get_details/edit/${accountNo}`,
      ({ results }) => {
        setData(results);
      },
      (err) => console.log(err)
    );
  };
  useEffect(() => {
    getDetails();
  }, []);

  const handleOnchange = (name, value, index) => {
    let arr = [];
    data.forEach((item, i) => {
      if (index === i) {
        arr.push({
          ...item,
          [name]: value,
        });
      } else {
        arr.push(item);
      }
    });
    setData(arr);
  };

  const handleSubmit = () => {
    _updateApi(
      `${apiURL()}/account/update/client-details/${accountNo}/${facilityId}`,
      data[0],
      () => {
        setLoading(false);
        _customNotify("Client Updated Successfully");
        history.push("/me/business/create-customer-account");
      },
      (err) => {
        setLoading(false);
        _warningNotify("Error Occur during updating the client");
      }
    );
  };
const [selected,setSelected] = useState({})
  return (
    <>
      {/* {JSON.stringify(data)} */}
      <Card>
        {data.map((item, i) => (
          <CardBody className="d-flex flex-direction-row flex-wrap">
            <InputGroup
              name="accName"
              onChange={({ target: { name, value } }) =>
                handleOnchange(name, value, i)
              }
              label={
                item.accountType === "Family"
                  ? "Full Name"
                  : "Organization Name"
              }
              value={item.accName}
              container="col-md-4"
              required
            />

            {item.accountType === "Family" ? (
              <>
                <InputGroup
                  label="Address"
                  container="col-md-4"
                  name="contactAddress"
                  value={item.contactAddress}
                  onChange={({ target: { name, value } }) =>
                    handleOnchange(name, value, i)
                  }
                  placeholder="Apartment, studio, or floor"
                />
              </>
            ) : null}

            <InputGroup
              name="balance"
              onChange={({ target: { name, value } }) =>
                handleOnchange(name, value, i)
              }
              label="Account Balance"
              value={item.balance}
              container="col-md-4"
              editable={false}
            />
            <InputGroup
              name="contactPhone"
              onChange={({ target: { name, value } }) =>
                handleOnchange(name, value, i)
              }
              label="Phone Number"
              value={item.contactPhone}
              container="col-md-4"
            />
            <InputGroup
              name="credit_limit"
              onChange={({ target: { name, value } }) =>
                handleOnchange(name, value, i)
              }
              label="Credit Limit"
              value={item.credit_limit}
              container="col-md-4"
            />
 <SelectInput
            name="state"
            // onChange={({target:{value}})=>{
            //   setData((p)=>({...p, state:value}))
              
            // }}
            onChange={({ target: { name, value } }) =>{
            handleOnchange(name, value, i) 
            setSelected(stateApi.filter(a=>a.state === value)[0])
          }}
            label="State"
            value={item.state}
            container="col-md-4"
            options={stateApi.map(i=>(i.state))}
          />
           <SelectInput
            name="lga"
            onChange={({ target: { name, value } }) =>
            handleOnchange(name, value, i)}
            label="LGA"
            value={item.lga}
            container="col-md-4"
            options={selected.lgas}
          />
           <SelectInput
            name="customer_type"
            // onChange={({target:{value}})=>{
            //   setData((p)=>({...p, state:value}))
              
            // }}
            onChange={({ target: { name, value } }) =>{
            handleOnchange(name, value, i) 
          
          }}
            label="Customer Type"
            value={item.customer_type}
            container="col-md-4"
            options={["Kirana","Uwargida"]}
          />
            {/* <InputGroup
              name="new_balance"
              onChange={({ target: { name, value } }) =>
                handleOnchange(name, value, i)
              }
              label="New Balance"
              value={item.new_balance}
              container="col-md-4"
            /> */}

            <Row className="bg-light m-0">
              <Col md={5}>
                <h4>
                  <kbd>Guarantor </kbd>
                </h4>
              </Col>

              <>
                <Row>
                  <Col md={6}>
                    {/* {JSON.stringify(client)} */}

                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input
                        type="name"
                        name="guarantor_name"
                        value={item.guarantor_name}
                        onChange={({ target: { name, value } }) =>
                          handleOnchange(name, value, i)
                        }
                        placeholder="Name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="name">Contact Address</Label>
                      <Input
                        name="guarantor_address"
                        value={item.guarantor_address}
                        onChange={({ target: { name, value } }) =>
                          handleOnchange(name, value, i)
                        }
                        placeholder="Address"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="phone" className="font-weight-bold">
                        Phone Number
                      </Label>
                      <Input
                        type="tel"
                        name="guarantor_phone"
                        value={item.guarantor_phone}
                        onChange={({ target: { name, value } }) =>
                          handleOnchange(name, value, i)
                        }
                        placeholder="Telephone Number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            </Row>
          </CardBody>
        ))}
      <div className="d-flex flex-row justify-content-center">
        <CustomButton
          loading={loading}
          onClick={handleSubmit}
          className="px-5 mt-3"
          color="success"
        >
          Update
        </CustomButton>
      </div>
      </Card>
    </>
  );
}

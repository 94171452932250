import {
  GET_INVENTORY,
  ADD_DRUG,
  INVENTORY_LOADING,
  SUBMIT_DRUG_LOADING,
  UPDATE_DRUG,
  UPDATING_DRUG,
  LOAD_EXPIRY_ALERT,
  LOAD_QTTY_ALERT,
  LOAD_EXPIRED,
  GET_BRANCHES,
  PURCHASE_RECORDS_LOADING,
  GET_PURCHASE_RECORDS,
  PENDING_PURCHASES_LOADING,
  GET_PENDING_PURCHASES,
  DISPENSING_DRUGS,
  DISPENSE_DRUGS,
  GETTING_ALL_SUPPLIERS,
  GET_ALL_SUPPLIERS,
  CREATING_SUPPLIERS,
  CREATE_SUPPLIER,
  DRUGS_LOADING,
  GET_DRUGS,
  DELETING_DRUG,
  ADDING_DRUG,
  UPDATING_SUPPLIER,
  DELETING_SUPPLIER,
  GETTING_PHARM_SALES_SUMMARY,
  GET_PHARM_SALES_SUMMARY,
  CLEAR_PHARM_SALES_SUMMARY,
  GETTING_TOP_5_POPULAR_DRUGS,
  GET_TOP_5_POPULAR_DRUGS,
  GETTING_CUSTOMERS,
  GET_CUSTOMERS,
  GOOD_TRANFER_CALCULATION,
  MARKUPTYPE,
  MARKUPVALUE,
  UNITCOST,
  QUANTITY,
  OTHEREXPENSES,
  RESETGRNFORM,
  GET_OTHER_EXPENSES,
  GET_SALES,
  GET_SALES2,
  GET_STORE_SUMMARY,
} from "../actions/actionTypes";
import {
  GET_BRANCH_REQ,
  GET_REQUISITION_LIST,
  GET_SHOP_BAL,
} from "../actions/types";

const initialState = {
  test: "Hello there",
  drugs: [],
  goodTranfer: {
    unitCost: 0,
    quantity: 0,
    otherExpenses: 0,
    markUpType: "fixed",
    markUpValue: 0,
    sellingPrice: 0,
    amount: 0,
    markUp: 0,
  },
  inventory: [],
  purchaseRecords: [],
  pendingPurchases: [],
  expiredDrugs: [],
  expiryAlert: [],
  quantityAlert: [],
  suppliers: [],
  inventoryLoading: false,
  drugSubmitLoading: false,
  updatingDrug: false,
  loadingPurchaseRecords: false,
  loadingPendingPurchases: false,
  dispensingDrugs: false,
  suppliersLoading: false,
  creatingSupplier: false,
  updatingSupplier: false,
  deletingSupplier: false,
  drugListLoading: false,
  addingDrug: false,
  deletingDrug: false,
  gettingPharmSalesSummary: false,
  summaryByBranch: [],
  storeSummary: [],
  gettingTop5: false,
  top5PopularDrugsToday: [],
  loadingCustomers: false,
  customersList: [],
  pharmHasStore: true,
  branches: [],
  branchesInfo: {
    unitCost: 0,
    quantity: 0,
    otherExpenses: 0,
    markUpType: "fixed",
    markUpValue: 0,
    sellingPrice: 0,
    amount: 0,
    markUp: 0,
  },
  otherExpenses: [],
  branchItem: [],
  requisitionList: [],
  branchRegItem: [],
  branchRegItem2: [],
  shopBal: {},
};

const pharmacyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EXPIRY_ALERT:
      return {
        ...state,
        expiryAlert: action.payload,
      };

    case LOAD_QTTY_ALERT:
      return {
        ...state,
        quantityAlert: action.payload,
      };

    case LOAD_EXPIRED:
      return {
        ...state,
        expiredDrugs: action.payload,
      };

    case INVENTORY_LOADING:
      return {
        ...state,
        inventoryLoading: !state.inventoryLoading,
      };

    case DRUGS_LOADING:
      return {
        ...state,
        drugListLoading: !state.drugListLoading,
      };

    case GET_DRUGS:
      return {
        ...state,
        drugListLoading: false,
          drugs: action.drugs,
      };

    case PURCHASE_RECORDS_LOADING:
      return {
        ...state,
        loadingPurchaseRecords: !state.loadingPurchaseRecords,
      };
    case GET_OTHER_EXPENSES:
      return {
        ...state,
        otherExpenses: action.payload,
      };

    case PENDING_PURCHASES_LOADING:
      return {
        ...state,
        loadingPendingPurchases: !state.loadingPendingPurchases,
      };

    case GET_PURCHASE_RECORDS:
      return {
        ...state,
        loadingPurchaseRecords: false,
          purchaseRecords: action.purchaseRecords,
      };

    case GET_PENDING_PURCHASES:
      return {
        ...state,
        loadingPendingPurchases: false,
          pendingPurchases: action.pendingPurchases,
      };

    case SUBMIT_DRUG_LOADING:
      return {
        ...state,
        drugSubmitLoading: !state.drugSubmitLoading,
      };

    case GET_INVENTORY: {
      // let drugs = [];
      // action.inventory.forEach(item => drugs.push(item.drug));
      return {
        ...state,
        // drugs:,
        inventoryLoading: false,
        inventory: action.inventory,
      };
    }

    case UPDATE_DRUG: {
      let {
        drug_id
      } = action.payload;
      let newList = state.inventory.filter((item) => item.drug_id !== drug_id);
      return {
        ...state,
        inventory: [action.payload, ...newList],
      };
    }

    case UPDATING_DRUG:
      return {
        ...state,
        updatingDrug: !state.updatingDrug,
      };

    case ADD_DRUG:
      return {
        ...state,
        inventory: [...state.inventory, action.payload],
      };
    case ADDING_DRUG:
      return {
        ...state,
        addingDrug: !state.addingDrug,
      };

    case DISPENSING_DRUGS:
      return {
        ...state,
        dispensingDrugs: !state.dispensingDrugs,
      };

    case DISPENSE_DRUGS: {
      const {
        dispensed
      } = action.payload;
      const {
        inventory
      } = state;
      let newInventory = [];
      dispensed.forEach((drug) => {
        let currentDrug = inventory.filter((item) => item.drug === drug.drug);
        if (currentDrug.length) {
          let newDrugQtty =
            parseInt(currentDrug[0].quantity) -
            parseInt(drug.quantity_dispensed);
          let newInventoryItem = {
            ...currentDrug[0],
            quantity: newDrugQtty
          };
          newInventory.push(newInventoryItem);
        }
      });
      console.log(newInventory);

      return {
        ...state,
        dispensingDrugs: false,
        inventory: newInventory,
      };
    }

    case GETTING_ALL_SUPPLIERS:
      return {
        ...state,
        suppliersLoading: !state.suppliersLoading,
      };

    case GET_ALL_SUPPLIERS:
      // console.log('action',action)
      return {
        ...state,
        suppliers: action.suppliers,
      };

    case CREATING_SUPPLIERS:
      return {
        ...state,
        creatingSupplier: !state.creatingSupplier,
      };

    case CREATE_SUPPLIER:
      return {
        ...state,
        suppliers: [action.payload, ...state.suppliers],
      };
    case UPDATING_SUPPLIER:
      return {
        ...state,
        updatingSupplier: !state.updatingSupplier,
      };
    case DELETING_SUPPLIER:
      return {
        ...state,
        deletingSupplier: !state.deletingSupplier,
      };
    case DELETING_DRUG:
      return {
        ...state,
        deletingDrug: !state.deletingDrug,
      };
    case GETTING_PHARM_SALES_SUMMARY:
      return {
        ...state,
        gettingPharmSalesSummary: !state.gettingPharmSalesSummary,
      };
    case GET_PHARM_SALES_SUMMARY:
      return {
        ...state,
        summaryByBranch: action.payload
      };
    case CLEAR_PHARM_SALES_SUMMARY:
      return {
        ...state,
        summaryByBranch: [],
      };
    case GET_STORE_SUMMARY:
      return {
        ...state,
        storeSummary: action.payload
      }
      case GETTING_TOP_5_POPULAR_DRUGS:
        return {
          ...state,
          gettingTop5: !state.gettingTop5,
        };
      case GET_TOP_5_POPULAR_DRUGS:
        return {
          ...state,
          top5PopularDrugsToday: action.payload,
        };
      case GETTING_CUSTOMERS:
        return {
          ...state,
          loadingCustomers: !state.loadingCustomers,
        };
      case GET_CUSTOMERS:
        return {
          ...state,
          customersList: action.payload,
        };
      case GOOD_TRANFER_CALCULATION:
        let amount =
          state.goodTranfer.quantity * state.goodTranfer.unitCost +
          state.goodTranfer.otherExpenses;
        let markup;
        if (state.goodTranfer.markUpType === "fixed") {
          markup = state.goodTranfer.markUpValue;
          // markup=amount*(state.goodTranfer.markUpValue/100)
        } else {
          markup = amount * parseFloat(state.goodTranfer.markUpValue / 100);
        }
        let selling_price = parseFloat(state.goodTranfer.unitCost) + markup;
        // let selling_price =parseFloat(amount/state.goodTranfer.quantity)  + markup;
        return {
          ...state,
          goodTranfer: {
            ...state.goodTranfer,
            sellingPrice: parseFloat(selling_price),

            markUp: markup,
            amount: amount,
          },
        };
      case MARKUPVALUE:
        return {
          ...state,
          goodTranfer: {
            ...state.goodTranfer,
            markUpValue: action.payload,
            // markUpValue: action.payload/state.goodTranfer.quantity,
          },
        };
      case MARKUPTYPE:
        return {
          ...state,
          goodTranfer: {
            ...state.goodTranfer,
            markUpType: action.payload,
          },
        };
      case UNITCOST:
        return {
          ...state,
          goodTranfer: {
            ...state.goodTranfer,
            unitCost: action.payload,
          },
        };
      case QUANTITY:
        return {
          ...state,
          goodTranfer: {
            ...state.goodTranfer,
            quantity: action.payload,
          },
        };
      case OTHEREXPENSES:
        return {
          ...state,
          goodTranfer: {
            ...state.goodTranfer,
            otherExpenses: action.payload,
          },
        };
      case RESETGRNFORM:
        return {
          ...state,
          goodTranfer: action.payload,
        };
      case GET_BRANCHES:
        return {
          ...state,
          branches: action.payload,
        };
      case GET_SALES:
        return {
          ...state,
          branchItem: action.payload,
        };
      case GET_SALES2:
        return {
          ...state,
          branchItem2: action.payload,
        };
      case GET_REQUISITION_LIST:
        return {
          ...state,
          requisitionList: action.payload,
        };
      case GET_BRANCH_REQ:
        return {
          ...state,
          branchRegItem: action.payload,
        };
      case GET_SHOP_BAL:
        return {
          ...state,
          shopBal: action.payload,
        };
      default:
        return state;
  }
};

export default pharmacyReducer;
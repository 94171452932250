import React, { useEffect, useState } from "react";
import { apiURL } from "../../redux/actions";
import { _fetchApi, _updateApi } from "../../redux/actions/api";
import { _customNotify } from "../utils/helpers";
import ExpensiveTable from "../Administration/ExpensiveTable";
import { useLocation } from "react-router";

export default function PendingExpense() {
  const [getExpenses, setExpenses] = useState([]);
  const location = useLocation();

  const getExpenseByPending = () => {
    _fetchApi(
      `${apiURL()}/account/get/expenses/status/pending`,
      (data) => {
        setExpenses(data.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getExpenseByApprove = () => {
    _fetchApi(
      `${apiURL()}/account/get/expenses/status/approved`,
      (data) => {
        setExpenses(data.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const select_reviewer_expenses = () => {
    _fetchApi(
      `${apiURL()}/drugs/reviewer/expenses`,
      (data) => {
        setExpenses(data.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getExpenseByAudit = () => {
    _fetchApi(
      `${apiURL()}/account/get/expenses/status/audited`,
      (data) => {
        setExpenses(data.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  useEffect(() => {
    // console.log("nothing", location.pathname);
    if (location.pathname === "/me/auditorr/expenses") {
      // console.log("management", location.pathname);
      getExpenseByAudit();
    } else if (location.pathname === "/me/auditor/expenses") {
      // console.log("audit", location.pathname);
      getExpenseByPending();
    } else if (location.pathname === "/me/account/expenditure") {
      console.log("expenditure", location.pathname);
      getExpenseByApprove();
    } else if (location.pathname.includes("/me/reviewer/expenses")) {
      // console.log("expenditure ", location.pathname);
      select_reviewer_expenses();
    } else {
      return null;
    }
  }, []);

  const _updateStatusApprove = (requestNo) => {
    _updateApi(
      `${apiURL()}/account/update/expenses/status/${requestNo}`,
      { status: "Reviewer" },
      (success) => {
        _customNotify("Approve Expenses Successfully");
        getExpenseByAudit();
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const _updateStatusReject = (requestNo) => {
    _updateApi(
      `${apiURL()}/account/update/expenses/status/${requestNo}`,
      { status: "Rejected" },
      (success) => {
        _customNotify("Reject Expenses Successfully");
        getExpenseByAudit();
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const _updateStatusAuditorReject = (requestNo) => {
    _updateApi(
      `${apiURL()}/account/update/expenses/status/${requestNo}`,
      { status: "Auditor Rejected" },
      (success) => {
        _customNotify("Reject Expenses Successfully");
        getExpenseByPending();
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const _updateStatusAudited = (requestNo) => {
    _updateApi(
      `${apiURL()}/account/update/expenses/status/${requestNo}`,
      { status: "Audited" },
      (success) => {
        _customNotify("Audit Expenses Successfully");
        getExpenseByPending();
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const _updateStatusAcct = (requestNo, newStatus) => {
    _updateApi(
      `${apiURL()}/account/update/expenses/status/${requestNo}`,
      { status: newStatus },
      (success) => {
        getExpenseByApprove();
        _customNotify("Expenses Successfully");
      },
      (err) => {
        console.log(err);
      }
    );
  };
  return (
    <>
      {/* {JSON.stringify(getExpenses)} */}

      <ExpensiveTable
        getExpenses={getExpenses}
        _updateStatusReject={_updateStatusReject}
        _updateStatusApprove={_updateStatusApprove}
        _updateStatusAudited={_updateStatusAudited}
        _updateStatusAuditorReject={_updateStatusAuditorReject}
        _updateStatusAcct={_updateStatusAcct}
      />
    </>
  );
}

import React, { lazy, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Route, Switch, Redirect } from "react-router";
import VerticalMenu from "../comp/components/vertical-menu/VerticalMenu";
import ListMenuItem from "../comp/components/vertical-menu/ListMenuItem";
// import { AiOutlineCluster } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import { useSelector } from "react-redux";
import { canUseThis } from "../auth";
import { useHistory } from "react-router";
import { Suspense } from "react";
import FullscreenLoading from "../comp/components/FullscreenLoading";
import allModule from "../admin/moduleData";
import { getReqNum } from "../utils/helpers";
const Process = lazy(() => import("../Auditor/Process"));
const PendingExpense = lazy(() => import("../Auditor/PendingExpense"));
const AudittedPurchaseOrder = lazy(() => import("./AudittedPurchaseOrder"));
const ViewExpenses = lazy(() => import("../Administration/ViewExpense"));

let currentPath = "/me/auditorr";
function Tab() {
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  const [, setstate] = useState("");
  const navigateUser = (user) => {
    let functionality = user.functionality;

    // const access = allModule.auditorr.type;

    // let active = null;
    // access.forEach((i) => {
    //   if (functionality && functionality.findIndex((it) => it === i) !== -1)
    //     active = i;
    //   return;
    // });

    // switch (active) {
    //   case "Management DashBoard":
    //     return history.push(`${currentPath}/dashboad`);
    //   case "Pending Expenses Requisition":
    //     return history.push(`${currentPath}/expenses`);
    //   case "Pending Purchase Order":
    //     return history.push(`${currentPath}/audited`);
    //   default:
    //     return history.push(`${currentPath}/expenses`);
    // }
  };

  useEffect(() => {
    let arr = '"Audited", "BackToManagement","ReviewerReject"';
    if (user) {
      navigateUser(user);
      getReqNum(setstate, arr);
    }
  }, [user]);
  return (
    <>
      <VerticalMenu>
        {/* {user.accessTo
          ? canUseThis(user, ["Management DashBoard"]) && (
              <ListMenuItem route={`${currentPath}/dashboad`}>
                <AiOutlineCluster size={26} style={{ marginRight: 5 }} />
                Management DashBoard
              </ListMenuItem>
            )
          : null}*/}
       
        {user.accessTo
          ? canUseThis(user, ["Pending Purchase Order"]) && (
              <ListMenuItem route={`${currentPath}/audited`}>
                <FiShoppingCart size={26} style={{ marginRight: 5 }} />
                Pending Purchase Orderr
                {/* <Badge color="info">{state}</Badge> */}
              </ListMenuItem>
            )
          : null} 
         
      </VerticalMenu>
    </>
  );
}

function Management() {
  return (
    <>
      <Row className="m-0">
        <Col md={3}>
          <Tab />
        </Col>
        <Col md={9}>
          <Suspense fallback={<FullscreenLoading />}>
            <Switch>
              {/* <Redirect
                exact
                from={`${currentPath}`}
                to={`${currentPath}/expenses`}
              /> */}
              <Route
                exact
                path={`${currentPath}/dashboad`}
                component={Process}
              />
              <Route
                exact
                path={`${currentPath}/audited/:id`}
                component={AudittedPurchaseOrder}
              />
              <Redirect
                exact
                from={`${currentPath}/audited`}
                to={`${currentPath}/audited/table`}
              />
              <Route
                exact
                path={`${currentPath}/expenses`}
                component={PendingExpense}
              />
              <Route
                exact
                path={`${currentPath}/expenses/view/:requestNo`}
                component={ViewExpenses}
              />
            </Switch>
          </Suspense>
        </Col>
      </Row>
    </>
  );
}

export default Management;

// user(s) action types
export const REGISTER = "REGISTER";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const LOADING_AVATAR = "LOADING_AVATAR";
export const LOAD_AVATAR = "LOAD_AVATAR";
export const CREATING_USER = "CREATING_USER";
export const ERROR_CREATING_USER = "ERROR_CREATING_USER";
export const CREATE_HOSPITAL = "CREATE_HOSPITAL";
export const GET_ROLES = "GET_ROLES";
export const GET_USERS = "GET_USERS";
export const GET_DOC_LIST_LOADING = "GET_DOC_LIST_LOADING";
export const GET_DOCTORS_LIST = "GET_DOCTORS_LIST";
export const GET_APPROVED_DOCTORS = "GET_APPROVED_DOCTORS";
export const LOADING_APPROVED_DOCTORS = "LOADING_APPROVED_DOCTORS";

// account action types
export const CREATE_ACC_HEAD = "CREATE_ACC_HEAD";
export const CREATE_ACC_HEAD_LOADING = "CREATE_ACC_HEAD_LOADING";
export const GET_ACC_HEAD_LOADING = "GET_ACC_HEAD_LOADING";
export const GET_ACC_HEAD = "GET_ACC_HEAD";
export const GET_REV_ACC_HEAD = "GET_REV_ACC_HEAD";
export const GETTING_TOTAL_SALES_BY_USER = "GETTING_TOTAL_SALES_BY_USER";
export const GET_TOTAL_SALES_BY_USER = "GET_TOTAL_SALES_BY_USER";
export const GET_AMOUNT_RECEIVED = "GET_AMOUNT_RECEIVED";
export const GET_AMOUNT_HANDEDOVER = "GET_AMOUNT_HANDEDOVER";
export const GET_EXPENSES_ACC_HEAD_LOADING = "GET_EXPENSES_ACC_HEAD_LOADING";
export const GET_EXPENSES_ACC_HEAD = "GET_EXPENSES_ACC_HEAD";
export const GET_REVENUE_ACC_HEADS = "GET_REVENUE_ACC_HEADS";
export const NEW_ITEM_DESCRIPTION = "NEW_ITEM_DESCRIPTION";
export const GET_ITEM_HEAD = "GET_ITEM_HEAD";
export const TRACK_SELECTED_REQUISITION = 'TRACK_SELECTED_REQUISITION'

// transactions action types
export const GET_GENERAL_ACC_REPORT_LOADING = "GET_GENERAL_ACC_REPORT_LOADING";
export const GET_GENERAL_ACC_REPORT = "GET_GENERAL_ACC_REPORT";
export const GET_EXPENDITURE_ACC_REPORT = "GET_EXPENDITURE_ACC_REPORT";
export const GET_REVENUE_ACC_REPORT = "GET_REVENUE_ACC_REPORT";
export const GET_PATIENT_ACC_STMT = "GET_PATIENT_ACC_STMT";
export const GETTING_PATIENT_ACC_STMT = "GETTING_PATIENT_ACC_STMT";

// account action types
export const GET_ACC_CHART = "GET_ACC_CHART";
export const GETTING_ACC_CHART = "GETTING_ACC_CHART";
export const GET_ACC_CHART_TREE = "GET_ACC_CHART_TREE";
export const GET_ITEM_CHART_TREE = "GET_ITEM_CHART_TREE";
export const GET_ALL_PENDING_REQ = "GET_ALL_PENDING_REQ";

//doctor action types
export const SET_TAB = "SET_TAB";
export const SAVE_VITAL_SIGNS = "SAVE_VITAL_SIGNS";
export const SAVE_PRESENTING_COMPLAINTS = "SAVE_PRESENTING_COMPLAINTS";
export const SAVE_P_COMPLAINT_HISTORY = "SAVE_P_COMPLAINT_HISTORY";
export const SAVE_PREV_MED_HISTORY = "SAVE_PREV_MED_HISTORY";
export const SAVE_SYS_EXAM = "SAVE_SYS_EXAM";
export const SAVE_PROBLEMS = "SAVE_PROBLEMS";
export const SAVE_LAB_INVESTIGATIONS = "SAVE_LAB_INVESTIGATIONS";
export const SAVE_PROVISIONAL_DIAGNOSIS = "SAVE_PROVISIONAL_DIAGNOSIS";
export const SAVE_ATHROPOMETRY = "SAVE_ATHROPOMETRY";
export const SAVE_MANAGEMENT_PLAN = "SAVE_MANAGEMENT_PLAN";
export const SAVE_PRESCRIPTION_REQUEST = "SAVE_PRESCRIPTION_REQUEST";
export const SAVE_OBSERVATION_REQUEST = "SAVE_OBSERVATION_REQUEST";
export const SAVE_DRESSING_REQUEST = "SAVE_DRESSING_REQUEST";
export const SUBMIT_DIAGNOSIS = "SUBMIT_DIAGNOSIS";
export const SUBMIT_DIAGNOSIS_LOADING = "SUBMIT_DIAGNOSIS_LOADING";
export const GET_PATIENT_ASSIGNED_TODAY = "GET_PATIENT_ASSIGNED_TODAY";
export const GETTING_PATIENT_ASSIGNED_TODAY = "GETTING_PATIENT_ASSIGNED_TODAY";
export const GETTING_PATIENTS_ASSIGNED_TO_DOC =
  "GETTING_PATIENTS_ASSIGNED_TO_DOC";
export const GET_PATIENTS_ASSIGNED_TO_DOC = "GET_PATIENTS_ASSIGNED_TO_DOC";
export const SET_PATIENT_TO_SEE = "SET_PATIENT_TO_SEE";
export const GETTING_PATIENT_PAST_VISIT = "GETTING_PATIENT_PAST_VISIT";
export const GET_PATIENT_PAST_VISIT = "GET_PATIENT_PAST_VISIT";
export const RESET_DIAGNOSIS_FORM = "RESET_DIAGNOSIS_FORM";
export const SAVE_FOLLOWUP_APPOINTMENT = "SAVE_FOLLOWUP_APPOINTMENT";
export const SAVE_MSS = "SAVE_MSS";
export const SAVE_CVS = "SAVE_CVS";
export const SAVE_RESPIRATORY = "SAVE_RESPIRATORY";
export const SAVE_ABDOMEN = "SAVE_ABDOMEN";
export const SAVE_CNS = "SAVE_CNS";
export const SAVE_GPE = "SAVE_GPE";

// app
export const TOGGLE_MOBILE_VIEW = "TOGGLE_MOBILE_VIEW";
export const REPAIR_FORM_NUMBER = "REPAIR_FORM_NUMBER";
export const INSTALLATION_FORM_NUMBER = "INSTALLATION_FORM_NUMBER";
export const REGISTRATION_FORM_NUMBER = "REGISTRATION_FORM_NUMBER";

//New one

export const SAVE_PATIENT_DATA = "SAVE_PATIENT_DATA";
export const SAVE_PATIENT_TO_LIST = "SAVE_PATIENT_TO_LIST";
export const GET_DOC_PATIENT_LIST = "GET_DOC_PATIENT_LIST";
export const SET_SELECTED_PATIENT = "SET_SELECTED_PATIENT";
export const CLEAR_SELECTED_PATIENT = "CLEAR_SELECTED_PATIENT";

export const SAVE_NEW_APPOINTMENT = "SAVE_NEW_APPOINTMENT";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const SET_SELECTED_APPOINTMENT = "SET_SELECTED_APPOINTMENT";
export const CLEAR_SELECTED_APPOINTMENT = "CLEAR_SELECTED_APPOINTMENT";
export const LOADING_UNAPPROVED_APPT = "LOADING_UNAPPROVED";
export const GET_UNAPPROVED_APPT = "GET_UNAPPROVED_APPT";
export const GET_PATIENTS_APPOINTMENTS = "GET_PATIENTS_APPOINTMENTS";

export const SET_PATIENT_APPOINTMENTS = "SET_PATIENT_APPOINTMENTS";
export const GET_REQUISITION_LIST = "GET_REQUISITION_LIST";
export const GET_BRANCH_REQ = "GET_BRANCH_REQ";
export const GET_SHOP_BAL = "GET_SHOP_BAL";

export const SET_PATIENT_FORM_MODE = "SET_PATIENT_FORM_MODE";

export const GET_VISITS_LIST = "GET_VISITS_LIST";
export const SET_SELECTED_VISIT = "SET_SELECTED_VISIT";
export const CLEAR_SELECTED_VISIT = "CLEAR_SELECTED_VISIT";
export const SET_PATIENT_PAST_VISITS = "SET_PATIENT_PAST_VISITS";

export const GET_LAB_LIST = "GET_LAB_LIST";
export const SET_SELECTED_LAB = "SET_SELECTED_LAB";
export const CLEAR_SELECTED_LAB = "CLEAR_SELECTED_LAB";
export const SET_PATIENT_LABS = "SET_PATIENT_LABS";

export const TOGGLE_VIDEO_VIEW = "TOGGLE_VIDEO_VIEW";

// notification
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const LOADING_NOTIFICATIONS = "LOADING_NOTIFICATIONS";

const access = ['canEditAccountStatement', 'canEditAccountStatement']
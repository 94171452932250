import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'reactstrap'
import { _fetchApiGeneral } from '../../redux/actions/api'
// import { apiURL } from '../redux/actions'
import Loading from '../loading'
import useQuery from '../utils/UseQuery'
import { checkEmpty, formatNumber } from '../utils/helpers'
import CustomerCreditDetails from '../pharmacy/pending-requests/CustomerCreditDetails'
// import BackButton from '../../comp/components/BackButton'
import moment from 'moment'
import DaterangeSelector from '../comp/components/DaterangeSelector'
import PrintWrapper from '../comp/components/PrintWrapper'
import BackButton from '../comp/components/BackButton'
import { apiURL } from '../../redux/actions'

export default function AccountEntriesDetails() {
  const query = useQuery()
  const client_id = query.get('client_id')
  // const date = query.get("date");
  const branch_name = query.get('branch_name')
  const user = useSelector((state) => state.auth.user)
  const today = moment().format('YYYY-MM-DD')
  const yearStart = moment().startOf('year').format('YYYY-MM-DD')
  const [range, setRange] = useState({
    from: yearStart,
    to: today,
  })

  const [loading, setLoading] = useState('')
  const [list, setList] = useState([])

  const getStockHistory = useCallback(() => {
    setLoading(true)
    _fetchApiGeneral(
      `${apiURL()}/customer-stock-balance/history?query_type=by store&branch=${branch_name}&facilityId=${
        user.facilityId
      }`,
      (data) => {
        setLoading(false)
        if (data && data.results) {
          setList(data.results)
        }
      },
      (err) => {
        console.log(err)
        setLoading(false)
      },
    )
  }, [branch_name, user.facilityId])

  useEffect(() => getStockHistory(), [getStockHistory])
  const handleRangeChange = ({ target: { name, value } }) => {
    setRange((p) => ({ ...p, [name]: value }))
  }

//   const totalDebit = list.reduce((a, b) => a + parseFloat(b.dr), 0)
//   const totalCredit = list.reduce((a, b) => a + parseFloat(b.cr), 0)

//   const balance = totalDebit - totalCredit

  return (
    <>
      <BackButton type="link" />
      <div className="my-2">
        <CustomerCreditDetails customer={branch_name} />
      </div>
      {loading ? <Loading /> : null}
      <DaterangeSelector
        from={range.from}
        to={range.to}
        handleChange={handleRangeChange}
      />
      {/* <SearchBar
            filterText={searchTerm}
            onFilterTextChange={(searchTerm) => setSearchTerm(searchTerm)}
            placeholder="Search by Shop name, status and request no."
          /> */}
      <div id="account-entries-details">
        <PrintWrapper
          title={`Account Statement for ${branch_name} (${range.from} - ${range.to})`}
        >
          <Table bordered striped size="sm">
          <thead>
            <tr>
              <th className="text-center">Date</th>
              <th className="text-center">Item Name</th>
              <th className="text-center">Quantity In</th>
              <th className="text-center">Quantity Out</th>
              <th className="text-center">Selling Price (₦)</th>
            </tr>
          </thead>
          <tbody>
            {list.map((purchase, i) => (
              <tr key={i} className="-info">
                <td className="text-center">{purchase.transaction_date}</td>
                <td className="">{purchase.item_name || ""}</td>

                <td className="text-center">{purchase.qty_in}</td>
                <td className="text-center">{purchase.qty_out}</td>
                <td className="text-right">{formatNumber(purchase.selling_price)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        </PrintWrapper>
      </div>
    </>
  )
}

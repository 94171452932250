import React from "react";
import { Button } from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { RiFileExcel2Line } from "react-icons/ri";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

function ExcelGenerator({
  data = [],
  filename = "",
  disabled = false,
  title = "",
  className = "",
  size,
  customOnClick = null,
}) {
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      size
      outline
      color="success"
      className={className}
      onClick={(e) => {
        if (customOnClick) {
          customOnClick((_data) => exportToCSV(_data, filename));
        } else {
          exportToCSV(data, filename);
        }
      }}
      disabled={disabled}
    >
      {title !== "" ? title : "Export to Excel"}
      <RiFileExcel2Line size={20} className="ml-1" />
    </Button>
  );
}

export default ExcelGenerator;

import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Card, Table } from "reactstrap/lib";
import { apiURL } from "../../redux/actions";
import { _fetchApiGeneral } from "../../redux/actions/api";
import BackButton from "../comp/components/BackButton";
import Loading from "../comp/components/Loading";
import { formatNumber } from "../utils/helpers";
import useQuery from "../utils/UseQuery";

function CustomerInventoryHistory() {
  const query = useQuery();
  const branch = query.get("branch");
  const item = query.get("item");
  const trn_number = query.get("trn");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const getStockHistory = useCallback(() => {
    setLoading(true);
    _fetchApiGeneral(
      `${apiURL()}/customer-stock-balance/history?branch=${branch}&item=${item}&trn=${trn_number}&facilityId=${
        user.facilityId
      }`,
      (data) => {
        setLoading(false);
        if (data && data.results) {
          setList(data.results);
        }
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [branch, item, trn_number, user.facilityId]);

  useEffect(() => getStockHistory(), [getStockHistory]);

  return (
    <Card body>
      <BackButton type="link" />
      <div className='py-2'>
          <p>Branch: {branch}</p>
          <p>Item Code: {item}</p>
      </div>
      <div style={{ maxHeight: "80vh", overflow: "auto" }}>
        {loading && <Loading />}
        {/* {JSON.stringify(list)} */}

        <Table bordered striped size="sm">
          <thead>
            <tr>
              <th className="text-center">Date</th>
              <th className="text-center">Item Name</th>
              <th className="text-center">Quantity In</th>
              <th className="text-center">Quantity Out</th>
              <th className="text-center">Selling Price (₦)</th>
            </tr>
          </thead>
          <tbody>
            {list.map((purchase, i) => (
              <tr key={i} className="-info">
                <td className="text-center">{purchase.transaction_date}</td>
                <td className="">{purchase.item_name || ""}</td>

                <td className="text-center">{purchase.qty_in}</td>
                <td className="text-center">{purchase.qty_out}</td>
                <td className="text-right">{formatNumber(purchase.selling_price)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Card>
  );
}

export default CustomerInventoryHistory;

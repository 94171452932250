import React from "react";
import { Card, CardHeader, CardBody, Form, FormGroup } from "reactstrap";
import { useSelector } from "react-redux";

export default function GeneralInfo() {
  const facility = useSelector((state) => state.facility.info);
  return (
    <Card>
      <CardHeader tag="h5" className="text-center">
        Settings
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-direction-column align-items-center justify-content-center">
          <img
            src={require("../../images/logo1.png")}
            width="120em"
            alt="def"
          />
        </div>
        {/*{JSON.stringify(facility)}*/}
        <h5 className="text-center">{facility.facility_name}</h5>
        <Form>
          <FormGroup row>
            <div className="col-md-6 col-lg-6">
              <label>Facility Name</label>
              <input
                className="form-control"
                type="text"
                name="facility_name"
                // autoFocus
                value={facility.facility_name}
                disabled
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <label>Prefix</label>
              <input
                className="form-control"
                type="text"
                name="prefix"
                value={facility.prefix}
                disabled
                // onChange={handleChange}
              />
            </div>
          </FormGroup>
          <FormGroup row>
            <div className="col-md-6 col-lg-6">
              <label>Address</label>
              <input
                className="form-control"
                type="address"
                name="address"
                value={facility.address}
                disabled
                // onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <label>Email</label>
              <input
                className="form-control"
                type="email"
                name="email"
                disabled
                value={facility.email}
                // onChange={handleChange}
              />
            </div>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
}

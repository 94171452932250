import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
// import { AiOutlineFolderView } from "react-icons/ai";
import { AiTwotoneApi } from "react-icons/ai";
import { Nav, NavItem } from "reactstrap";
import {
  MdAssignmentLate,
  MdBusiness,
  MdLocalPharmacy,
  MdPresentToAll,
} from "react-icons/md";
import { hasAccess } from "../auth";
import { getReqNum } from "../utils/helpers";

const NavModules = ({ user, toggle, userAccess }) => {
  const [, setAuditor] = useState("");
  const [, setMang] = useState("");
  const [review, setReview] = useState("");
  const [, setAcct] = useState("");

  const reviewer = '"pending", "ManagementReject","BackToAuditor"';
  const account = "'ReviewerApproved'";
  const audit = '"BackToAuditor", "ManagementApproved","Reviewer"';
  const mana = '"Audited", "BackToManagement","ReviewerReject"';

  useEffect(() => {
    getReqNum(setAuditor, audit);
    getReqNum(setMang, mana);
    getReqNum(setReview, reviewer);
    getReqNum(setAcct, account);
  }, []);

  return (
    <Nav className="ml-auto" navbar>
      {/* {user.accessTo
        ? hasAccess(user, ["Pharmacy"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Pharmacy"
              route="/me/inventory"
              icon={<MdLocalPharmacy size={18} />}
            />
          )
        : null} */}
      {user.accessTo
        ? hasAccess(user, ["Stock Management" || "Inventory"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Stock Managment"
              route="/me/inventory"
              icon={<MdPresentToAll size={18} />}
            />
          )
        : null}

      {user.accessTo
        ? hasAccess(user, ["Sales"] ) && (
            <CustomNavItem
              toggle={toggle}
              title="Sales"
              route="/me/business"
              icon={<MdBusiness size={18} />}
            />
          )
        : null}

      {/* {user.accessTo
        ? hasAccess(user, ["Accounts"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Accounts"
              route="/me/account"
              icon={<FaCoins size={18} />}
              notify={acct}
              notifying={true}
            />
          )
        : null} */}

       {/* {user.accessTo
        ? hasAccess(user, ["Admin"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Map"
              route="/me/map"
              icon={<MdAssignmentLate size={18} />}
            />
          )
        : null}  */}
      {user.accessTo
        ? hasAccess(user, ["Administration"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Administration"
              route="/me/purchase-order/deposit"
              icon={<MdAssignmentLate size={18} />}
            />
          )
        : null}

      {user.accessTo
        ? hasAccess(user, ["Executive"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Executive"
              route="/me/auditor"
              icon={<AiTwotoneApi size={18} />}
              notify={review}
              // notifying={true}
            />
          )
        : null}
      {/* {user.accessTo
        ? hasAccess(user, ["Audit"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Auditor"
              route="/me/reviewer"
              icon={<AiOutlineFolderView size={18} />}
              notify={auditor}
              notifying={true}
            />
          )
        : null} */}

      {/* {user.accessTo
        ? hasAccess(user, ["Engineering"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Engineering"
              route="/me/eng"
              icon={<AiOutlineSetting size={18} />}
            />
          )
        : null} */}

      {/* {user.accessTo
        ? hasAccess(user, ["Management"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Management"
              route="/me/auditorr"
              icon={<AiOutlineWeibo size={18} />}
              notify={mang}
              notifying={true}
            />
          )
        : null} */}
      {user.accessTo
        ? hasAccess(user, ["Content Management"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Content Management"
              route="/me/content-management"
              icon={<FaUserAlt size={18} />}
            />
          )
        : null}
        {user.accessTo
        ? hasAccess(user, ["Admin"]) && (
            <CustomNavItem
              toggle={toggle}
              title="Admin"
              route="/me/admin"
              icon={<FaUserAlt size={18} />}
            />
          )
        : null}
    </Nav>
  );
};

function mapStateToProps(state) {
  return {
    userAccess: state.auth.user.accessTo,
  };
}

function CustomNavItem({
  toggle = (f) => f,
  title = "",
  route = "",
  icon,
  notify,
  notifying = false,
}) {
  return (
    <NavItem onClick={toggle} className="mx-1 px-1">
      <NavLink to={route} className="nav">
        <span className="mr-2">{icon}</span>
        {notifying ? (
          <span className="bg-warning px-1 rounded">{notify}</span>
        ) : null}
        {title}
      </NavLink>
    </NavItem>
  );
}

export default connect(mapStateToProps)(NavModules);

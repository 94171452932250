import { PDFViewer } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { FaPrint, FaWindowClose } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Table,
} from 'reactstrap'
import { apiURL } from '../../redux/actions'
import {
  _fetchApi,
  _postApi,
  _fetchApiGeneral,
  _updateApi,
} from '../../redux/actions/api'
import { formatNumber, _customNotify } from '../utils/helpers'
import SearchBar from '../utils/SearchBar'
import { DispensaryInventoryPDF } from './DispensaryInventoryPDF'
import CustomButton from '../comp/components/Button'
import EditItemModal from './inventory/EditItemModal'

export default function InventoryItemTab() {
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState('')
  const [inventoryItem, setInventoryItem] = useState([])
  const [newData, setNewData] = useState([])
  const [newItem, setNewItem] = useState('more items')
  const [prewiew, setPreview] = useState(true)
  const [syncing, setSyncing] = useState(false)

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [selected, setSelected] = useState({})

  const branch = useSelector((state) => state.auth.user.branch_name)
  const facilityId = useSelector((state) => state.auth.user.facilityId)

  const toggle = () => setEditModalOpen((p) => !p)

  const handlePrint = () => {
    setPreview((d) => !d)
  }
  const getInventory = () => {
    _fetchApi(
      `${apiURL()}/account/new/item_chart-dashboard`,
      (data) => {
        let finalData = data.results.map((i) => ({
          ...i,
          new_price: i.sale_price,
          new_balance: i.available_quantity,
          editMode: false,
          
        }))
        setInventoryItem(finalData)
      },
      (e) => console.log(e),
    )
    // _fetchApiGeneral(`${apiURL()}/account/get/inventory/${facilityId}?query_type=web`, (data) => {
    //   if (data.results) {
    //     let finalData = data.results.map((i) => ({
    //       ...i,
    //       new_price: i.unit_price,
    //       new_balance: i.balance,
    //       editMode: false,
    //     }));
    //     setInventoryItem(finalData);
    //   }
    //   // console.log(data);
    // });
  }

  const getInventory2 = () => {
    _fetchApi(
      `${apiURL()}/account/new/item_chart-dashboard`,
      (data) => {
        let finalData = data.results.map((i) => ({
          ...i,
          new_price: i.sale_price,
          new_balance: i.available_quantity,
          editMode: false,
        }))
        setNewData(finalData)
      },
      (e) => console.log(e),
    )

    // _fetchApiGeneral(
    //   `${apiURL()}/account/get/inventory2/${facilityId}?query_type=web`,
    //   (data) => {
    //     if (data.results) {
    //       let finalData = data.results.map((i) => ({
    //         ...i,
    //         new_price: i.unit_price,
    //         new_balance: i.balance,
    //         editMode: false,
    //       }))
    //       setNewData(finalData)
    //     }
    //     // console.log(data);
    //   },
    // )
  }

  const handleEditClick = (item) => {
    let editting = inventoryItem.map((i) =>
      i.item_name === item.item_name &&
      i.unit_price === item.unit_price &&
      i.expiring_date === item.expiring_date
        ? { ...i, editMode: !i.editMode }
        : i,
    )

    setInventoryItem(editting)
  }

  const handlePriceChange = (item, val) => {
    let editted = inventoryItem.map((i) =>
      i.item_name === item.item_name &&
      i.unit_price === item.unit_price &&
      i.expiring_date === item.expiring_date
        ? { ...i, new_price: val }
        : i,
    )
    setInventoryItem(editted)
  }

  const handleQtyChange = (item, val) => {
    let editted = inventoryItem.map((i) =>
      i.item_name === item.item_name &&
      i.unit_price === item.unit_price &&
      i.expiring_date === item.expiring_date
        ? { ...i, new_balance: val }
        : i,
    )
    setInventoryItem(editted)
  }

  const updateRow = (item) => {
    console.log(item)
    if (item.new_balance !== '' && item.new_price !== '') {
      _postApi(
        `${apiURL()}/procurement/update-intentory-price`,
        { ...item, query_type: 'update qty' },
        (resp) => {
          console.log(resp)
          _customNotify('Price Updated')
          getInventory()
          getInventory2()
        },
        (err) => console.log(err),
      )

      handleEditClick(item)
    }
  }

  // const updateRowQty = (item) => {
  //   _postApi(
  //     `${apiURL()}/procurement/update-intentory-price`,
  //     { ...item, query_type: "update qty", new_price: item.new_balance },
  //     (resp) => {
  //       console.log(resp);
  //       _customNotify("Quantity Updated");
  //       getInventory();
  //       getInventory2();
  //     },
  //     (err) => console.log(err)
  //   );

  //   handleEditClick(item);
  // };

  useEffect(() => {
    getInventory()
    getInventory2()
  }, [])
  const moreItems = newItem === 'more items'

  let newArr = moreItems ? inventoryItem : newData
  const rows = []

  const handleSync = () => {
    setSyncing(true)
    _postApi(
      `${apiURL()}/sync-erp-kirana-inventory`,
      {},
      (resp) => {
        setSyncing(false)
        console.log(resp)
      },
      (e) => {
        setSyncing(false)
        console.log(e)
      },
    )
  }

  newArr &&
    newArr.forEach((purchase, i) => {
      if (
        purchase.item_name
          .toString()
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase()) === -1
      ) {
        return
      }
      rows.push(
        <tr key={i} className="bg-info">
          {moreItems ? (
            <>
              <td className="text-center">
                <>
                  {purchase.editMode ? (
                    <Button
                      size="sm"
                      color="warning"
                      onClick={() => updateRow(purchase)}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      color="success"
                      // onClick={() => handleEditClick(purchase)}
                      onClick={() => {
                        setSelected(purchase)
                        toggle()
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </>
              </td>
            </>
          ) : null}
          <td className="text-center">
            <img
              src={purchase.item_image}
              alt="item_image"
              // height={200}
              width={20}
            />
          </td>
          <td className="">
            <Button
              size="sm"
              color={purchase.available === 0 ? 'danger' : 'success'}
              onClick={() => {
                _updateApi(
                  `${apiURL()}/procurement/disable-product`,
                  {
                    item_id: purchase.item_id,
                    availability: purchase.available === 0 ? 1 : 0,
                  },
                  () => {
                    _customNotify('Item Disabled Successfully')
                    getInventory()
                    getInventory2()
                  },
                  (e) => {
                    getInventory()
                    getInventory2()
                  },
                )
              }}
            >
              {purchase.available === 0 ? 'Disabled' : 'Available'}
            </Button>
          </td>
          <td className="">{purchase.item_name || ''}</td>
          <td className="text-right">
            {purchase.editMode ? (
              <Input
                size="sm"
                value={purchase.new_price}
                onChange={({ target: { value } }) =>
                  handlePriceChange(purchase, value)
                }
                // onBlur={() => updateRow(purchase)}
              />
            ) : (
              formatNumber(purchase.price)
            )}
          </td>
          <td className="text-center">
            {purchase.editMode ? (
              <Input
                size="sm"
                value={purchase.new_balance}
                onChange={({ target: { value } }) =>
                  handleQtyChange(purchase, value)
                }
                // onBlur={() => updateRow(purchase)}
              />
            ) : (
              formatNumber(purchase.balance)
            )}

            {/* {purchase.balance || ""} */}
          </td>
          <td className="text-center">
            
             { formatNumber(purchase.unit)}

            {/* {purchase.balance || ""} */}
          </td>
          {/* {moreItems && (
            <> */}
          {/* <td className="text-center">{purchase.expiring_date || ""}</td> */}
          <td className="">{purchase.product_category}</td>
          {/* </>
          )} */}
        </tr>,
      )
    })
  return (
    <>
      <Card>
        <CardBody>
          {/* {JSON.stringify(newArr)} */}
          <div className="d-flex justify-content-end align-items-center">
            {/* {prewiew ? (
              <>
                <FormGroup check className="mr-4">
                  <Input
                    id="radio1-option1"
                    type="radio"
                    name="newItem"
                    value="items"
                    checked={newItem === "items"}
                    onChange={(e) => setNewItem(e.target.value)}
                  />
                  <Label
                    check
                    for="radio1-option1"
                    className="font-weight-bold"
                  >
                    Item List
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    type="radio"
                    value="more items"
                    name="newItem"
                    checked={moreItems}
                    id="radio1-option2"
                    onChange={(e) => setNewItem(e.target.value)}
                  />
                  <Label
                    check
                    for="radio1-option2"
                    className="font-weight-bold"
                  >
                    More Details (e.g Edit Amt)
                  </Label>
                </FormGroup>
              </>
            ) : null} */}
            {/* <CustomButton
              onClick={() =>
                history.push('/me/purchase-order/make-purchases/form')
              }
              className=" px-4"
            >
              Add New SKU
            </CustomButton> */}
            <Button
              onClick={handleSync}
              className="px-4 ml-auto"
              color="success"
            >
              <FaPrint size="16" className="mr-1" /> Update Inventory
            </Button>
            {prewiew ? (
              <Button
                onClick={handlePrint}
                className="px-4 ml-auto"
                color="warning"
              >
                <FaPrint size="16" className="mr-1" /> Print
              </Button>
            ) : (
              <>
                <div>
                  <Button
                    color="danger"
                    className="p-3 float-right mb-3"
                    onClick={handlePrint}
                  >
                    <FaWindowClose size="20" /> Close
                  </Button>
                </div>
                <center>
                  <PDFViewer height="900" width="600">
                    <DispensaryInventoryPDF
                      list={newArr}
                      branch={branch}
                      type="Main Store"
                    />
                  </PDFViewer>
                </center>
              </>
            )}
          </div>

          <FormGroup className="m-3">
            <SearchBar
              filterText={searchTerm}
              onFilterTextChange={(value) => setSearchTerm(value)}
              placeholder="Search item name"
            />
          </FormGroup>
          {prewiew ? (
            <Scrollbars style={{ height: 400 }}>
              <Table bordered striped size="sm">
                <thead>
                  <tr>
                    {moreItems ? (
                      <>
                        <th className="text-center">Action</th>
                      </>
                    ) : null}

                    <th className="text-center">Item Image</th>
                    <th className="text-center">Availability</th>
                    <th className="text-center">Item Name</th>
                    <th className="text-center">Selling Price</th>
                    <th className="text-center">QTY</th>
                    <th className="text-center">Unit QTY</th>
                    {/* {moreItems && 
                    <> */}
                    {/* <th className="text-center">Expiry Date </th> */}
                    <th className="text-center">Item Category</th>
                    {/* </>
                    } */}
                    {/* {user.role === "Admin" && <th> Action</th>} */}
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            </Scrollbars>
          ) : null}
        </CardBody>
        <EditItemModal
          isOpen={editModalOpen}
          toggle={toggle}
          selected={selected}
          getInventory2={getInventory2}
          getInventory={getInventory}
        />
      </Card>
    </>
  )
}

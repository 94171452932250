import React, { useState } from 'react'
import { useEffect } from 'react'
import { Route, Switch } from 'react-router'
import { Button, Card } from 'reactstrap'
import { CardBody, CardHeader } from 'reactstrap/lib'
import { apiURL } from '../../redux/actions'
import { _fetchApiGeneral } from '../../redux/actions/api'
import AccountEntriesDetails from '../account/account-entries/AccountEntriesDetails'
import ExcelGenerator from '../account/ExcelGenerator'
import CustomButton from '../comp/components/Button'
// import SearchBar from "../utils/SearchBar";
import useQuery from '../utils/UseQuery'
import CustomerCreditLimit from './CustomerCreditLimit'
import CustomerStockHistory from './CustomerStockHistory'

function CreditDetails() {
  const query = useQuery()
  const pageType = query.get('page_type')
  const [searchTerm, setSearchTerm] = useState('')
  const [data, setData] = useState([])
  // const history = useHistory();

  const getList = () => {
    _fetchApiGeneral(
      `${apiURL()}/account/customer-details?query_type=list`,
      (data) => {
        if (data && data.results && data.results.length) {
          setData(data.results)
          // const account_name = data.results[0].accName
        }
      },
      (err) => {
        console.log(err)
      },
    )
  }

  useEffect(() => {
    getList()
  }, [])

  let rows = []
  data.length &&
    data.forEach((item, index) => {
      if (item.accName.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1)
        return
      rows.push(
        <tr key={index}>
          <td style={{ cursor: 'pointer' }} onClick={() => {}}>
            {item.accName}
          </td>
          <td>{item.utilized_credit}</td>
          <td>{item.credit_limit}</td>
          <td>{item.credit_allowance}</td>
          <td>
            <Button
              outline
              color="success"
              size="sm"
              //   onClick={() =>
              //     history.push(
              //       `/me/business/create-customer-account/form/${item.accountNo}`
              //     )
              //   }
            >
              View Details
            </Button>
          </td>
        </tr>,
      )
    })

  const printPage = () => {
    // console.log('printting', pageType)

    if (pageType && pageType === 'details') {
      window.frames[
        'print_frame'
      ].document.body.innerHTML = document.getElementById(
        'account-entries-details',
      ).innerHTML
      window.frames['print_frame'].window.focus()
      window.frames['print_frame'].window.print()
    } else {
      window.frames[
        'print_frame'
      ].document.body.innerHTML = document.getElementById(
        'customer-account-balance',
      ).innerHTML
      window.frames['print_frame'].window.focus()
      window.frames['print_frame'].window.print()
    }
  }

  return (
    <Card>
      <CardHeader className="d-flex flex-direction-row justify-content-between">
        <h6>Customers</h6>
        
        <CustomButton size="sm" className="px-4" onClick={printPage}>
          Print
        </CustomButton>
      </CardHeader>
      <CardBody>
        <Switch>
          <Route path="/me/business/customer-account-entries">
            {pageType && pageType === 'details' ? (
              <AccountEntriesDetails />
            ) : pageType && pageType === 'stock-history' ? (
              <CustomerStockHistory />
            ) : (
              <CustomerCreditLimit />
            )}
          </Route>
          {/* <Route path="/me/business/customer-stock-history">
            <CustomerStockHistory />
          </Route> */}
          {/* <Route exact path='/me/business/customer-account-entries/details' component={AccountEntriesDetails} /> */}
        </Switch>
        <iframe
          title="customer-account"
          name="print_frame"
          width="0"
          height="0"
          src="about:blank"
          // style={styles}
        />
      </CardBody>
    </Card>
  )
}

export default CreditDetails

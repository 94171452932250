// records action types
export const GET_IDS = "GET_IDS";
export const GET_UNASSIGNED_PATIENTS = "GET_UNASSIGNED_PATIENTS";
export const IDS_LOADING = "IDS_LOADING";
export const GET_PATIENT_LIST = "GET_PATIENT_LIST";
export const SET_PATIENTS = "SET_PATIENTS";
export const SET_CURRENT_PATIENT = "SET_CURRENT_PATIENT";

// pharmacy action types
export const LOAD_EXPIRED = "LOAD_EXPIRED";
export const LOAD_EXPIRY_ALERT = "LOAD_EXPIRY_ALERT";
export const LOAD_QTTY_ALERT = "LOAD_QTTY_ALERT";
export const GET_INVENTORY = "GET_INVENTORY";
export const GET_PURCHASE_RECORDS = "GET_PURCHASE_RECORDS";
export const GET_PENDING_PURCHASES = "GET_PENDING_PURCHASES";
export const SET_INVENTORY = "SET_INVENTORY";
export const SET_PURCHASE_RECORDS = "SET_PURCHASE_RECORDS";
export const ADD_DRUG = "ADD_DRUG";
export const INVENTORY_LOADING = "INVENTORY_LOADING";
export const PURCHASE_RECORDS_LOADING = "PURCHASE_RECORDS_LOADING";
export const SUBMIT_DRUG_LOADING = "SUBMIT_DRUG_LOADING";
export const UPDATE_DRUG = "UPDATE_DRUG";
export const UPDATING_DRUG = "UPDATING_DRUG";
export const PENDING_PURCHASES_LOADING = "PENDING_PURCHASES_LOADING";
export const DISPENSING_DRUGS = "DISPENSING_DRUGS";
export const DISPENSE_DRUGS = "DISPENSE_DRUGS";
export const GETTING_ALL_SUPPLIERS = "GETTING_ALL_SUPPLIERS";
export const GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS";
export const CREATE_SUPPLIER = "CREATE_SUPPLIER";
export const CREATING_SUPPLIERS = "CREATING_SUPPLIERS";
export const UPDATING_SUPPLIER = "UPDATING_SUPPLIER";
export const DELETING_SUPPLIER = "DELETING_SUPPLIER";
export const GET_DRUGS = "GET_DRUGS";
export const ADDING_DRUG = "ADDING_DRUG";
export const DRUGS_LOADING = "DRUGS_LOADING";
export const DELETING_DRUG = "DELETING_DRUG";
export const GET_CUSTOMERS = "GET_CUSTMERS";
export const GETTING_CUSTOMERS = "GETTING_CUSTOMERS";

// account
export const GETTING_SUMMARY_REPORT = "GETTING_SUMMARY_REPORT";
export const GET_SUMMARY_REPORT = "GET_SUMMARY_REPORT";

// services action types
export const NEW_TRANSACTION = "NEW_TRANSACTION";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const DELETE_SERVICE_LOADING = "DELETE_SERVICE_LOADING";

// maintenance action types
export const NEW_DIESEL_USAGE = "NEW_DIESEL_USAGE";
export const NEW_DIESEL_REFUEL = "NEW_DIESEL_REFUEL";
export const NEW_SERVICE_LOG = "NEW_SERVICE_LOG";
export const NEW_REPAIR_ERROR_LOG = "NEW_REPAIR_ERROR_LOG";

// laboratory action type
export const GET_LAB_SERVICES = "GET_LAB_SERVICES";
export const LOADING_LAB_SERVICES = "LOADING_LAB_SERVICES";
export const GET_PENDING_LAB_REQUEST = "GET_PENDING_LAB_REQUEST";
export const LOADING_PENDING_LAB_REQUEST = "GET_PENDING_LAB_REQUEST";
export const LOADING_PATIENT_LAB_TEST = "LOADING_PATIENT_LAB_TEST";
export const GET_PATIENT_LAB_TEST = "GET_PATIENT_LAB_TEST";
export const GET_LAB_SERVICES_RAW = "GET_LAB_SERVICES_RAW";

export const GETTING_FACILITY_INFO = "GETTING_FACILITY_INFO";
export const GET_FACILITY_INFO = "GET_FACILITY_INFO";

export const GET_FACILITY_PENDING_LABS = "GET_FACILITY_PENDING_LABS";
export const LOADING_FACILITY_PENDING_LABS = "LOADING_FACILITY_PENDING_LABS";

export const SET_CURRENT_PATIENT_LAB_INFO = "SET_CURRENT_PATIENT_LAB_INFO";
export const SET_LAB_TREE = "SET_LAB_TREE";
export const LOADING_LAB_TREE = "LOADING_LAB_TREE";
export const GET_VALID_TESTS = "GET_VALID_TESTS";
export const GET_OTHER_EXPENSES = "GET_OTHER_EXPENSES"
export const GET_SALES = "GET_SALES"
export const GET_SALES2 = "GET_SALES2"
// dashboard
export const GET_PHARM_SALES_SUMMARY = "GET_PHARM_SALES_SUMMARY";
export const GET_STORE_SUMMARY = 'GET_STORE_SUMMARY'
export const GETTING_PHARM_SALES_SUMMARY = "GETTING_PHARM_SALES_SUMMARY";
export const CLEAR_PHARM_SALES_SUMMARY = "CLEAR_PHARM_SALES_SUMMARY";
export const GET_PHARM_TOTAL_STOCK = "GET_PHARM_TOTAL_STOCK";
export const GETTING_PHARM_TOTAL_STOCK = "GETTING_PHARM_TOTAL_STOCK";
export const GETTING_TOP_5_POPULAR_DRUGS = "GETTING_TOP_5_POPULAR_DRUGS";
export const GET_TOP_5_POPULAR_DRUGS = "GET_TOP_5_POPULAR_DRUGS";

// admin dashboard
export const LOADING_ADMIN_ALL_APPOINTMENT_LIST =
  "LOADING_ADMIN_ALL_APPOINTMENT_LIST";
export const GET_ADMIN_ALL_APPOINTMENT_LIST = "GET_ADMIN_ALL_APPOINTMENT_LIST";
export const GOOD_TRANFER_CALCULATION = "GOOD_TRANFER_CALCULATION";
export const MARKUPTYPE = "MARKUPTYPE";
export const MARKUPVALUE = "MARKUPVALUE";
export const UNITCOST = "UNITCOST";
export const QUANTITY = "QUANTITY";
export const OTHEREXPENSES = "OTHEREXPENSES";
export const RESETGRNFORM = "RESETGRNFORM";
export const GET_BRANCHES = "GET_BRANCHES";

// purchase-order
export const GET_PURCHASE_ORDER_REPORT = 'GET_PURCHASE_ORDER_REPORT'
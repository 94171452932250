import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { GrDown, GrUp } from "react-icons/gr";
import InputGroup from "../../comp/components/InputGroup";
import SelectInput from "../../comp/components/SelectInput";
import CustomInput from "../../auth/registration/component/Input";
import { Typeahead } from "react-bootstrap-typeahead";
import { _fetchApi, _fetchApiGeneral } from "../../../redux/actions/api";
import { apiURL } from "../../../redux/actions";
import { useEffect } from "react";
import { stateApi } from "./Api";

function BasicInformation({
  client = {},
  handleRadio = (f) => f,
  onInputChange = (f) => f,
  setContactPerson = (f) => f,
  accNoChange = (f) => f,
  setAccountType = (f) => f,
  handleSelectedBank = (f) => f,
  accounts = [],
  depositFocus = (f) => f,
  setClientObj = (f) => f,
  handleUsernameChange = (f) => f,
  checkingUsername,
  usernameGood,
  usernameMsg,
  setClient,
}) {
  const [isOpen, toggleOpen] = useState(true);
  const [users, setUsers] = useState([]);
  const fetchData = () => {
    let arr = [];
    _fetchApiGeneral(
      `${apiURL()}/users`,
      ({ results }) => {
        results.forEach((item) => {
          if (item.role === "CRM") {
            arr.push(item);
          }
        });

        setUsers(arr);
      },
      (err) => console.log(err)
    );
  };


  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => toggleOpen(!isOpen);
  const bankList = [
    "Sterling Bank",
    "First Bank",
    "Access Bank",
    "TAZ Bank",
    "Union Bank",
  ];
 const [selected,setSelected] = useState({})

  // const facility = useSelector((state) => state.facility.info);

  // const facilityIsFactory = facility.type === "factory";
  return (
    <Card className="mb-1">
  {/* {JSON.stringify(client)} */}
      <CardHeader
        tag="div"
        className="d-flex flex-direction-row justify-content-between btn"
        onClick={toggle}
      >
        <span>Basic Information</span>
        <span>{isOpen ? <GrDown /> : <GrUp />}</span>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody className="d-flex flex-direction-row flex-wrap">
          <InputGroup
            name="name"
            onChange={onInputChange}
            label={
              client.accountType === "Family"
                ? "Full Name"
                : "Organization Name"
            }
            value={client.name}
            container="col-md-6"
            required
          />

          {client.accountType === "Family" ? (
            <>
              <InputGroup
                label="Address"
                container="col-md-6"
                name="address"
                value={client.address}
                onChange={onInputChange}
                placeholder="Apartment, studio, or floor"
                required
              />
              <Col md={4}>
                <FormGroup>
                  <Label for="date">Year Of Bussiness</Label>
                  <Input
                    type="date"
                    name="year_of_bus"
                    value={client.year_of_bus}
                    onChange={onInputChange}
                  />
                </FormGroup>
              </Col>
              <SelectInput
                name="bus_type"
                onChange={onInputChange}
                label="Type of business"
                options={[
                  "Proprietorship",
                  "Partnership",
                  "LTD Company",
                  "PLC",
                ]}
                value={client.bus_type}
                container="col-md-4"
                required
              />

              <InputGroup
                name="business_name"
                onChange={onInputChange}
                label="Business Name"
                value={client.business_name}
                container="col-md-4" required
              />
            </>
          ) : null}
          <InputGroup
            name="phone"
            onChange={onInputChange}
            label="Phone Number"
            value={client.phone}
            container="col-md-4"
            required
          />

          {/* <InputGroup
            name="depositAmount"
            onChange={onInputChange}
            label="Deposit Amount (Optional)"
            onFocus={depositFocus}
            value={client.depositAmount}
            container="col-md-4"
          /> */}

          {/* <SelectInput
            name="modeOfPayment"
            onChange={onInputChange}
            label="Mode of payment"
            value={client.modeOfPayment}
            container="col-md-4"
            options={["Cash", "POS", "Bank Transfer"]}
          /> */}

          {/* {client.modeOfPayment === "Bank Transfer" ? (
            <div className="col-md-4">
              <label className="">Select Bank</label>
              <Typeahead
                align="justify"
                labelKey={(item) => item}
                id="bankList"
                options={bankList}
                onChange={(val) => {
                  if (val.length) {
                    handleSelectedBank(val[0]);
                  }
                }}
                onInputChange={(name) => handleSelectedBank(name[0])}
              />
            </div>
          ) : null} */}
          {/* <InputGroup
            name="credit_limit"
            onChange={onInputChange}
            label="Credit Limit"
            // onFocus={depositFocus}
            value={client.credit_limit}
            container="col-md-4"
            required
          /> */}

          {/* <CustomInput
            className="col-lg-12"
            label="Username"
            name="username"
            onChange={(e) => handleUsernameChange(e.target.value)}
            value={client.username}
            placeholder="e.g. abba"
            required
            loading={checkingUsername}
            good={usernameGood}
            message={usernameMsg}
          /> */}
          {/* <InputGroup
            name="password"
            onChange={onInputChange}
            label="Password"
            type="password"
            value={client.password}
            container="col-md-4"
            required
          /> */}
          <div className="col-md-4">
            <label className="">Customer Relationship Officer</label>
            <Typeahead
              align="justify"
              labelKey={(item) => item.busName}
              id="crm"
              options={users}
              onChange={(val) => {
                if (val.length) {
                  setClient((p) => ({ ...p, crm: val[0].busName }));
                }
              }}
              onInputChange={(name) =>
                setClient((p) => ({ ...p, crm: name[0].busName }))
              }
            />
          </div>
          
          <SelectInput
            name="state"
            onChange={({target:{value}})=>{
              setClient((p)=>({...p, state:value}))
              setSelected(stateApi.filter(a=>a.state === value)[0])
            }}
            label="State"
            value={client.state}
            container="col-md-4"
            options={stateApi.map(i=>(i.state))} required
          />
          <SelectInput
            name="lga"
            onChange={onInputChange}
            label="L.G.A"
            value={client.lga}
            container="col-md-4"
            options={selected.lgas} required
          />
            <SelectInput
            name="customer_type"
            onChange={onInputChange}
            label="Customer Type"
            value={client.customer_type}
            container="col-md-4"
            options={["Kirana","Uwargida", "Others"]} required
          />
          
         
          <Col md={12} className="bg-light">
            <h4 className="text-center">
              <kbd>Guarantor Form</kbd>
            </h4>
            <Row className=" m-0 mt-4">
              {client.contact === "self" ? (
                ""
              ) : (
                <>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="name">Name of Guarantor</Label>
                        <Input
                          type="name"
                          name="guarantor_name"
                          value={client.guarantor_name}
                          onChange={onInputChange}
                          placeholder="Name" required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="name">Guarantor's Contact Address</Label>
                        <Input
                          name="guarantor_address"
                          value={client.guarantor_address}
                          onChange={onInputChange}
                          placeholder="Address" required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="phone" className="font-weight-bold">
                          Guarantor's Phone Number
                        </Label>
                        <Input
                          type="tel"
                          name="guarantor_phoneNo"
                          value={client.guarantor_phoneNo}
                          onChange={onInputChange}
                          placeholder="Telephone Number" required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="name">Relationship with applicant</Label>
                        <Input
                          name="relation_with_app"
                          value={client.relation_with_app}
                          onChange={onInputChange}
                          placeholder="eg Brother"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="name">
                          Years relationship with applicant
                        </Label>
                        <Input
                          name="year_relationship_with_app"
                          value={client.year_relationship_with_app}
                          onChange={onInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
            </Row>
          </Col>
        </CardBody>
      </Collapse>
    </Card>
  );
}

export default BasicInformation;

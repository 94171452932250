import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // Badge
} from 'reactstrap';
import { Avatar } from 'evergreen-ui';
import { GoSignOut } from 'react-icons/go';
import { FaUser } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
// import { MdNotifications } from 'react-icons/md'
// import Profile from './Profile';
import { MdDashboard } from 'react-icons/md';

function UserAvatar({ user, logout }) {
  const [isOpen, setState] = useState(false);
  const toggle = () => setState((prevState) => !prevState);
  const history = useHistory();
  const location = useLocation();

  const _gotoAdminDashboard = () => {
    history.push('/admin');
  };

  const _gotoApp = () => {
    history.push('/me');
  };

  const isAdminPage = location.pathname.includes('/admin');

  const _action = isAdminPage ? _gotoApp : _gotoAdminDashboard;

  const _actionText = isAdminPage ? 'Back to App' : 'Admin Dashboard';

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Dropdown
        isOpen={isOpen}
        toggle={toggle}
        direction="left"
        style={{ cursor: 'pointer' }}
      >
        <DropdownToggle tag="div">
          <Avatar
            src={user.image}
            name={`${user.firstname} ${user.lastname}`}
            size={40}
          />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>
            Signed in as <b>{user.username}</b>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag="div" onClick={() => history.push('/me/profile')}>
            <FaUser style={{ margin: '0 20px 0 0' }} />
            Profile
          </DropdownItem>
          {user.userType === 'admin' ? (
            <DropdownItem tag="div" onClick={_action}>
              <MdDashboard style={{ margin: '0 20px 0 0' }} />
              {_actionText}
            </DropdownItem>
          ) : null}
          <DropdownItem tag="div" onClick={logout}>
            <GoSignOut style={{ margin: '0 20px 0 0' }} />
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

export default connect(mapStateToProps)(UserAvatar);

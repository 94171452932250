import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
} from 'reactstrap'
import { LoadingSM } from '../loading'
import { createUser, getRoles } from '../../redux/actions/auth'
import { _customNotify, _warningNotify } from '../utils/helpers'
import allModule from './moduleData'
import { _fetchApi, _postApi } from '../../redux/actions/api'
import { apiURL } from '../../redux/actions'
import SelectInput from '../comp/components/SelectInput'
import { checkUsername } from '../auth/registration/api'
import Input from '../auth/registration/component/Input'
import BranchName from '../utils/GlobalHelper'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import CheckBoxNormal from '../utils/CheckBoxNormal'
import CheckBoxItem from '../utils/CheckBoxItem'

// sulaimanbaba80@gmail.com
// Sulaiman Rabiu Baba
class NewUser extends PureComponent {
  state = {
    busName: '',
    phone: '',
    username: '',
    email: '',
    password: '',
    role: '',
    speciality: '',
    accessTo: [],
    functionalities: [],
    department: '',
    error: '',
    labDepartments: [],
    checkingUsername: false,
    usernameGood: false,
    usernameMsg: '',
    branch_name: '',
    id: '',
  }
  handleUserUpdate = (e) => {
    e.preventDefault()
    let newAccessTo = this.state.accessTo.join(',')
    let newFunctionality = this.state.functionalities.join(',')
    const data = {
      ...this.state,
      accessTo: newAccessTo,
      functionalities: newFunctionality,
    }
    _postApi(
      `${apiURL()}/users/access/update`,
      data,
      (res) => {
        // if (res.results) {
        _customNotify('User Updated successfully')
        // }
        this.props.history.push('/me/admin/manage-users')
      },
      (err) => {
        console.log(err)
        _warningNotify('There was an error when updating user')
      },
    )
  }

  handleFetch = (id) => {
    _fetchApi(
      `${apiURL()}/users/getById/${id}`,
      (data) => {
        console.log(data)
        if (data.results.length) {
          let acc = data.results[0].accessTo.split(',')
          let fuc = data.results[0].functionality.split(',')
          let { role, email, busName, phone, branch_name } = data.results[0]
          this.setState({
            functionalities: fuc,
            accessTo: acc,
            role: role,
            email: email,
            busName: busName,
            phone: phone,
            branch_name: branch_name,
          })
        }
      },
      (err) => console.log(err),
    )
  }
  handleUsernameChange = (value) => {
    this.setState({
      username: value,
      checkingUsername: true,
    })
    checkUsername(
      value,
      (msg) => {
        this.setState({
          checkingUsername: false,
          usernameGood: true,
          usernameMsg: msg,
        })
      },
      (err) => {
        this.setState({
          checkingUsername: false,
          usernameGood: false,
          usernameMsg: err,
        })
      },
    )
  }

  getLabDepartments = () => {
    _fetchApi(
      `${apiURL()}/lab/departments/list`,
      (data) => {
        if (data.success) {
          this.setState({ labDepartments: data.results })
        }
      },
      (err) => console.log(err),
    )
  }
  componentDidMount() {
    this.props.getRoles()
    const id = this.props.match.params.id
    this.setState({
      id: id,
    })
    if (id) {
      this.handleFetch(id)
    }
  }

  handleChange = ({ target }) => {
    this.setState({
      error: '',
      [target.name]: target.value,
    })
  }

  resetForm = () => {
    this.setState({
      busName: '',
      phone: '',
      username: '',
      email: '',
      password: '',
      role: '',
      speciality: '',
      accessTo: [],
      functionalities: [],
      branch_name: '',
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      busName,
      phone,
      username,
      email,
      password,
      role,
      speciality,
      accessTo,
      functionalities,
      department,
      branch_name,
    } = this.state
    const { facilityId } = this.props.user
    if (
      busName === '' ||
      phone === '' ||
      username === '' ||
      password === '' ||
      branch_name === '' ||
      accessTo.length === 0 ||
      functionalities.length === 0
    ) {
      this.setState({ error: 'Please complete the form' })
    } else {
      const data = {
        busName,
        phone,
        username,
        email,
        password,
        role,
        speciality,
        accessTo: accessTo.join(','),
        functionality: functionalities.join(','),
        department: department,
        facilityId,
        branch_name,
      }
      let callback = () => {
        _customNotify('User successfully created!')
        this.resetForm()
      }
      let error = (err) => {
        _warningNotify(err)
        this.resetForm()
      }
      this.props.createUser(data, callback, error)
      // console.log(data);
    }
  }

  handleCheckboxChange = (val) => {
    const { accessTo } = this.state
    if (accessTo.includes(val)) {
      let newVal = accessTo.filter((item) => item !== val)
      this.setState({ accessTo: newVal })
    } else {
      let newVal = [...accessTo, val]
      this.setState({ accessTo: newVal })
    }
  }

  handleFunctionalities = (val) => {
    const { functionalities } = this.state
    if (functionalities.includes(val)) {
      let newVal = functionalities.filter((item) => item !== val)
      this.setState({ functionalities: newVal })
    } else {
      let newVal = [...functionalities, val]
      this.setState({ functionalities: newVal })
    }
  }

  isChecked = (name) => this.state.accessTo.includes(name)

  render() {
    const {
      handleChange,
      handleSubmit,
      handleCheckboxChange,
      handleFunctionalities,
      isChecked,
      state: {
        busName,
        phone,
        username,
        email,
        password,
        role,
        speciality,
        accessTo,
        error,
        functionalities,
        department,
        branch_name,
        id,
      },
      props: { creatingUser, facility },
      handleUserUpdate,
    } = this

    return (
      <Form onSubmit={id ? handleUserUpdate : handleSubmit}>
        <Card>
          <CardHeader tag="h5" className="text-center">
            {id ? 'User Information' : 'Create User Account'}
          </CardHeader>
          <CardBody>
            {/* {JSON.stringify({accessTo, functionalities})} */}

            <Form>
              <FormGroup row>
                <div className="col-md-6 col-lg-6">
                  <label>Full Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="busName"
                    autoFocus
                    value={busName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 col-lg-6">
                  <label>Phone Number</label>
                  <input
                    className="form-control"
                    type="text"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                  />
                </div>
                {/* </FormGroup>
              <FormGroup row> */}

                <div
                  className={id ? 'col-md-12 col-lg-12' : 'col-md-6 col-lg-6'}
                >
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                </div>
                {id ? (
                  ''
                ) : (
                  <div className="col-md-6 col-lg-6">
                    <Input
                      className="col-lg-6"
                      label="Username"
                      name="username"
                      onChange={(e) =>
                        this.handleUsernameChange(e.target.value)
                      }
                      value={username}
                      placeholder="e.g. abba"
                      required
                      loading={this.state.checkingUsername}
                      good={this.state.usernameGood}
                      message={this.state.usernameMsg}
                    />
                  </div>
                )}
              </FormGroup>

              <FormGroup row>
                {id ? (
                  ''
                ) : (
                  <div className="col-md-4 col-lg-4">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      value={password}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className={id ? 'col-md-8 col-lg-8' : 'col-md-4 col-lg-4'}>
                  <label>Role</label>
                  <input
                    onChange={handleChange}
                    name="role"
                    type="text"
                    list="role"
                    autoComplete="disabled"
                    value={role}
                    className="form-control"
                  />
                  <datalist id="role">
                    {this.props.roles.map((role, i) => (
                      <option value={role} />
                    ))}
                  </datalist>
                  {role.toLowerCase() === 'doctor' ? (
                    <>
                      <label>Speciality</label>
                      <input
                        className="form-control"
                        name="speciality"
                        value={speciality}
                        onChange={handleChange}
                      />
                    </>
                  ) : null}
                </div>
                {facility.type === 'factory' && (
                  <BranchName
                    value={branch_name}
                    handleChange={(e) =>
                      this.setState({ branch_name: e.target.value })
                    }
                    name="branch_name"
                  />
                )}
                {facility.type === 'laboratory' && (
                  <div className="col-md-6 col-lg-6">
                    <Label>Department</Label>
                    {/* <Input type="select" className="form-control">
                    <option>Microbiology</option>
                    <option>Radiology</option>
                    <option>Chemical Pathology</option>
                    <option>Hamotology</option>
                  </Input> */}
                    {/* {JSON.stringify(department)} */}
                    <SelectInput
                      name="department"
                      value={department}
                      options={[
                        'Microbiology',
                        'Radiology',
                        'Chemical',
                        'Hamotology',
                      ]}
                      onChange={(e) =>
                        this.setState({ department: e.target.value })
                      }
                    />
                  </div>
                )}
              </FormGroup>
              <FormGroup row>
                <div className="container ">
                  <h6>Access (User's Privilege)</h6>
                  <div className="row m-2">
                    {/* <div className="col-md-3">
                      <CheckBoxNormal
                        label="Reports"
                        name="Reports"
                        handleCheck={handleCheckboxChange}
                        value={accessTo}
                        className="font-weight-bold"
                      />
                      {allModule.reports.type.map((item) =>
                        isChecked("Reports") ? (
                          <CheckBoxItem
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={functionalities}
                          />
                        ) : null
                      )}
                    </div> */}
                    <div className="col-md-3">
                      {facility.type === 'hospital' ||
                      facility.type === 'pharmacy' ||
                      facility.type === 'laboratory' ||
                      facility.type === 'factory' ? (
                        <CheckBoxNormal
                          label="Admin"
                          name="Admin"
                          handleCheck={handleCheckboxChange}
                          value={accessTo}
                          className="font-weight-bold"
                        />
                      ) : null}
                      {facility.type === 'hospital' ||
                      facility.type === 'pharmacy' ||
                      facility.type === 'laboratory' ||
                      facility.type === 'factory'
                        ? allModule.admin.type.map((item) =>
                            isChecked('Admin') ? (
                              <CheckBoxItem
                                label={item}
                                name={item}
                                handleCheck={handleFunctionalities}
                                value={functionalities}
                              />
                            ) : null,
                          )
                        : null}
                    </div>

                    {/* <div className="col-md-3 col-lg-3">
                      {facility.type === "hospital" && (
                        <CheckBoxNormal
                          className="font-weight-bold"
                          label="Maintenance"
                          name="Maintenance"
                          handleCheck={handleCheckboxChange}
                          value={accessTo}
                        />
                      )}
                      {facility.type === "hospital" ||
                      facility.type === "pharmacy" ||
                      facility.type === "factory" ? (
                        <CheckBoxNormal
                          className="font-weight-bold"
                          label="Accounts"
                          name="Accounts"
                          handleCheck={handleCheckboxChange}
                          value={accessTo}
                        />
                      ) : null}
                      {facility.type === "hospital" ||
                      facility.type === "pharmacy" ||
                      facility.type === "factory"
                        ? allModule.account.type.map((item) =>
                            isChecked("Accounts") ? (
                              <CheckBoxItem
                                className="font-weight-bold"
                                label={item}
                                name={item}
                                handleCheck={handleFunctionalities}
                                value={functionalities}
                              />
                            ) : null
                          )
                        : null}
                    </div> */}
                    <div className="col-md-3 ">
                      {facility.type === 'hospital' ||
                      facility.type === 'pharmacy' ||
                      facility.type === 'factory' ? (
                        <CheckBoxNormal
                          className="font-weight-bold"
                          label="Stock Management"
                          name="Stock Management"
                          handleCheck={handleCheckboxChange}
                          value={accessTo}
                        />
                      ) : null}
                      {facility.type === 'hospital' ||
                      facility.type === 'pharmacy' ||
                      facility.type === 'factory'
                        ? allModule.inventory.type.map((item) =>
                            isChecked('Stock Management') ? (
                              <CheckBoxItem
                                className="font-weight-bold"
                                label={item}
                                name={item}
                                handleCheck={handleFunctionalities}
                                value={functionalities}
                              />
                            ) : null,
                          )
                        : null}
                    </div>

                    <div className="col-md-3 ">
                      {facility.type === 'factory' ? (
                        <CheckBoxNormal
                          className="font-weight-bold"
                          label="Administration"
                          name="Administration"
                          handleCheck={handleCheckboxChange}
                          value={accessTo}
                        />
                      ) : null}
                      {facility.type === 'hospital' ||
                      facility.type === 'pharmacy' ||
                      facility.type === 'factory'
                        ? allModule.administration.type.map((item) =>
                            isChecked('Administration') ? (
                              <CheckBoxItem
                                className="font-weight-bold"
                                label={item}
                                name={item}
                                handleCheck={handleFunctionalities}
                                value={functionalities}
                              />
                            ) : null,
                          )
                        : null}
                    </div>
                    {/* <div className="col-md-3 ">
                      {facility.type === "factory" ? (
                        <CheckBoxNormal
                          className="font-weight-bold"
                          label="Engineering"
                          name="Engineering"
                          handleCheck={handleCheckboxChange}
                          value={accessTo}
                        />
                      ) : null}
                      {facility.type === "hospital" ||
                      facility.type === "pharmacy" ||
                      facility.type === "factory"
                        ? allModule.engineering.type.map((item) =>
                            isChecked("Engineering") ? (
                              <CheckBoxItem
                                className="font-weight-bold"
                                label={item}
                                name={item}
                                handleCheck={handleFunctionalities}
                                value={functionalities}
                              />
                            ) : null
                          )
                        : null}
                    </div> */}

                    <div className="col-md-3">
                      {facility.type === 'factory' ? (
                        <CheckBoxNormal
                          className="font-weight-bold"
                          label="Executive"
                          name="Executive"
                          handleCheck={handleCheckboxChange}
                          value={accessTo}
                        />
                      ) : null}
                      {facility.type === 'hospital' ||
                      facility.type === 'pharmacy' ||
                      facility.type === 'factory'
                        ? allModule.management.type.map((item) =>
                            isChecked('Executive') ? (
                              <CheckBoxItem
                                className="font-weight-bold"
                                label={item}
                                name={item}
                                handleCheck={handleFunctionalities}
                                value={functionalities}
                              />
                            ) : null,
                          )
                        : null}
                    </div>

                    <div className="col-md-3 ">
                      <CheckBoxNormal
                        className="font-weight-bold"
                        label="Content Management"
                        name="Content Management"
                        handleCheck={handleCheckboxChange}
                        value={accessTo}
                      />
                      {allModule.inventory.type.map((item) =>
                        isChecked('Stock Management') ? (
                          <CheckBoxItem
                            className="font-weight-bold"
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={functionalities}
                          />
                        ) : null,
                      )}
                    </div>
                    {/* <div className="col-md-3">
                      {facility.type === "factory" ? (
                        <CheckBoxNormal
                          className="font-weight-bold"
                          label="Reviewer"
                          name="Reviewer"
                          handleCheck={handleCheckboxChange}
                          value={accessTo}
                        />
                      ) : null}
                      {facility.type === "hospital" ||
                      facility.type === "pharmacy" ||
                      facility.type === "factory"
                        ? allModule.reviewer.type.map((item) =>
                            isChecked("Reviewer") ? (
                              <CheckBoxItem
                                className="font-weight-bold"
                                label={item}
                                name={item}
                                handleCheck={handleFunctionalities}
                                value={functionalities}
                              />
                            ) : null
                          )
                        : null}
                    </div> */}
                    {/* <div className="col-md-3 ">
                      {facility.type === "factory" ? (
                        <CheckBoxNormal
                          className="font-weight-bold"
                          label="Management"
                          name="Management"
                          handleCheck={handleCheckboxChange}
                          value={accessTo}
                        />
                      ) : null}
                      {facility.type === "hospital" ||
                      facility.type === "pharmacy" ||
                      facility.type === "factory"
                        ? allModule.management.type.map((item) =>
                            isChecked("Management") ? (
                              <CheckBoxItem
                                className="font-weight-bold"
                                label={item}
                                name={item}
                                handleCheck={handleFunctionalities}
                                value={functionalities}
                              />
                            ) : null
                          )
                        : null}
                    </div> */}

                    <div className="col-md-3 ">
                      {facility.type === 'factory' ? (
                        <CheckBoxNormal
                          className="font-weight-bold"
                          label="Sales"
                          name="Customer"
                          handleCheck={handleCheckboxChange}
                          value={accessTo}
                        />
                      ) : null}
                      {facility.type === 'hospital' ||
                      facility.type === 'pharmacy' ||
                      facility.type === 'factory'
                        ? allModule.customer.type.map((item) =>
                            isChecked('Sales') ? (
                              <CheckBoxItem
                                className="font-weight-bold"
                                label={item}
                                name={item}
                                handleCheck={handleFunctionalities}
                                value={functionalities}
                              />
                            ) : null,
                          )
                        : null}
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Form>
            {error !== '' ? (
              <center>
                <p style={{ color: 'red' }}>{error}</p>
              </center>
            ) : null}
          </CardBody>

          <CardFooter>
            {id ? (
              <Button
                color="primary"
                outline
                className="offset-md-5 col-md-2 offset-lg-5 col-lg-2"
              >
                {creatingUser ? <LoadingSM /> : 'Update User'}
              </Button>
            ) : (
              <Button
                color="primary"
                outline
                className="offset-md-5 col-md-2 offset-lg-5 col-lg-2"
              >
                {creatingUser ? <LoadingSM /> : 'Create User'}
              </Button>
            )}
          </CardFooter>
        </Card>
      </Form>
    )
  }
}

export function ModuleComponent({
  facility = {},
  functionalities,
  accessTo = [],
  handleCheckboxChange,
  handleFunctionalities,
}) {
  const isChecked = (name) => accessTo.includes(name)
  return (
    <FormGroup row>
      <div className="col-md-12 col-lg-12">
        <h6>Access (User's Privilege)</h6>
        <div className="row">
          <div className={isChecked('Laboratory') ? 'col-md-6' : 'col-md-3'}>
            <div className="row">
              {facility.type === 'hospital' ||
              facility.type === 'laboratory' ? (
                <div className="col-md-12">
                  <CheckBoxNormal
                    label="Laboratory"
                    name="Laboratory"
                    handleCheck={handleCheckboxChange}
                    value={accessTo}
                    className="font-weight-bold"
                  />
                </div>
              ) : null}

              {
                // JSON.stringify(allModule.lab.type)
                facility.type === 'hospital' || facility.type === 'laboratory'
                  ? allModule.laboratory.type.map((item) => (
                      <div className="col-md-6">
                        {isChecked('Laboratory') ? (
                          <CheckBoxItem
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={functionalities}
                          />
                        ) : null}
                      </div>
                    ))
                  : null
              }
            </div>
          </div>
          <div className="col-md-3">
            <CheckBoxNormal
              label="Reports"
              name="Reports"
              handleCheck={handleCheckboxChange}
              value={accessTo}
              className="font-weight-bold"
            />
            {allModule.reports.type.map((item) =>
              isChecked('Reports') ? (
                <CheckBoxItem
                  label={item}
                  name={item}
                  handleCheck={handleFunctionalities}
                  value={functionalities}
                />
              ) : null,
            )}
          </div>
          <div className="col-md-3">
            {facility.type === 'hospital' ||
            facility.type === 'pharmacy' ||
            facility.type === 'laboratory' ? (
              <CheckBoxNormal
                label="Admin"
                name="Admin"
                handleCheck={handleCheckboxChange}
                value={accessTo}
                className="font-weight-bold ml-4 m-0 pl-3"
              />
            ) : null}
            {facility.type === 'hospital' ||
            facility.type === 'pharmacy' ||
            facility.type === 'laboratory'
              ? allModule.admin.type.map((item) =>
                  isChecked('Admin') ? (
                    <CheckBoxItem
                      label={item}
                      name={item}
                      handleCheck={handleFunctionalities}
                      value={functionalities}
                    />
                  ) : null,
                )
              : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-lg-3">
            {facility.type === 'hospital' && (
              <CheckBoxNormal
                className="font-weight-bold"
                label="Maintenance"
                name="Maintenance"
                handleCheck={handleCheckboxChange}
                value={accessTo}
              />
            )}
            {facility.type === 'hospital' ? (
              <CheckBoxNormal
                className="font-weight-bold"
                label="Records"
                name="Records"
                handleCheck={handleCheckboxChange}
                value={accessTo}
              />
            ) : null}
            {facility.type === 'hospital'
              ? allModule.records.type.map((item) =>
                  isChecked('Records') ? (
                    <CheckBoxItem
                      className="font-weight-bold"
                      label={item}
                      name={item}
                      handleCheck={handleFunctionalities}
                      value={functionalities}
                    />
                  ) : null,
                )
              : null}

            {facility.type === 'hospital' || facility.type === 'pharmacy' ? (
              <CheckBoxNormal
                className="font-weight-bold"
                label="Accounts"
                name="Accounts"
                handleCheck={handleCheckboxChange}
                value={accessTo}
              />
            ) : null}
            {facility.type === 'hospital' || facility.type === 'pharmacy'
              ? allModule.account.type.map((item) =>
                  isChecked('Accounts') ? (
                    <CheckBoxItem
                      className="font-weight-bold"
                      label={item}
                      name={item}
                      handleCheck={handleFunctionalities}
                      value={functionalities}
                    />
                  ) : null,
                )
              : null}
          </div>

          <div className="col-md-3 col-lg-3">
            {facility.type === 'hospital' && (
              <CheckBoxNormal
                className="font-weight-bold"
                label="Theater"
                name="Theater"
                handleCheck={handleCheckboxChange}
                value={accessTo}
              />
            )}
            {facility.type === 'hospital' &&
              allModule.theater.type.map((item) =>
                isChecked('Theater') ? (
                  <CheckBoxItem
                    className="font-weight-bold"
                    label={item}
                    name={item}
                    handleCheck={handleFunctionalities}
                    value={functionalities}
                  />
                ) : null,
              )}
            {facility.type === 'hospital' && (
              <CheckBoxNormal
                className="font-weight-bold"
                label="Doctors"
                name="Doctors"
                handleCheck={handleCheckboxChange}
                value={accessTo}
              />
            )}
            {facility.type === 'hospital' &&
              allModule.doctor.type.map((item) =>
                isChecked('Doctors') ? (
                  <CheckBoxItem
                    className="font-weight-bold"
                    label={item}
                    name={item}
                    handleCheck={handleFunctionalities}
                    value={functionalities}
                  />
                ) : null,
              )}
          </div>
          <div className="col-md-3 ">
            {facility.type === 'hospital' || facility.type === 'pharmacy' ? (
              <CheckBoxNormal
                className="font-weight-bold"
                label="Pharmacy"
                name="Pharmacy"
                handleCheck={handleCheckboxChange}
                value={accessTo}
              />
            ) : null}
            {facility.type === 'hospital' || facility.type === 'pharmacy'
              ? allModule.pharmacy.type.map((item) =>
                  isChecked('Pharmacy') ? (
                    <CheckBoxItem
                      className="font-weight-bold"
                      label={item}
                      name={item}
                      handleCheck={handleFunctionalities}
                      value={functionalities}
                    />
                  ) : null,
                )
              : null}
          </div>
        </div>
      </div>
    </FormGroup>
  )
}

function mapStateToProps({ auth, facility }) {
  return {
    user: auth.user,
    creatingUser: auth.creatingUser,
    roles: auth.roles,
    facility: facility.info,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createUser: (data, success, err) =>
      dispatch(createUser(data, success, err)),
    getRoles: () => dispatch(getRoles()),
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NewUser)

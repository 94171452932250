import React, { useState, useEffect, useCallback } from 'react'
import { Table, Card, CardBody, CardHeader } from 'reactstrap'
import { _fetchApiGeneral } from '../../redux/actions/api'
import { apiURL } from '../../redux/actions'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { formatNumber } from '../utils/helpers'
import DaterangeSelector from '../comp/components/DaterangeSelector'
import Loading from '../comp/components/Loading'

function PaymentReport() {
  const today = moment().format('YYYY-MM-DD')
  const [range, setRange] = useState({
    from: today,
    to: today,
  })
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([{}])

  const facilityId = useSelector((state) => state.auth.user.facilityId)

  const getList = useCallback(() => {
    setLoading(true)
    _fetchApiGeneral(
      `${apiURL()}/account/get/payment/${facilityId}?query_type=select&from=${
        range.from
      }&to=${range.to}`,
      (data) => {
        setLoading(false)
        if (data && data.results) {
          setList(data.results)
        }
        // console.log(data);
      },
      () => {
        setLoading(false)
      },
    )
  }, [range.from, range.to])

  useEffect(() => {
    getList()
  }, [getList])

  return (
    <div>
      <Card>
        {/* {JSON.stringify(result)} */}
        <CardHeader tag="h5">Payment Report</CardHeader>
        <CardBody>
          <DaterangeSelector
            from={range.from}
            to={range.to}
            handleChange={({ target: { name, value } }) =>
              setRange((p) => ({ ...p, [name]: value }))
            }
          />
          {loading && <Loading />}
          <Table bordered striped size="md">
            <thead>
              <tr>
                <th className="text-center">Date</th>
                <th className="text-center">Customer Name</th>
                <th className="text-center">Amount</th>
                <th className="text-center">Reference</th>
                <th className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item) => (
                <tr>
                  <td>{moment(item.create_at).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{item.customer_name}</td>
                  <td className="text-right">{formatNumber(item.amount)}</td>
                  <td>{item.tx_ref}</td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

export default PaymentReport

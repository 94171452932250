import {
  GET_IDS,
  GET_UNASSIGNED_PATIENTS,
  GET_PATIENT_LIST,
  SET_PATIENTS,
  SET_CURRENT_PATIENT,
} from '../actions/actionTypes';

const initialState = {
  ids: [],
  unassignedPatients: [],
  patientlist: [],
  patients: [],
  names: [],
  accountNos: [],
  currentPatient: {},
};

const recordsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IDS:
      return {
        ...state,
        ids: action.payload,
      };

    case GET_UNASSIGNED_PATIENTS:
      return {
        ...state,
        unassignedPatients: action.payload,
      };

    case GET_PATIENT_LIST:
      return {
        ...state,
        patientlist: action.payload,
      };

    case SET_PATIENTS: {
      const { patients, names, accountNos } = action.payload;
      return {
        ...state,
        patients,
        names,
        accountNos,
      };
    }
    case SET_CURRENT_PATIENT:
      return {
        ...state,
        currentPatient: action.payload,
      };

    default:
      return state;
  }
};

export default recordsReducer;

import React from 'react';

function VerticalMenu(props) {
  return (
    <div className="list-group">
      {props.title ? (
        <span
          className="list-group-item list-group-item-action text-center font-weight-bold"
          style={{ backgroundColor: '#ccc' }}
        >
          {props.title}
        </span>
      ) : null}
      {props.children}
    </div>
  );
}

export default VerticalMenu;

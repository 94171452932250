import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { apiURL } from "../../redux/actions";
import { _postApi } from "../../redux/actions/api";
import CustomButton from "../comp/components/Button";
import SearchItem from "../pharmacy/components/SearchItem";
import { _warningNotify } from "../utils/helpers";
import useQuery from "../utils/UseQuery";

export default function Promo() {
  const _data = {
    promo_name: "",
    promo_amt: "",
    items: "",
    item_amt: "",
    amt_saved: "",
    promo_start: "",
    promo_end: "",
    item_name: "",
  };
  const [form, setForm] = useState(_data);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const query = useQuery();
  const item_name = query.get("item_name");
  const facilityId = useSelector((state) => state.facility.info.facility_id);
  const dispensaryDrugCodeRef1 = useRef();
  const {
    promo_name,
    promo_amt,
    qty,
    item_amt,
    amt_saved,
    promo_start,
    promo_end,
  } = form;
  const handleAdd = () => {
    setData((p) => [
      ...p,
      { items: form.items, qty: form.qty, item_amt: form.item_amt },
    ]);
    setForm((p) => ({
      ...p,
      qty: "",
      item_amt: "",
    }));
    dispensaryDrugCodeRef1.current.clear();
  };
  const handleDrugCodeChange = (value, drugInfo) => {
    console.log(drugInfo, value);
    // this.setState({ item_code: value });
    if (drugInfo) {
      setForm((prev) => ({
        ...prev,
        items: drugInfo.item_name,
        quantity_avail: drugInfo.balance,
        item_amt: drugInfo.unit_price,
        expiryDate: drugInfo.expiring_date,
        store_type: drugInfo.store_type,
        po_no: drugInfo.po_no,
        grm_no: drugInfo.grm_no,
        item_code: drugInfo.item_code,
        // qtyReq: qtyReq,
        item_category: drugInfo.item_category,
      }));
      //  dispatchUnitCost(parseFloat(drugInfo.unit_price))
    }
    // this.qttyRef.current && this.qttyRef.current.focus();
  };

  const _fetch = (item) => {
    fetch(`${apiURL()}/account/get/item_name/${facilityId}?item_name=${item}`)
      .then((resp) => resp.json())
      .then(({ itemInfo }) => {
        console.log(item, itemInfo, "JDJDJDJDJD");
        handleDrugCodeChange(item, itemInfo[0]);
      });
  };

  useEffect(() => {
    _fetch(item_name);
  }, [item_name]);
  const total =
    data && data.reduce((item, id) => item + parseFloat(id.item_amt), 0);
  const items = data.map((i) => `${i.items}, Quantity:${i.qty}`);

  const handleSubmit = () => {
    setLoading(true);
    const callBack = (res) => {
      alert("Submit Successfullly");
      console.log(res);
      setLoading(false);
    };
    const error = (err) => {
      _warningNotify("Error Occur");
      console.log(err);
      setLoading(false);
    };
    let obj = {
      ...items,
      promo_name: form.promo_name,
      promo_amt: form.promo_amt,
      item_amt: form.item_amt,
      amt_saved: form.amt_saved,
      promo_start: form.promo_start,
      promo_end: form.promo_end,
    };
    _postApi(
      `${apiURL()}/inventory/promo?query_type=insert`,
      obj,
      callBack,
      error
    );
  };

  return (
    <div>
      <Card>
        {/* {JSON.stringify(itemQty)} */}
        <CardHeader tag="h4" className="text-center">
          Promo Creation
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Promo Name</Label>
                  <Input
                    type="text"
                    name="promo_name"
                    value={promo_name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Promo Amount</Label>
                  <Input
                    type="text"
                    name="promo_amt"
                    value={promo_amt}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>Amount Saved</Label>
                  <Input
                    type="text"
                    name="amt_saved"
                    value={amt_saved}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Promo Start</Label>
                  <Input
                    type="date"
                    name="promo_start"
                    value={promo_start}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Promo End</Label>
                  <Input
                    type="date"
                    name="promo_end"
                    value={promo_end}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <SearchItem
                label="Select Item Name"
                //   autoFocus={pharmHasStore}
                _ref={dispensaryDrugCodeRef1}
                handleResult={handleDrugCodeChange}
              />
              <Col md={4}>
                <FormGroup>
                  <Label>Quantity</Label>
                  <Input
                    type="text"
                    name="qty"
                    value={qty}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Item Amount</Label>
                  <Input
                    type="text"
                    name="item_amt"
                    value={item_amt}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <center>
              <Button size="sm" onClick={handleAdd}>
                Add item
              </Button>
            </center>
            <p className="text-right">Total Amount: {total}</p>
            <Table bordered striped size="sm">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Cost Price</th>
                </tr>
              </thead>
              <tbody>
                {data.map((it, id) => (
                  <tr>
                    <td>{it.items}</td>
                    <td>{it.qty}</td>
                    <td>{it.item_amt}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <center>
              <CustomButton
                className="px-5"
                loading={loading}
                onClick={handleSubmit}
              >
                Submit
              </CustomButton>
            </center>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import ExcelGenerator from "../account/ExcelGenerator";
import BranchName from "../utils/GlobalHelper";
import SearchBar from "../utils/SearchBar";
import { today } from "../utils/helpers";
import DaterangeSelector from "../comp/components/DaterangeSelector";
import { _fetchApi } from "../../redux/actions/api";
import { apiURL } from "../../redux/actions";
import { Alert } from "reactstrap/lib";

export default function RequisitionList() {
  const branch_name = useSelector((state) => state.auth.user.branch_name);

  const [data, setData] = useState([]);
  const [form, setForm] = useState({
    from: today,
    to: today,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [branch, setBranch] = useState(
    branch_name === "Head Office" ? "Head Office" : branch_name
  );
  const handleDateChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const { from, to } = form;
  const getAllItemsByBranch = useCallback(() => {
    _fetchApi(
      `${apiURL()}/account/get-branch-requisition/${from}/${to}/${branch}`,
      (data) => {
        // if (data.success) {
        setData(data.results);
        // }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [from, to, branch]);
  useEffect(() => {
    getAllItemsByBranch();
  }, [getAllItemsByBranch]);

  let rows = [];
  data &&
    data.forEach((item, index) => {
      if (
        item.item_name &&
        item.item_name.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1 &&
        item.req_status.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1
      )
        return;
      rows.push(
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.item_name}</td>
          <td>{item.item_category}</td>
          <td>{item.qty}</td>
          <td>{item.approved_qty}</td>
          <td>{item.request_at}</td>
          <td>{item.branch_name}</td>
          <td
            className={
              item.req_status === "Rejected"
                ? "bg-danger text-white text-center"
                : item.req_status === "Approve"
                ? "bg-success text-white text-center"
                : ""
            }
          >
            {item.req_status}
          </td>
        </tr>
      );
    });
  return (
    <>
      <Card>
        {/* {JSON.stringify(data)} */}
        <CardHeader tag="h5" className="text-center">
          Your Requisition List
        </CardHeader>
        <CardBody>
          <Form>
            <DaterangeSelector
              from={from}
              to={to}
              handleChange={handleDateChange}
            />
            <Row>
              <Col md={8}>
                <Label>Search here</Label>
                <FormGroup>
                  <SearchBar
                    filterText={searchTerm}
                    onFilterTextChange={(searchTerm) =>
                      setSearchTerm(searchTerm)
                    }
                    placeholder="Search by item Name or status"
                  />
                </FormGroup>
              </Col>
              {branch_name === "Head Office" ? (
                <BranchName
                  value={branch}
                  handleChange={({ target: { value } }) => setBranch(value)}
                  name="branch"
                />
              ) : null}

              <Col md={branch_name !== "Head Office" ? 4 : 12}>
                <ExcelGenerator data={data} className="float-right mb-2" />
              </Col>

              <Col md={12}>
                <Scrollbars style={{ height: 400 }}>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Item Name</th>
                        <th>Item Category</th>
                        <th>Request Qty</th>
                        <th>Approved Qty</th>
                        <th>Date</th>
                        <th>Branch</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>{rows} </tbody>
                  </Table>
                  {!data.length && (
                    <Alert className="text-center">No requisition today</Alert>
                  )}
                </Scrollbars>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

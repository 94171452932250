import React from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { MdArrowBack } from "react-icons/md";
import { withRouter } from "react-router";

function BackButton({ text = "Click to go back", history, type = "button" }) {
  if (type === "link") {
    return (
      <a
        href="#no-link"
        onClick={(e) => {
          e.preventDefault();
          history.goBack();
        }}
      >
        <span className="d-flex flex-direction-row align-items-center">
          <MdArrowBack className="mr-1" size={20} />
          {text}
        </span>
      </a>
    );
  } else {
    return (
      <button className="btn btn-dark mb-2" onClick={() => history.goBack()}>
        <span className="d-flex flex-direction-row align-items-center text-white">
          <AiOutlineLeft className="mr-1" size={20} />
          {text}
        </span>
      </button>
    );
  }
}

export default withRouter(BackButton);

import React, { useEffect, useState } from 'react'
import { Row, Col, Badge } from 'reactstrap'
import { Route, Switch, Redirect } from 'react-router'
import VerticalMenu from '../comp/components/vertical-menu/VerticalMenu'
import ListMenuItem from '../comp/components/vertical-menu/ListMenuItem'
import { AiFillQqSquare, AiOutlineCluster } from 'react-icons/ai'
import Process from './Process'
import PendingExpense from './PendingExpense'
import AccountEntriesDailySummary from '../account/account-entries/AccountEntriesDailySummary'
import AccountEntriesDetails from '../account/account-entries/AccountEntriesDetails'
// import PurchaseOrder from '../pharmacy/PurchaseOrder';
import PendingPurchaseOrder from './PendingPurchaseOrder'
// import ViewExpenses from "../Administration/ViewExpense";
import { useSelector } from 'react-redux'
import { canUseThis } from '../auth'
import { useHistory } from 'react-router'
import allModule from '../admin/moduleData'
import { getReqNum } from '../utils/helpers'
import PendingRequest from './PendingRequest'
import OnlineRequest from './OnlineRequest'
import { MdWeb } from 'react-icons/md'

let currentPath = '/me/auditor'

function Tab() {
  const user = useSelector((state) => state.auth.user)
  const history = useHistory()
  const [, setstate] = useState('')
  const navigateUser = (user) => {
    let functionality = user.functionality

    const access = allModule.management.type

    let active = null
    access.forEach((i) => {
      if (functionality && functionality.findIndex((it) => it === i) !== -1)
        active = i
      return
    })

    switch (active) {
      // case "Reviewer DashBoard":
      //   return history.push(`${currentPath}/dashboad`);
      case 'Pending Request':
        return history.push(`${currentPath}/pending-request`)
      // case 'Pending Purchase Order':
      //   return history.push(`${currentPath}/purchase`)
      default:
        return history.push(`${currentPath}/pending-request`)
    }
  }

  useEffect(() => {
    let arr = '"Pending Purchase Order", "Pending Request","BackToAuditor"'
    if (user) {
      navigateUser(user)
      getReqNum(setstate, arr)
    }
  }, [user])
  // const user = useSelector((state) => state.auth.user);
  return (
    <>
      <VerticalMenu>
        {/* {user.accessTo
          ? canUseThis(user, ["Reviewer DashBoard"]) && (
              <ListMenuItem route={`${currentPath}/dashboad`}>
                <AiOutlineCluster size={26} style={{ marginRight: 5 }} />
                Reviewer DashBoard
              </ListMenuItem>
            )
          : null}
        {user.accessTo
          ? canUseThis(user, ["Pending Expenses Requisition"]) && (
              <ListMenuItem route={`${currentPath}/expenses`}>
                <AiOutlineCluster size={26} style={{ marginRight: 5 }} />
                Pending Expenses Requisition
              </ListMenuItem>
            )
          : null} */}
        {/* {user.accessTo
          ? canUseThis(user, ['Pending Purchase Order']) && (
              <ListMenuItem route={`${currentPath}/purchase`}>
                <AiFillQqSquare size={26} style={{ marginRight: 5 }} />
                Pending Purchase Order
              </ListMenuItem>
            )
          : null} */}
        {user.accessTo
          ? canUseThis(user, ['Pending Request']) && (
              <>
                <ListMenuItem route={`${currentPath}/pending-request`}>
                  <AiOutlineCluster size={26} style={{ marginRight: 5 }} />
                  Kirana requests
                </ListMenuItem>
                <ListMenuItem route={`${currentPath}/online-request`}>
                  <MdWeb size={26} style={{ marginRight: 5 }} />
                  Online requests
                </ListMenuItem>
              </>
            )
          : null}
      </VerticalMenu>
    </>
  )
}

function Auditor() {
  return (
    <>
      <Row className="m-0">
        <Col md={3}>
          <Tab />
        </Col>
        <Col md={9}>
          <Switch>
            {/* <Redirect
              exact
              from={`${currentPath}`}
              to={`${currentPath}/expenses`}
            /> */}
            <Route
              path={`${currentPath}/pending-request`}
              component={PendingRequest}
            />
            <Route
              path={`${currentPath}/online-request`}
              component={OnlineRequest}
            />
            {/* <Route
          path={`${currentPath}/account-entries-summary`}
          component={AccountEntriesDailySummary}
          // exact
        />
        <Route
          path={`${currentPath}/account-entries-details`}
          component={AccountEntriesDetails}
          // exact
        /> */}

            <Route
              path={`${currentPath}/purchase/:id`}
              component={PendingPurchaseOrder}
            />
            <Redirect
              exact
              from={`${currentPath}/purchase`}
              to={`${currentPath}/purchase/table`}
            />
            {/* <Route
              exact
              path={`${currentPath}/expenses/view/:requestNo`}
              component={ViewExpenses}
            />
            <Route
              exact
              path={`${currentPath}/expenses`}
              component={PendingExpense}
            />
            <Route
              exact
              path={`${currentPath}/expenses/view/:requestNo`}
              component={ViewExpenses}
            /> */}
          </Switch>
        </Col>
      </Row>
    </>
  )
}

export default Auditor

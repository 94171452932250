import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Input, Table } from 'reactstrap'
import { _fetchApiGeneral, _postApi } from '../../../redux/actions/api'
import { apiURL } from '../../../redux/actions'
import Loading from '../../loading'
import useQuery from '../../utils/UseQuery'
import { formatNumber, _customNotify } from '../../utils/helpers'
import CustomerCreditDetails from '../../pharmacy/pending-requests/CustomerCreditDetails'
import BackButton from '../../comp/components/BackButton'
import moment from 'moment'
import DaterangeSelector from '../../comp/components/DaterangeSelector'
import PrintWrapper from '../../comp/components/PrintWrapper'
import { FaEdit, FaSave } from 'react-icons/fa'
import { AiFillDelete } from 'react-icons/ai'
import ExcelGenerator from '../ExcelGenerator'
import { useHistory } from 'react-router'

export function adjustItem(obj, query_type, i_status, callBack, error) {
  _postApi(
    `${apiURL()}/inventory/adjust?query_type=${query_type}&i_status=${i_status}`,
    obj,
    callBack,
    error,
  )
}

export default function AccountEntriesDetails() {
  const query = useQuery()
  const client_id = query.get('client_id')
  // const date = query.get("date");
  const branch_name = query.get('branch_name')
  const user = useSelector((state) => state.auth.user)
  const today = moment().format('YYYY-MM-DD')
  const yearStart = moment().startOf('year').format('YYYY-MM-DD')
  const [range, setRange] = useState({
    from: yearStart,
    to: today,
  })
  const history = useHistory()
  const [loading, setLoading] = useState('')
  const [list, setList] = useState([])

  const getStatement = useCallback(() => {
    setLoading(true)

    // let roles = user;
    // let newArr = [];
    _fetchApiGeneral(
      `${apiURL()}/api/account/get-client-statement?clientId=${client_id}&facilityId=${
        user.facilityId
      }&query_type=daily_summary&from=${range.from}&to=${range.to}`,
      (data) => {
        setLoading(false)
        if (data && data.results) {
          // let final = {}
          // data.results.forEach((item) => {
          //   if (Object.keys(final).includes(item.createdAt)) {
          //     final[item.createdAt] = [...final[item.createdAt], item]
          //   } else {
          //     final[item.createdAt] = [item]
          //   }
          // })
          // setList(data.results)
          // setList(final)
          let newArr = []
          data.results.forEach((i) =>
            newArr.push({
              ...i,
              oldQty: i.quantity,
              oldDate: i.createdAt,
              edit: false,
            }),
          )
          setList(newArr)
        }
      },
      (err) => {
        setLoading(false)
        console.log(err)
      },
    )
  }, [client_id, user.facilityId, range.from, range.to])

  const handleRangeChange = ({ target: { name, value } }) => {
    setRange((p) => ({ ...p, [name]: value }))
  }

  useEffect(() => {
    getStatement()
  }, [getStatement])

  const totalDebit = list.reduce((a, b) => a + parseFloat(b.dr), 0)
  const totalCredit = list.reduce((a, b) => a + parseFloat(b.cr), 0)

  const balance = totalDebit - totalCredit

  const handleTable = (name, value, index) => {
    let newArr = []
    list.forEach((item, i) => {
      if (index === i) {
        newArr.push({ ...item, [name]: value })
      } else {
        newArr.push(item)
      }
    })
    setList(newArr)
  }
  const handleUpdate = () => {
    const callBack = () => {
      console.log('Successful')
    }
    const error = () => {
      console.log('Error Occured')
    }
    let newArr = []
    list.filter((i) => i.edit === true).forEach((i) => newArr.push({ ...i }))
    // alert(JSON.stringify(newArr));
    for (let index = 0; index < newArr.length; index++) {
      adjustItem(newArr[index], 'edit', 'pending', callBack, error)
    }
    _customNotify('Successfully Submitted, Wanting for admin approve')
    history.push('/me/business/customer-account-entries')
  }

  const handleDelete = (item) => {
    const callBack = () => {
      console.log('Successful')
    }
    const error = () => {
      console.log('Error Occured')
    }
    adjustItem(item, 'delete', 'pending', callBack, error)
    getStatement()
    _customNotify('Succefully Deleted')
  }

  const handleEdit = (i) => {
    let newArr = []
    list.forEach((it, idx) => {
      if (idx === i) {
        newArr.push({ ...it, edit: true })
      } else newArr.push(it)
    })

    setList(newArr)
  }

  const canEdit = user.accessTo.includes('canEditAccountStatement')
  const canDelete = user.accessTo.includes('canEditAccountStatement')

  return (
    <>
      <div className="d-flex flex-direction-row justify-content-between">
        <BackButton type="link" />
        <ExcelGenerator
          title="Export to Excel"
          data={list}
          filename={`Account Statement for ${branch_name} (${range.from} - ${range.to})`}
        />
      </div>

      <div className="my-2">
        <CustomerCreditDetails customer={branch_name} />
      </div>
      {loading ? <Loading /> : null}
      <DaterangeSelector
        from={range.from}
        to={range.to}
        handleChange={handleRangeChange}
      />
      {/* <SearchBar
            filterText={searchTerm}
            onFilterTextChange={(searchTerm) => setSearchTerm(searchTerm)}
            placeholder="Search by Shop name, status and request no."
          /> */}
      {/* {JSON.stringify(user.accessTo)} */}
      <div id="account-entries-details">
        <PrintWrapper
          title={`Account Statement for ${branch_name} (${range.from} - ${range.to})`}
        >
          <Table bordered striped size="sm">
            <thead>
              <tr>
                <th className="text-center">S/N</th>
                {/* <th className="text-center">Date</th> */}
                <th className="text-center">Date</th>
                <th className="text-center">Description</th>
                <th className="text-center">Quantity</th>
                <th className="text-center">Debit (₦)</th>
                <th className="text-center">Credit (₦)</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, i) => (
                <tr key={i}>
                  <td className="text-center">{i + 1}</td>
                  {/* <td className="text-center">{item.id}</td> */}
                  <td>
                    {!item.edit ? (
                      moment.utc(item.createdAt).format('DD-MM-YYYY')
                    ) : (
                      <Input
                        name="createdAt"
                        type="date"
                        value={moment(item.createdAt).format('YYYY-MM-DD')}
                        onChange={(e) =>
                          handleTable('createdAt', e.target.value, i)
                        }
                      />
                    )}
                  </td>
                  <td>
                    {!item.edit ? (
                      item.description
                    ) : (
                      <Input
                        name="description"
                        value={item.description}
                        onChange={(e) =>
                          handleTable('description', e.target.value, i)
                        }
                      />
                    )}
                  </td>
                  <td className="text-right">
                    {item.edit ? (
                      <Input
                        name="quantity"
                        value={item.quantity}
                        onChange={(e) =>
                          handleTable('quantity', e.target.value, i)
                        }
                      />
                    ) : item.quantity === 0 || item.quantity === '0' ? (
                      '-'
                    ) : (
                      item.quantity
                    )}
                  </td>
                  <td className="text-right">
                    {!item.edit ? (
                      formatNumber(item.cr)
                    ) : (
                      <Input
                        name="cr"
                        value={item.cr}
                        onChange={(e) => handleTable('cr', e.target.value, i)}
                      />
                    )}
                  </td>
                  <td className="text-right">
                    {' '}
                    {!item.edit ? (
                      formatNumber(item.dr)
                    ) : (
                      <Input
                        name="dr"
                        value={item.dr}
                        onChange={(e) => handleTable('dr', e.target.value, i)}
                      />
                    )}
                  </td>
                  <td className="text-right">
                    {canEdit && (
                      <Button
                        outline
                        size="sm"
                        color="primary"
                        className="mr-1"
                        onClick={() =>
                          item.edit ? handleUpdate() : handleEdit(i)
                        }
                      >
                        {item.edit ? (
                          <>
                            {' '}
                            <FaSave />
                            <small>Update</small>{' '}
                          </>
                        ) : (
                          <FaEdit />
                        )}
                      </Button>
                    )}
                    {canDelete && (
                      <Button
                        outline
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(item)}
                      >
                        <AiFillDelete />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
              <tr>
                <th colSpan={4} className="text-right">
                  Total
                </th>
                <th className="text-right">{formatNumber(totalCredit)}</th>
                <th className="text-right">{formatNumber(totalDebit)}</th>
              </tr>
              <tr>
                <th colSpan={5} className="text-right">
                  Balance
                </th>
                <th className="text-right">{formatNumber(balance)}</th>
              </tr>
            </tbody>
          </Table>
        </PrintWrapper>
      </div>
    </>
  )
}

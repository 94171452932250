import { apiURL } from "../../redux/actions";
import { _fetchApiGeneral } from "../../redux/actions/api";

export const getRequisitionNextStatus = (
  curr_status,
  user_role,
  callback = (f) => f
) => {
  _fetchApiGeneral(
    `${apiURL()}/account/requisitions/next-status?curr_status=${curr_status}&user_role=${user_role}`,
    (data) => {
      if (data && data.results && data.results.length) {
        callback(data.results[0].status_to);
      }
    },
    (err) => {
      console.log(err);
    }
  );
};

import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Card, CardBody, CardHeader, Table } from 'reactstrap'
import { apiURL } from '../../../redux/actions'
import { _fetchApiGeneral } from '../../../redux/actions/api'
import { formatNumber } from '../../utils/helpers'

function CustomerOverview({ showFull }) {
  const history = useHistory()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const isRecovery = location.pathname.includes('recovery')

  let top5 = showFull ? list : list && list.slice(0, 5)
  const totalUnpaid = list.reduce((a, b) => a + parseFloat(b.balance), 0)

  const viewItemDetails = () => {}

  useEffect(() => {
    _fetchApiGeneral(`${apiURL()}/customers/get-customers-summary`, (data) => {
      if (data && data.results) {
        setList(data.results)
      }
    })
  }, [])

  const viewFullList = () => {
    history.push('/me/inventory/customer-overview-full-report')
  }

  return (
    <Card className="mt-2">
      <CardHeader
        tag="div"
        className="d-flex flex-row justify-content-between align-items-center"
        style={showFull ? {} : { backgroundColor: '#143d59', color: 'white' }}
      >
        <h6 className="font-outline-bold">
          {isRecovery ? 'Recovery List' : 'Customer Overview'}
        </h6>
        {!showFull && (
          <button className="btn btn-sm btn-warning" onClick={viewFullList}>
            View all
          </button>
        )}
      </CardHeader>
      <CardBody
        className="p-1 m-0"
        style={showFull ? {} : { backgroundColor: '#143d59', color: 'white' }}
      >
        {loading ? (
          <div className="d-flex align-items-center justify-content-center">
            <span className="text-center text-primary">Loading...</span>
          </div>
        ) : (
          <div>
            <div className='d-flex flex-direction-row justify-content-end'>
              <span className={`text-right font-weight-bold ${showFull ? '' : 'text-white'}`}>
                Total Unpaid: ₦{formatNumber(totalUnpaid)}
              </span>
            </div>
            <Table size="sm" bordered>
              <thead>
                <tr>
                  <th className={`text-center ${showFull ? '' : 'text-white'}`}>
                    Account
                  </th>
                  <th className={`text-center ${showFull ? '' : 'text-white'}`}>
                    Total Collected (₦)
                  </th>
                  <th className={`text-center ${showFull ? '' : 'text-white'}`}>
                    Total Paid (₦)
                  </th>
                  <th className={`text-center ${showFull ? '' : 'text-white'}`}>
                    Balance (₦)
                  </th>
                </tr>
              </thead>
              <tbody>
                {top5 &&
                  top5.map((item, index) => (
                    <tr key={index}>
                      <td className=" text-white">
                        <a
                          href="#view-item"
                          onClick={(e) => {
                            e.preventDefault()
                            viewItemDetails(item)
                          }}
                        >
                          {item.accName}
                        </a>
                      </td>
                      <td
                        className={`text-right ${showFull ? '' : 'text-white'}`}
                      >
                        {formatNumber(item.collected)}
                      </td>
                      <td
                        className={`text-right ${showFull ? '' : 'text-white'}`}
                      >
                        {formatNumber(item.paid)}
                      </td>
                      <td
                        className={`text-right ${showFull ? '' : 'text-white'}`}
                      >
                        {formatNumber(item.balance)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default CustomerOverview

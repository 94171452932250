import React, { useEffect } from "react";
import { FaUserPlus, FaUsers, FaRegHospital } from "react-icons/fa";
import VerticalMenu from "../comp/components/vertical-menu/VerticalMenu";
import ListMenuItem from "../comp/components/vertical-menu/ListMenuItem";
import { Switch, Route, Redirect } from "react-router-dom";
import NewUser from "./NewUser";
import AddHospital from "./AddHospital";
import Users from "./Users";
import { MdSettings } from "react-icons/md";
import Settings from "./Settings";
import StaffReview from "./StaffReview";
import ApproveAdmin from "./ApproveAdmin";
import { useSelector } from "react-redux";
import { canUseThis } from "../auth";
import { useHistory } from "react-router";
import allModule from "./moduleData";
const Tabs = () => {
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();

  const navigateUser = (user) => {
    let functionality = user.functionality;

    const access = allModule.admin.type;

    let active = null;
    access.forEach((i) => {
      if (functionality && functionality.findIndex((it) => it === i) !== -1)
        active = i;
      return;
    });

    switch (active) {
      case "Create User":
        return history.push(`/me/admin/create-new-user`);
      case "Manage Users":
        return history.push(`/me/admin/manage-users`);
      case "Settings":
        return history.push(`/me/admin/settings`);
      default:
        return history.push(`/me/admin/create-new-user`);
    }
  };

  useEffect(() => {
    if (user) {
      navigateUser(user);
    }
  }, [user]);
  return (
    <div>
      <VerticalMenu title="What would you like to do?">
        {user.accessTo
          ? canUseThis(user, ["Create User"]) && (
              <ListMenuItem route="/me/admin/create-new-user">
                <FaUserPlus
                  size={26}
                  fontWeight="bold"
                  style={{ marginRight: 10 }}
                />
                Create User
              </ListMenuItem>
            )
          : null}
        {user.accessTo
          ? canUseThis(user, ["Manage Users"]) && (
              <ListMenuItem route="/me/admin/manage-users">
                <FaUsers
                  size={26}
                  fontWeight="bold"
                  style={{ marginRight: 10 }}
                />
                Manage Users
              </ListMenuItem>
            )
          : null}
        {user.accessTo
          ? canUseThis(user, ["Settings"]) && (
              <ListMenuItem route="/me/admin/settings">
                <MdSettings
                  size={26}
                  fontWeight="bold"
                  style={{ marginRight: 10 }}
                />
                Settings
              </ListMenuItem>
            )
          : null}
        {user.accessTo
          ? canUseThis(user, ["Create New Facility"]) &&
            user.username === "emaitee" && (
              <ListMenuItem route="/me/admin/create-new-facility">
                <FaRegHospital
                  size={26}
                  fontWeight="bold"
                  style={{ marginRight: 10 }}
                />
                Create New Facility
              </ListMenuItem>
            )
          : null}
        {user.accessTo
          ? canUseThis(user, ["Manage Admin"]) &&
            user.username === "emaitee" && (
              <ListMenuItem route="/me/admin/manage-admin">
                <MdSettings
                  size={26}
                  fontWeight="bold"
                  style={{ marginRight: 10 }}
                />
                Manage Admin
              </ListMenuItem>
            )
          : null}
      </VerticalMenu>
    </div>
  );
};

function AdminDashboard() {
  // const [showPatientDeposit, setShowPatientDeposit] = useState(true)
  // const [component, setComponet] = useState('')
  // const [user, setUser] = useState('')
  return (
    <div>
      <Switch>
        <Redirect exact from="/me/admin" to="/me/admin/create-new-user" />
        <Route path="/me/admin/create-new-user" component={NewUser} />
        <Route path="/me/admin/manage-users" component={Users} />
        <Route path="/me/admin/staffreview/:id" component={StaffReview} />
        <Route path="/me/admin/create-new-facility" component={AddHospital} />
        <Route path="/me/admin/settings" component={Settings} />
        <Route path="/me/admin/manage-admin" component={ApproveAdmin} />
      </Switch>
    </div>
  );
}

export default AdminDashboard;
export { Tabs };

import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand } from "reactstrap";
// import logo from './images/nol.png';
// import logo from '../../images/logo-p.jpg';
import { connect } from "react-redux";
// import styled from 'styled-components';
// import { hasAccess } from "../auth";
import { logout, checkAuthStatus } from "../../redux/actions/auth";
import { compose } from "redux";
import UserAvatar from "../user/Avatar";
// import Notifications from '../notifications/Notifications';
import NavModules from "./nav-modules";
// import { GoIssueOpened } from "react-icons/go";

class NavbarNow extends PureComponent {
  state = {
    isOpen: false,
    user: null,
    netStat: "offline",
    dropdownOpen: false,
    isProfileOpen: false,
    // isMobile: false,
  };

  componentDidMount() {
    document.title = this.props.facilityInfo.facility_name;
    this.getUser();

    if (this.props.history.action === "PUSH") {
      console.log("wait a bit before checking auth stat");
      setTimeout(
        checkAuthStatus(
          () => console.log("yeah"),
          () => this.gotoLogin()
        ),
        2000
      );
    } else {
      checkAuthStatus(
        () => console.log("logged in"),
        () => this.gotoLogin()
      );
    }
    if (navigator.onLine) {
      this.setState({ netStat: "online" });
    }
    window.addEventListener("online", this.onNetOn);
    window.addEventListener("offline", this.onNetOff);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.onNetOn);
    window.removeEventListener("offline", this.onNetOff);
  }

  onNetOn = () => this.setState({ netStat: "online" });

  onNetOff = () => this.setState({ netStat: "offline" });

  getUser() {
    let savedUser = localStorage.getItem("user") || "";
    this.setState({ user: savedUser });
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggleDropdown = () =>
    this.setState((prev) => ({
      dropdownOpen: !prev.dropdownOpen,
    }));

  gotoLogin = () => {
    this.props.history.push("/auth");
  };

  gotoHome = () => {
    this.props.history.push("/");
  };

  logout = () => {
    this.props.logout(() => this.gotoLogin());
  };

  render() {
    const {
      props: { user, isMobile },
    } = this;

    return (
      <Navbar
        dark
        expand="md"
        style={{
          minHeight: "50px",
          padding: 0,
          margin: 0,
          paddingRight: 5,
          backgroundColor: "#F37021",
        }}
      >
        <NavbarBrand onClick={this.gotoHome}>
          <img
            src={require("../../images/logo1.png")}
            alt="logo"
            height="40"
            // width="auto"
            style={{
              margin: "0 20px",
            }}
          />
          {/* <h4 className="logoText">HMS</h4> */}
        </NavbarBrand>

        <div style={{ display: "flex", flexDirection: "row" }}>
          {isMobile ? (
            <div
              style={{
                margin: "0 10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* <Notifications /> */}
              <UserAvatar logout={this.logout} />
            </div>
          ) : null}
          <NavbarToggler onClick={this.toggle} />
        </div>

        <Collapse
          isOpen={this.state.isOpen}
          navbar
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexGrow: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* {JSON.stringify(user)} */}
            {user.accessTo && user.accessTo.length > 1 ? (
              <NavModules user={user} toggle={this.toggle} />
            ) : null}

            {/* {isMobile ?  
             // <NavDoc />
              {/* : null} */}
          </div>

          {!isMobile ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* <button
                className="btn btn-danger mr-1"
                onClick={() => {
                  this.props.history.push("/me/report_issues");
                  this.toggle();
                }}
              >
                <GoIssueOpened size={20} className="mr-1" /> Report Issues
              </button> */}

              {/* <Notifications /> */}
              <UserAvatar logout={this.logout} />
            </div>
          ) : (
            ""
            // <button
            //   className="btn btn-danger"
            //   onClick={() => {
            //     this.props.history.push("/me/report_issues");
            //     this.toggle();
            //   }}
            // >
            //   <GoIssueOpened size={20} className="mr-1" /> Report Issues
            // </button>
          )}
        </Collapse>
      </Navbar>
    );
  }
}

function mapStateToProps({ auth, facility, app }) {
  return {
    user: auth.user,
    facilityInfo: facility.info,
    isMobile: app.isMobile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: (cb) => dispatch(logout(cb)),
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NavbarNow);

import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "reactstrap";
import { _fetchApiGeneral } from "../../../redux/actions/api";
import { getShopBalance } from "../../../redux/actions/pharmacy";
import { formatNumber } from "../../utils/helpers";

function CustomerCreditDetails({
  customer = "",
  showShopName = true,
  canClick = false,
  setCustomerDetails=f=>f
}) {
  const shopBal = useSelector((state) => state.pharmacy.shopBal);
  const dispatch = useDispatch();
  const history = useHistory();

  const _getDetails = useCallback(() => {
    dispatch(getShopBalance(customer, "", val => setCustomerDetails(val)));
  }, [dispatch, customer]);

  // useEffect(() => {
  //   _fetchApiGeneral(`/api/customer-details-erp`, d => {
  //     console.log(d)
  //   })
  // }, [])

  useEffect(() => {
    _getDetails();
  }, [_getDetails]);

  const gotoView = (e) => {
    e.preventDefault();

    history.push(
      `/me/auditor/pending-request/account-summary?branch_name=${customer}&client_id=${shopBal.account_no}`
    );
  };

  return (
    <Row>
      {showShopName && (
        <Col md={12}>
          <b>Shop Name:</b> {customer}
          {/* <Label>{JSON.stringify(this.branch_name)}</Label> */}
        </Col>
      )}
      {/* {JSON.stringify(shopBal)} */}
      <Col md={4}>
        <b className="mr-1">Credit Utilized:</b>
        {canClick ? (
          <a href="#no-link" onClick={gotoView}>
            ₦{formatNumber(Math.abs(shopBal.utilized_credit))}
          </a>
        ) : (
          `₦${formatNumber(Math.abs(shopBal.utilized_credit))}`
        )}
      </Col>
      <Col md={4}>
        <b className="mr-1">Credit Limit:</b>₦
        {formatNumber(shopBal.credit_limit)}
      </Col>
      <Col md={4}>
        <b className="mr-1">Credit Allowance:</b>₦
        {formatNumber(shopBal.credit_allowance)}
        {/* <Label>{JSON.stringify(this.branch_name)}</Label> */}
      </Col>
    </Row>
  );
}

export default CustomerCreditDetails;

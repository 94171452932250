import React, { Fragment } from 'react'
import { FaSearch } from 'react-icons/fa'
import { apiURL } from '../../../redux/actions'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'
import { Label } from 'reactstrap'
import store from '../../../redux/store'
import { _fetchApi, _fetchApiGeneral } from '../../../redux/actions/api'
import { useEffect } from 'react'

function SearchItem(props) {
  let [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const facilityId = useSelector((state) => state.auth.user.facilityId)

  const handleSearch = (query) => {
    setIsLoading(true)
    setSearchTerm(query)
    fetch(`${apiURL()}/account/get/item_name/${facilityId}?item_name=${query}`)
      .then((resp) => resp.json())
      .then(({ itemInfo }) => {
        if (itemInfo.length === 1) {
          props.handleResult(searchTerm, itemInfo[0])
          props._ref.current.setState({ text: itemInfo[0].item_name })
        } else {
          setOptions(itemInfo)
        }

        setIsLoading(false)
      })
  }

  const getItems = () => {
    setLoading(true)
    _fetchApi(
      `${apiURL()}/account/new/item_chart-dashboard`,
      (data) => {
        console.log(data, 'oooooooooooooooooooooooooooooooo')
        setOptions(data.results)
      },
      (e) => console.log(e),
    )
  }
  // {"name":[2397,"[CHE010] CHECKERS MILK 3 IN 1, 1.5KG *3"],"default_code":"CHE010","image":"False","sale_price":1,
  // "cost_price":0,"product_categ_id":[10,"FOOD / HOME FOOD"],"location_id":[35,"BIZOM"],"inventory_quantity":7,"available_quantity":7
  useEffect(() => {
    getItems()
  }, [])

  return (
    <div className="form-group has-search">
      {props.label && <Label>{props.label || ''}</Label>}
      <span className="form-control-feedback">
        <FaSearch />
      </span>
      {/* {JSON.stringify(options)} */}
      <Typeahead
        // <AsyncTypeahead
        ref={props._ref}
        id="search-item_name"
        isLoading={isLoading}
        labelKey={(i) => `${i.item_name}`}
        minLength={3}
        // onSearch={handleSearch}
        options={options}
        placeholder={props.placeholder || 'Search Item by Name or Barcode'}
        emptyLabel=""
        renderMenuItemChildren={(option, props) => (
          <Fragment>
            <div className="font-weight-bold mr-1">
              {option.item_name} (₦ {option.price})
            </div>
            <div>
              <span className="font-weight-bold mr-1">Quantity Available:</span>
              {option.balance}
            </div>
          </Fragment>
        )}
        onChange={(val) => {
          if (val.length) {
            props.handleResult(searchTerm, val[0])
          }
        }}
        {...props}
      />
    </div>
  )
}

export default SearchItem

import React, { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import AuthWrapper from './AuthWrapper'
import { hasAccess } from '../components/auth'
import { Suspense } from 'react'
import FullscreenLoading from '../components/comp/components/FullscreenLoading'
import ContentManagement from '../components/content-management'
import BusinessDashboard from '../components/business/business'
import Auditor from '../components/Auditor/Auditor'
import Profile from '../components/user/Profile'
import Management from '../components/management/Management'
import Admin from '../components/admin/Admin'
import Pharmacy from '../components/pharmacy/Pharmacy'
import AdminstrationDashboard from '../components/Administration/AdminstrationDashboard'

// const Account = lazy(() => import('../components/account/Account'))
// const Pharmacy = lazy(() => import('../components/pharmacy/Pharmacy'))
// const Admin = lazy(() => import('../components/admin/Admin'))
// const Map = lazy(() => import('../components/map/App'))
// const ReportIssues = lazy(() => import('../components/user/ReportIssues'))
// const Engineering = lazy(() => import('../components/Engineering/Index'))
// const BusinessDashboard = lazy(() => import('../components/business/business'))
// const Auditor = lazy(() => import('../components/Auditor/Auditor'))
// const AdmminstrationDashboard = lazy(() =>
//   import('../components/Administration/AdminstrationDashboard'),
// )
// const Profile = lazy(() => import('../components/user/Profile'))
// const Report = lazy(() => import('../components/report/Report'))
// const Management = lazy(() => import('../components/management/Management'))
// const Reviewer = lazy(() => import('../components/reviewer/Reviewer'))

const AuthenticatedContainer = ({ user }) => {
  return (
    <AuthWrapper>
      <Suspense fallback={<FullscreenLoading />}>
        <Switch>
          {user.accessTo
            ? hasAccess(user, ['Stock Management', 'Sales']) && (
                <Route path="/me/inventory" component={Pharmacy} />
              )
            : null}

          {/* {user.accessTo
            ? hasAccess(user, ['Accounts']) && (
                <Route path="/me/account" component={Account} />
              )
            : null} */}
          {user.accessTo
            ? hasAccess(user, ['Admin']) && (
                <Route path="/me/admin" component={Admin} />
              )
            : null}

          {/* <Route path="/me/map" component={Map} /> */}
          {/* <Route path="/me/report" component={Report} /> */}
          {/* <Route path="/me/eng" component={Engineering} /> */}
          {/* <Route exact path="/me/report_issues" component={ReportIssues} /> */}
          <Route exact path="/me/profile" component={Profile} />

          {user.accessTo
            ? hasAccess(user, ['Administration']) && (
                <Route
                  path="/me/purchase-order"
                  component={AdminstrationDashboard}
                />
              )
            : null}

          {user.accessTo
            ? hasAccess(user, ['Executive']) && (
                <Route path="/me/auditor" component={Auditor} />
              )
            : null}

          <Route path="/me/auditorr" component={Management} />
          {/* <Route path="/me/reviewer" component={Reviewer} /> */}
          {user.accessTo
            ? hasAccess(user, ['Sales']) && (
                <Route path="/me/business" component={BusinessDashboard} />
              )
            : null}
          {user.accessTo
            ? hasAccess(user, ['Content Management']) && (
                <Route
                  path="/me/content-management"
                  component={ContentManagement}
                />
              )
            : null}
        </Switch>
      </Suspense>
    </AuthWrapper>
  )
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
})

export default connect(mapStateToProps)(AuthenticatedContainer)

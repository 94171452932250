import React from 'react';
import { FaSearch } from 'react-icons/fa';
import './search.css';

export default function SearchBar({
  placeholder = '',
  filterText = '',
  onFilterTextChange = f => f,
}) {
  const handleFilterTextChange = e => {
    onFilterTextChange(e.target.value);
  };

  return (
    <div className="form-group has-search">
      <span className="form-control-feedback">
        <FaSearch />
      </span>
      <input
        name="filterText"
        value={filterText}
        onChange={handleFilterTextChange}
        type="text"
        className="form-control"
        placeholder={
          placeholder !== '' ? placeholder : 'Search for a patient...'
        }
      />
    </div>
  );
}

import React from 'react';

function FullscreenLoading() {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <p>Loading...</p>
    </div>
  );
}

export default FullscreenLoading;

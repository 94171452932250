import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { Row, Col } from 'reactstrap'
import ListMenuItem from '../comp/components/vertical-menu/ListMenuItem'
import VerticalMenu from '../comp/components/vertical-menu/VerticalMenu'
import ManageBlogs from './ManageBlogs'
import ManageGallery from './ManageGallery'
import ManageNews from './ManageNews'
import NewBlog from './NewBlog'
import NewNews from './NewNews'

function ContentManagement() {
  return (
    <Row className="m-0">
      <Col md={3}>
        <VerticalMenu title="What would you like to do?">
          <ListMenuItem route="/me/content-management/blogs">
            Manage Blog
          </ListMenuItem>
          <ListMenuItem route="/me/content-management/news">
            Manage News
          </ListMenuItem>
          <ListMenuItem route="/me/content-management/gallery">
            Manage Gallery
          </ListMenuItem>
        </VerticalMenu>
      </Col>
      <Col>
        <Switch>
          <Redirect
            exact
            from="/me/content-management"
            to="/me/content-management/blogs"
          />
          <Route
            exact
            path="/me/content-management/blogs"
            component={ManageBlogs}
          />
          <Route
            exact
            path="/me/content-management/blogs/new"
            component={NewBlog}
          />
          <Route
            exact
            path="/me/content-management/news/new"
            component={NewNews}
          />
          <Route
            exact
            path="/me/content-management/news"
            component={ManageNews}
          />
          <Route
            exact
            path="/me/content-management/gallery"
            component={ManageGallery}
          />
        </Switch>
      </Col>
    </Row>
  )
}

export default ContentManagement

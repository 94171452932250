import React from 'react';

function TextInput({
  type = 'text',
  value = '',
  name = '',
  onChange = (f) => f,
  onFocus = (f) => f,
  onBlur = (f) => f,
  disabled = false,
  placeholder = '',
  autoFocus = false,
  _ref = null,
}) {
  return (
    <>
      {!disabled ? (
        <input
          ref={_ref}
          autoFocus={autoFocus}
          className="form-control"
          type={type}
          value={value}
          name={name}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
        />
      ) : (
        <p className="form-control">{value}</p>
      )}
    </>
  );
}

export default TextInput;

import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
// import primeLogo from '../../../images/logo-p.jpg'
import { formatNumber, toCamelCase } from "../../utils/helpers";
import FooterPdf from "./FooterPdf";
import HeaderPdf from "./HeaderPdf";

// let remedix = {
//   title: "Remedix Pharmacy Ltd.",
//   sub1: "Sheikh Jafar road, Opposite Brains College,",
//   sub2: "Dorayi, Kano",
//   acronym: "RPL",
// };

// let optimum = {
//   title: "Optimum Diagnostics Center",
//   sub1: "Hospital Road, Opposite AKTH,",
//   sub2: "Kano",
//   acronym: "ODC",
// };

// let asymco = {
//   title: "Asymco Pharmacy",
//   sub1: "ZC Kundila Housing Est. Bashir Othman Tofa,",
//   sub2: "Street Off Zoo Rd. Gandun Albasa, Kano",
//   acronym: "ASP",
// };

let factory = {
  title: "BINADAM OIL MILLS LIMITED",
  sub1: "Dawakin-Dakata Road",
  sub2: "No. 995 Ranji Dakata, Kano State.",
  acronym: "BIN",
};

export const facilityDetails = factory;

export function DepositReceipt({ depositDetails, logo, facilityInfo,branch_name }) {
  return (
    <Document>
      <Page style={styles.body}>
        <View>
          <HeaderPdf title="Customer Deposit Receipt" />
          <View style={styles.details}>
            <View style={styles.item}>
              <Text style={{ marginRight: 5 }}>Receipt Number:</Text>
              <Text>{depositDetails.receiptNo}</Text>
            </View>
            <View style={styles.item}>
              <Text style={{ marginRight: 5 }}>Date:</Text>
              <Text>{moment(depositDetails.date).format("LL")}</Text>
            </View>
          </View>

          <View>
            <View style={styles.item}>
              <Text style={{ marginRight: 5 }}>Name:</Text>
              <View>
                <Text>{depositDetails.name}</Text>
              </View>
            </View>
            <View style={styles.item}>
              <Text style={{ marginRight: 5 }}>Deposit Account No:</Text>
              <View>
                <Text>{`MIN/${depositDetails.accountNo}/${moment().format(
                  "DD/YY/MM"
                )}`}</Text>
              </View>
            </View>

            <View style={styles.item}>
              <Text style={{ marginRight: 5 }}>Description:</Text>
              <Text>{depositDetails.description}</Text>
            </View>
            <View style={styles.item}>
              <Text style={{ marginRight: 5 }}>Deposit Amount:</Text>
              <Text>{`N${formatNumber(depositDetails.amount)}`}</Text>
            </View>
            <View style={styles.item}>
              <Text style={{ marginRight: 5 }}>Current Balance:</Text>
              <Text>{`N${formatNumber(depositDetails.newBalance) || 0}`}</Text>
            </View>

            <View style={styles.item}>
              <Text style={{ marginRight: 5 }}>Mode of payment:</Text>
              <Text>{toCamelCase(depositDetails.mode)}</Text>
            </View>
          </View>
          <View style={styles.cashier}>
            <Text style={{ marginRight: 5 }}>Cashier:</Text>
            <Text>{depositDetails.user}</Text>
          </View>

          <View style={styles.cashier1}>
            <Text style={{ marginRight: 5 }}>Branch Name:{branch_name}</Text>
          </View>
        </View>
        <FooterPdf />
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingVertical: 5,
    fontSize: 19,
    paddingHorizontal: 7,
  },
  title: {
    fontSize: 26,
    // textAlign: 'center',
    fontFamily: "CustomRoboto",
  },
  subtitle: {
    fontSize: 22,
    fontFamily: "CustomRoboto",
  },
  author: {
    fontSize: 17,
    marginBottom: 20,
  },
  subtitle2: {
    fontSize: 18,
    marginBottom: 30,
  },
  image: {
    height: 80,
    width: 70,
  },

  headerContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    flexDirection: "row",
    marginVertical: 8,
    marginHorizontal: 5,
  },
  details: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 30,
  },
  cashier: {
    display: "flex",
    float: "right",
    alignItems: "flex-end",
  },
  cashier1: {
    display: "flex",
    float: "right",
    alignItems: "center",
    fontSize: 13,
  },
});

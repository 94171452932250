import React, { PureComponent } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  login,
  authLoading,
  checkAuthStatus,
} from "../../../redux/actions/auth";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
  CardBody,
  Row,
} from "reactstrap";
import { Spinner } from "evergreen-ui";
import "./login.css";
import Loading from "../../loading";
import PublicWrapper from "../../../routes/PublicWrapper";

export const accountTypes = {
  PATIENT: "PATIENT",
  DOCTOR: "DOCTOR",
  OTHER: "OTHER",
};

class Login extends PureComponent {
  state = {
    formData: {
      username: "",
      passowrd: "",
      accountType: "OTHER",
    },
    loading: false,
    pageLoading: true,
  };

  //   "Reports"
  // 1: "Admin"
  // 2: "Store"
  // 3: "Accounts"
  // 4: "Administration"
  // 5: "Engineering"
  // 6: "Audit"
  // 7: "Management"

  navigateUser(access) {
    // console.log(access);
    this.setState({ pageLoading: false });
    if (access && access.length) {
      // console.log(access);
      switch (access[0]) {
        case "Stock Managment":
          return this.props.history.push("/me/inventory/dashboard");
        // case "Reports":
        //   return this.props.history.push("/me/report/total/summary");
        case "Admin":
          return this.props.history.push("/me/admin/create-new-user");
        // case "Accounts":
        //   return this.props.history.push("/me/account/report");
        case "Administration":
          return this.props.history.push("/me/purchase-order/SKUs");
        // case "Engineering":
        //   return this.props.history.push("/me/eng/equipment/registration/form");
        // case "Audit":
        //   return this.props.history.push("/me/reviewer/expenses");
        case "Management":
          return this.props.history.push("/me/auditor/pending-request");
        // case "Reviewer":
        //   return this.props.history.push("/me/auditor/expenses");
        case "Customer":
          return this.props.history.push("/me/auditor/pending-request");
        default:
          return this.props.history.push("/");
      }
    }
  }

  componentDidMount() {
    //   console.log(this.props)
    checkAuthStatus(
      (acc) => this.navigateUser(acc),
      () => this.setState({ pageLoading: false })
    );
  }

  handleChange = ({ target: { name, value } }) =>
    this.setState((prevState) => ({
      formData: Object.assign({}, prevState.formData, { [name]: value }),
    }));

  handleRadioChange = (value) =>
    this.setState((prevState) => ({
      formData: Object.assign({}, prevState.formData, { accountType: value }),
    }));

  loginSuccess = () => {
    const { accountType } = this.state.formData;
    this.setState(
      {
        loading: false,
        formData: { username: "", password: "" },
      },
      () => {
        console.log(accountType);
        switch (accountType) {
          case accountTypes.PATIENT: {
            this.props.history.push("/user");
            break;
          }
          case accountTypes.DOCTOR: {
            this.props.history.push("/me/doctor");
            break;
          }
          case accountTypes.OTHER: {
            this.props.history.push("/me/inventory");
            break;
          }
          default:
            return null;
        }
      }
    );
  };

  loginErr = () => {
    this.setState({ loading: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { username, password, accountType } = this.state.formData;
    this.props.login(
      { username, password, accountType },
      this.loginSuccess,
      this.loginErr
    );
  };

  render() {
    const {
      handleChange,
      handleSubmit,
      state: {
        formData: { username, password, accountType },
        loading,
        pageLoading,
      },
      props: { error },
    } = this;

    if (pageLoading) {
      return (
        <div style={{ marginTop: "40vh" }}>
          <Loading />
        </div>
      );
    }

    return (
      <PublicWrapper>
        <Row className="m-0">
          <img
            alt="bg"
            src={require("../../../images/logo1.png")}
            className="img-fluid h-100 w-100"
            style={{ position: "absolute", zIndex: -1, 
            opacity: 0.2,
            // background: 'rgba(76, 175, 80, 0.3)' 
          }}
          />
          <Form className="login-form" onSubmit={handleSubmit}>
            <div>
              <h3 className="text-center text-white">LOGIN</h3>
              <CardBody className="bg-white p-3">
                {/* {JSON.stringify(this.state.formData)} */}
                <FormGroup>
                  <Label>username or email</Label>
                  <Input
                    name="username"
                    value={username}
                    placeholder="username"
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>password</Label>
                  <Input
                    name="password"
                    type="password"
                    value={password}
                    placeholder="password"
                    onChange={handleChange}
                  />
                </FormGroup>

                {/* <FormGroup>
                  <label htmlFor="accountTpe">Login as:</label>
                  <div>
                    <label className="mr-4" htmlFor="patient">
                      <input
                        name="accountType"
                        id="patient"
                        type="radio"
                        checked={accountType === accountTypes.PATIENT}
                        onChange={() => handleRadioChange(accountTypes.PATIENT)}
                      />{' '}
                      Patient
                    </label>
                    <label className="mr-4" htmlFor="doctor">
                      <input
                        name="accountType"
                        id="doctor"
                        type="radio"
                        checked={accountType === accountTypes.DOCTOR}
                        onChange={() => handleRadioChange(accountTypes.DOCTOR)}
                      />{' '}
                      Doctor
                    </label>
                    <label className="mr-4" htmlFor="other">
                      <input
                        name="accountType"
                        id="other"
                        type="radio"
                        checked={accountType === accountTypes.OTHER}
                        onChange={() => handleRadioChange(accountTypes.OTHER)}
                      />{' '}
                      Other
                    </label>
                  </div>
                </FormGroup> */}

                <Button
                  className="btn-dark btn-block"
                  disabled={
                    username === "" ||
                    password === "" ||
                    !accountType ||
                    accountType === ""
                  }
                >
                  <>
                    {loading ? (
                      <center>
                        <Spinner size={20} />
                      </center>
                    ) : (
                      "Login"
                    )}
                  </>
                </Button>
              </CardBody>
              <CardFooter className="m-0 bg-warning shadow">
                <div className="text-center text-white">
                  {/* <Link to="/signup" className="text-light">
                    Sign up
                  </Link>
                  <span className="p-2">|</span> */}
                  <Link to="/forgotpassword" className="text-light">
                    Forgot password?
                  </Link>
                </div>
                <div style={{ color: "red" }}>
                  <center>{error.length ? error : ""}</center>
                </div>
              </CardFooter>
            </div>
          </Form>
        </Row>
      </PublicWrapper>
    );
  }
}

function mapStateToProps({ auth: { authenticated, error } }) {
  return {
    authenticated,
    error,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ login, authLoading }, dispatch);
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);

import React, { useState } from 'react';
import {
  CardHeader,
  Card,
  CardBody,
  Row,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Button,
} from 'reactstrap';
import PendingExpensises from './PendingExpensises';

function Process() {
  const [state, setstate] = useState(false);
  return (
    <div>
      <Card>
        <CardHeader>Process</CardHeader>
        <CardBody>
          {state ? (
            <Form>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label>Date:</Label>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Request No:</Label>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Pv No:</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label>Description:</Label>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Amount:</Label>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Remark:</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label>Other Remark:</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Account Head:</Label>
                    <Input />
                  </FormGroup>
                </Col>
              </Row>
              <center>
                <Button color="primary">Submit</Button>
              </center>
            </Form>
          ) : (
            <PendingExpensises setstate={setstate} />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default Process;

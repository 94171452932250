import React, { Component } from 'react'
import {
  Table,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
} from 'reactstrap'
import SearchBar from '../utils/SearchBar'
import { _deleteApi, _fetchApiGeneral } from '../../redux/actions/api'
import { apiURL } from '../../redux/actions'
import { _customNotify } from '../utils/helpers'
import { MdRateReview, MdDelete } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'

export default class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
      users: [],
    }
  }

  fetchData() {
    _fetchApiGeneral(
      `${apiURL()}/users`,
      (data) => {
        // console.log(data);
        if (data.results.length) {
          this.setState({ users: data.results })
        }
      },
      (err) => console.log(err),
    )
  }

  componentDidMount() {
    this.fetchData()
  }

  handleSearchTermChange = (value) => {
    console.log(value)
    this.setState({ searchTerm: value })
  }

  handleActionSelectChange = async (user, value) => {
    switch (value) {
      case 'approve': {
        // toggle(true);
        let userId = user.id
        let response = await fetch(`${apiURL()}/users/approve/${userId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'aplication/json' },
        })
        let data = await response.json()
        if (data) {
          console.log(data)
          _customNotify('Data updated!')
          this.fetchData()
        }
        // toggle(false);
        break
      }
      case 'suspend': {
        // toggle(true);
        let userId = user.id
        let response = await fetch(`${apiURL()}/users/suspend/${userId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'aplication/json' },
        })
        let data = await response.json()
        if (data) {
          console.log(data)
          _customNotify('Data updated!')
          this.fetchData()
        }
        // toggle(false);
        break
      }
      default:
        return null
    }
  }

  handleDelete = (id, facilityId) => {
    //  console.log(id, facilityId)
    _deleteApi(
      `${apiURL()}/users/delete/${id}/${facilityId}`,
      {},
      (data) => {
        // if(data.success){
        _customNotify('Deleted successfully')
        this.fetchData()
        // }
      },
      (err) => {
        console.log(err)
      },
    )
  }

  render() {
    const { users, searchTerm } = this.state

    const rows = []
    if (users.length) {
      users.forEach((user, i) => {
        if (
          user.busName &&
          user.busName.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1 &&
          user.username.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1
        )
          return

        rows.push(
          <TableRow
            key={i}
            idx={i}
            user={user}
            fullname={user.busName}
            username={user.username}
            role={user.role}
            id={user.id}
            facilityId={user.facilityId}
            handleActionSelectChange={this.handleActionSelectChange}
            handleDelete={this.handleDelete}
          />,
        )
      })
    }

    return (
      <Card>
        <CardHeader className="text-center" tag="h6">
          List of All Users
        </CardHeader>
        <CardBody>
          <SearchBar
            filterText={this.state.searchTerm}
            onFilterTextChange={this.handleSearchTermChange}
            placeholder="Search by name"
          />

          <Scrollbars style={{ height: 400 }}>
            <Table size="md" bordered hover striped>
              <thead>
                <tr>
                  <th className="text-center">S/N</th>
                  <th className="text-center">Name</th>
                  <th className="text-center">Username</th>
                  <th className="text-center">Role</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Action</th>
                  <th />
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </Scrollbars>
        </CardBody>
      </Card>
    )
  }
}

const TableRow = ({
  user,
  // firstname,
  fullname,
  username,
  role,
  handleActionSelectChange,
  handleDelete,
  id,
  facilityId,
  key, idx
}) => {
  const history = useHistory()
  // const match = useRouteMatch();
  // const ids = match.params.id;

  const handleClick = () => {
    history.push(`/me/admin/staffreview/${id}`)
  }
  return (
    <>
      {/* {JSON.stringify(ids)} */}
      <tr>
        <td>{idx + 1}</td>
        <td>{fullname}</td>
        <td>{username}</td>
        <td>{role}</td>
        <td>{user.status}</td>
        <td>
          <FormGroup>
            <select
              className="mt-2 form-control"
              onChange={(e) => handleActionSelectChange(user, e.target.value)}
            >
              <option value="">Select Action</option>
              <option value="approve">Approve</option>
              <option value="suspend">Suspend</option>
            </select>
          </FormGroup>
        </td>
        <td>
          <Button
            color="success"
            size="sm"
            onClick={() => handleClick()}
            className="mr-1"
          >
            <MdRateReview
              size={20}
              fontWeight="bold"
              // style={{ marginRight: 10 }}
            />
            {/* Review */}
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => handleDelete(id, facilityId)}
          >
            <MdDelete size={20} fontWeight="bold" />
          </Button>
        </td>
      </tr>
    </>
  )
}
TableRow.defaultProps = {
  users: [],
}

import React, { useState, useCallback } from 'react'
import BasicInformation from './components/client-form'
import { useEffect } from 'react'
import { apiURL } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../comp/components/Button'
import {
  _customNotify,
  generateReceiptNo,
  convertSignedMoney,
  _warningNotify,
} from '../utils/helpers'
import moment from 'moment'
import { _deleteApi, _fetchApi, _postApi } from '../../redux/actions/api'
import { Route, useHistory, useLocation } from 'react-router'
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap'
// import { formatNumber } from "../utils/helpers";
import BackButton from '../comp/components/BackButton'
import SearchBar from '../record/SearchBar'
import { MdEdit } from 'react-icons/md'
import ClientEdit from './components/ClientEdit'
import { checkUsername } from '../auth/registration/api'
import { createBusinessUser } from '../../redux/actions/auth'
import CustomScrollbar from '../comp/components/CustomScrollbar'
import Loading from '../comp/components/Loading'
import CustomButton from '../comp/components/Button'

const initialForm = {
  name: '',
  gender: '',
  dob: '',
  deposit: '',
  maritalStatus: '',
  occupation: '',
  clientAccount: '',
  clientBeneficiaryAcc: '',
  depositAmount: '0',
  modeOfPayment: 'Cash',
  date: moment().format('YYYY-MM-DD'),
  donorNo: '',
  donorName: '',
  address: '',
  contactName: '',
  phone: '',
  email: '',
  website: '',
  // deposite: '',
  contactAddress: '',
  accountType: 'Family',
  contact: 'other',
  patientId: '',
  contactPhone: '',
  bankName: '',
  guarantor_name: '',
  guarantor_address: '',
  guarantor_phoneNo: '',
  checkingUsername: false,
  usernameGood: false,
  usernameMsg: '',
  branch_name: '',
  id: '',
  accessTo: [],
  functionalities: ['Business'],
  relation_with_app: '',
  year_relationship_with_app: '',
  username: '',
  password: '',
  crm: '',
  state: '',
  lga: '',
  customer_type: '',
}

function AddClient() {
  // eslint-disable-next-line no-unused-vars
  const [nextClientID, setNextClientID] = useState('')
  const [loading, setLoading] = useState(false)
  const facilityId = useSelector((state) => state.facility.info.facility_id)
  const userId = useSelector((state) => state.auth.user.username)
  const [, setAccounts] = useState([])

  const [client, setClient] = useState(initialForm)

  const dispatch = useDispatch()

  const handleSelectedBank = (value) => {
    setClient((prev) => ({
      ...prev,
      bankName: value,
    }))
  }
  //   const [collect, setCollect] = useState('self')
  // const [regType, setRegType] = useState('Family')

  // const handleRadio = (e) =>{
  //   setCollect(e.target.value)
  // }

  const handleUsernameChange = (value) => {
    setClient((prev) => ({
      ...prev,
      username: value,
      checkingUsername: true,
    }))
    checkUsername(
      value,
      (msg) => {
        setClient((prev) => ({
          ...prev,
          checkingUsername: false,
          usernameGood: true,
          usernameMsg: msg,
        }))
      },
      (err) => {
        setClient((prev) => ({
          ...prev,
          checkingUsername: false,
          usernameGood: false,
          usernameMsg: err,
        }))
      },
    )
  }

  const getApprovedClients = () => {
    _fetchApi(
      `${apiURL()}/accounts/approved/list`,
      ({ results }) => {
        setAccounts(results)
      },
      (err) => console.log(err),
    )
  }

  const getNextPatientId = async () => {
    try {
      const response = await fetch(
        `${apiURL()}/client/next-patient-id/${facilityId}`,
      )
      return await response.json()
    } catch (error) {
      return error
    }
  }

  const resetForm = () => {
    setClient(initialForm)
    getIds()
  }

  const getNextClientID = useCallback(async () => {
    try {
      const response = await fetch(`${apiURL()}/client/nextId/${facilityId}`)
      return await response.json()
    } catch (error) {
      return error
    }
  }, [facilityId])

  const getNextBeneficiaryId = useCallback(
    async (acc) => {
      try {
        const response = await fetch(
          `${apiURL()}/client/nextBeneficiaryId/${acc}/${facilityId}`,
        )
        return await response.json()
      } catch (error) {
        return error
      }
    },
    [facilityId],
  )

  const handleChange = ({ target: { name, value } }) => {
    setClient((prev) => ({ ...prev, [name]: value }))
  }

  const getIds = useCallback(() => {
    getNextClientID()
      .then((d) => {
        // console.log(d);
        if (d.success) {
          let acc = d.results.accountNo
          setClient((prev) => ({
            ...prev,
            clientAccount: acc,
          }))
          getNextBeneficiaryId(acc)
            .then((d) => {
              // console.log(d);
              if (d.success) {
                let ben = d.results.beneficiaryNo
                setClient((prev) => ({
                  ...prev,
                  clientBeneficiaryAcc: ben,
                }))
              }
            })
            .catch((err) => {
              console.log(err)
            })
          // console.log(d.results.accountNo);
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getNextPatientId()
      .then((data) => {
        if (data.success) {
          setClient((p) => ({ ...p, patientId: data.results.id }))
        }
      })
      .catch((err) => console.log(err))
  }, [getNextClientID, getNextBeneficiaryId])

  useEffect(() => {
    getIds()
    getApprovedClients()
  }, [getIds])

  const saveAsBeneficiary = async (client) => {
    try {
      let response = await fetch(`${apiURL()}/client/beneficiary/new`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(client),
      })
      return response.json()
    } catch (error) {
      return error
    }
  }

  const saveNewClient = async (client) => {
    try {
      // let response = await fetch(`${apiURL()}/lab/client/account/new`, {
      let response = await fetch(`${apiURL()}/client/new`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(client),
      })
      return response.json()
    } catch (error) {
      return error
    }
  }

  // const saveUserUpdate = () => {
  //   // e.preventDefault();
  //   // let newAccessTo = client.accessTo.join(",");
  //   // let newFunctionality = client.functionalities.join(",");
  //   let [firstname, ...lastname] = client.name.split(" ");

  //   const data = {
  //     username: client.phone,
  //     email: "",
  //     phone: client.phone,
  //     password: client.password,
  //     busName: client.business_name,
  //     address: client.address,
  //     status: "approved",
  //     role: "Business",
  //     date: moment().format("YYYY-MM-DD"),
  //     accessTo: client.accessTo[0],
  //     functionality: client.functionalities[0],
  //     firstname,
  //     lastname: lastname.join(""),
  //     // privilege,
  //     // accessTo,
  //   };
  //   let callback = () => {
  //     _customNotify("User successfully created!");
  //     // this.resetForm();
  //   };
  //   let error = (err) => {
  //     _warningNotify(err);
  //     // this.resetForm();
  //   };
  //   dispatch(createUser(data, callback, error));
  // };

  // const setClientObj = (key, val) => {
  //   setClient((prev) => ({ ...prev, [key]: val }));
  // };

  // const createBranch = (form, callback, error) => {
  //   _postApi(
  //     `${apiURL()}/account/create/branch`,
  //     {
  //       data: {
  //         branch_name: form.business_name,
  //         state: form.address,
  //         address: form.address,
  //         facilityId,
  //         crm: form.crm,
  //       },
  //     },
  //     () => callback(),
  //     () => error()
  //   );
  // };

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    // saveUserUpdate();
    let [firstname, ...lastname] = client.name.split(' ')
    let destination =
      client.modeOfPayment.toLowerCase() === 'cash' ? 'Cash' : 'Bank'

    let clientObject = {
      ...client,
      // user info
      username: client.phone,
      email: '',
      phone: client.phone,
      password: client.password,
      busName: client.business_name,
      // address: client.address,
      status: 'approved',
      role: 'Business',
      date: moment().format('YYYY-MM-DD'),
      accessTo: client.accessTo[0],
      functionality: client.functionalities[0],
      // firstname,
      lastname: lastname.join(''),
      // branch
      branch_name: client.business_name,
      // state: client.address,
      address: client.address,
      facilityId,
      crm: client.crm,
      // account
      firstname,
      surname: lastname.join(''),
      id: `${client.clientAccount}-${client.clientBeneficiaryAcc}`,
      facId: facilityId,
      userId,
      source: 'Deposit',
      destination,
      bankName: client.bankName,
      // receiptsn: rec,
      // receiptno: receiptNo,
      description: `Deposit from account ${client.clientAccount}`,
      // crm: client.crm,
    }

    dispatch(
      createBusinessUser(
        clientObject,
        (data) => {
          // if(data.success) {
          setLoading(false)
          _customNotify('New Account created for client')
          resetForm()
          // }
        },
        (err) => {
          _warningNotify(
            err.msg ? err.msg : 'An error occured, please try again.',
          )
          console.log(err)
          setLoading(false)
        },
      ),
    )

    // createBranch(
    //   client,
    //   () => {
    //     generateReceiptNo((rec, receiptNo) => {
    //       const obj = {
    //         ...client,
    //         firstname,
    //         surname,
    //         id: `${client.clientAccount}-${client.clientBeneficiaryAcc}`,
    //         facId: facilityId,
    //         userId,
    //         source: "Deposit",
    //         destination,
    //         bankName: client.bankName,
    //         receiptsn: rec,
    //         receiptno: receiptNo,
    //         description: `Deposit from account ${client.clientAccount}`,
    //         crm: client.crm,
    //       };
    //       // if (client.accountType === 'Family') {
    //       // if (client.clientBeneficiaryAcc === 1) {
    //       saveNewClient(obj)
    //         .then((data) => {
    //           setLoading(false);
    //           if (data.success) {
    //             // _customNotify()
    //             // clear form
    //             // alert('Success');
    //             _customNotify("New Account created for client");
    //             resetForm();
    //           }
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //           setLoading(false);
    //         });
    //     });
    // },
    // (err) => {
    //   console.log("Error", err);
    // }
    // );
  }

  const accNoChange = (acc) => {
    setClient((prev) => ({ ...prev, clientAccount: acc }))
    getNextBeneficiaryId(acc)
      .then((resp) => {
        console.log(resp)
        if (resp.success) {
          setClient((prev) => ({
            ...prev,
            clientBeneficiaryAcc: resp.results.beneficiaryNo,
          }))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const newClient = () => {
    history.push('/me/business/create-customer-account/form')
  }
  const formIsValid = client.name !== ''
  const history = useHistory()
  const location = useLocation()
  return (
    <div>
      {location.pathname === '/me/business/create-customer-account' ? (
        <Button className="mb-1" color="dark" onClick={newClient}>
          Add New Customer
        </Button>
      ) : (
        <BackButton />
      )}
      {/* {JSON.stringify(client)} */}
      <Route path="/me/business/create-customer-account/form" exact>
        <BasicInformation
          patientHospitalId={nextClientID}
          id_label="Client ID"
          client={client}
          onInputChange={handleChange}
          accNoChange={accNoChange}
          setContactPerson={(person) =>
            setClient((p) => ({ ...p, contact: person }))
          }
          setAccountType={(accType) =>
            setClient((p) => ({
              ...p,
              accountType: accType,
              contact: accType !== 'Family' ? 'other' : 'self',
            }))
          }
          handleSelectedBank={handleSelectedBank}
          handleUsernameChange={handleUsernameChange}
          checkingUsername={client.checkingUsername}
          usernameGood={client.usernameGood}
          usernameMsg={client.usernameMsg}
          setClient={setClient}
        />
        {/* {JSON.stringify(client)} */}
        <div className="d-flex flex-row justify-content-center">
          <Button
            loading={loading}
            disabled={!formIsValid}
            onClick={handleSubmit}
            color="dark"
            className="pr-5 pl-5 m-2"
          >
            Submit now
          </Button>
        </div>
      </Route>
      <Route path="/me/business/create-customer-account/form/:accountNo" exact>
        <ClientEdit />
      </Route>
      {location.pathname === '/me/business/create-customer-account' ? (
        <CustomerDebtorsReport />
      ) : null}
    </div>
  )
}

export default AddClient

function CustomerDebtorsReport() {
  const user = useSelector((state) => state.auth.user)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [selectedDeleteData, setSelectedDeleteData] = useState({})
  const [deleting, setDeleting] = useState(false)

  const canDelete = user.accessTo.includes('canDeleteCustomer')

  // const [preview, setPreview] = useState(true);

  // const handlePreview = () => {
  //   setPreview((d) => !d);
  // };
  const [loading, setLoading] = useState('')
  const getData = useCallback(() => {
    setLoading(true)
    fetch(`${apiURL()}/account/customer/creditors/factory/${user.facilityId}`)
      .then((raw) => raw.json())
      .then((data) => {
        setLoading(false)
        if (data.success) {
          setData(data.data)
          setTotal(data.total)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [user.facilityId])

  useEffect(() => {
    getData()
  }, [getData])
  const deleteCustormer = (accountNo, account) => {
    setDeleting(true)
    _deleteApi(
      `${apiURL()}/doc/custormer/delete?in_acc_no=${accountNo}&in_account_name=${account}`,
      {},
      (data) => {
        setDeleting(false)
        setConfirmDelete(false)
        // if(data.success){
        _customNotify('Deleted successfully')
        getData()
        // }
      },
      (err) => {
        setDeleting(false)
        console.log(err)
      },
    )
  }

  const history = useHistory()
  let rows = []
  data.length &&
    data.forEach((item, index) => {
      if (
        item.account.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1 &&
        item.contactPhone
          .toString()
          .toLowerCase()
          .indexOf(searchTerm.toLocaleLowerCase()) === -1
      )
        return
      rows.push(
        <tr key={index}>
          <td>
            <div className="d-flex flex-direction-row align-items-center">
              <Button
                outline
                color="success"
                size="sm"
                className="mr-2"
                onClick={() =>
                  history.push(
                    `/me/business/create-customer-account/form/${item.accountNo}`,
                  )
                }
              >
                <small>
                  {/* <MdEdit /> */}
                  Edit
                </small>
              </Button>
              {canDelete && (
                <Button
                  outline
                  color="danger"
                  size="sm"
                  onClick={() => {
                    setConfirmDelete(true)
                    setSelectedDeleteData(item)
                  }}
                >
                  {' '}
                  Delete
                </Button>
              )}
            </div>
          </td>
          <td>{index + 1}</td>
          <td
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push(
                `/me/business/create-customer-account/form/${item.account}/${item.accountNo}`,
              )
            }}
          >
            {item.account}
          </td>
          <td>{item.contactAddress}</td>
          <td>{item.segment}</td>
          <td>{item.contactPhone}</td>
          <td>{item.guarantor_name}</td>
          <td>{item.guarantor_phone}</td>
          {/* <td className="text-right">
            {convertSignedMoney(item.balance) || 0}
          </td> */}
        </tr>,
      )
    })
  return (
    <>
      {/* {preview ? (
        <> */}
      {/* <Button
            color="primary"
            className="pl-5 pr-5 float-right mb-3"
            onClick={() => handlePreview()}
          >
            <FaPrint size="20" /> Print{' '}
          </Button> */}
      <Card>
        {/* {JSON.stringify(data.accountNo)} */}
        <CardBody>
          <SearchBar
            filterText={searchTerm}
            onFilterTextChange={(searchTerm) => setSearchTerm(searchTerm)}
            placeholder="Search by Customer Name"
          />
          <span className="font-weight-bold">
            Total Number of Customers: {data.length}
          </span>
          <CustomScrollbar>
            {loading ? <Loading /> : null}
            <Table bordered striped responsive>
              <thead>
                <tr>
                  {/* <th className="text-center">Date</th> */}
                  <th className="text-center">Action</th>
                  <th className="text-center">S/N</th>
                  <th className="text-center">Customer Name</th>
                  <th className="text-center">Address</th>
                  <th className="text-center">Segment</th>
                  <th className="text-center">Phone</th>
                  <th className="text-center">Guarantor Name</th>
                  <th className="text-center">Guarantor Phone No.</th>
                  {/* <th className="text-center">Balance</th> */}
                </tr>
              </thead>
              <tbody>
                {rows}
                {/* <tr>
                  <th colSpan="3" />
                  <th className="font-weight-bold">Total</th>
                  <th className="text-right">
                    {convertSignedMoney(total) || 0}
                  </th>
                </tr> */}
              </tbody>
            </Table>
          </CustomScrollbar>
        </CardBody>
        <Modal
          isOpen={confirmDelete}
          toggle={() => setConfirmDelete((p) => !p)}
        >
          <ModalHeader toggle={() => setConfirmDelete((p) => !p)}>
            Confirm Delete
          </ModalHeader>
          <ModalBody>
            <h2>Are you sure you want to delete this account?</h2>
            <p>This account can not be reversed!</p>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => setConfirmDelete((p) => false)}>
              Cancel
            </Button>
            <CustomButton
              loading={deleting}
              onClick={() =>
                deleteCustormer(
                  selectedDeleteData.accountNo,
                  selectedDeleteData.account,
                )
              }
            >
              Confirm
            </CustomButton>
          </ModalFooter>
        </Modal>
      </Card>
      {/* </>
      ) : (
        <>
          <Button
            color="danger"
            className="p-3 float-right mb-3"
            onClick={() => handlePreview()}
          >
            <FaWindowClose size="20" /> Close{' '}
          </Button>
          <center>
            <PDFViewer height="900" width="600">
              <CreditReportPDF type="Debtors" data={data} />
            </PDFViewer>
          </center>
        </>
      )} */}
    </>
  )
}

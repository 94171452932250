import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Button, Table } from 'reactstrap'
import { apiURL } from '../../redux/actions'
import { _fetchApiGeneral } from '../../redux/actions/api'
import CustomScrollbar from '../comp/components/CustomScrollbar'
import Loading from '../comp/components/Loading'
import PrintWrapper from '../comp/components/PrintWrapper'
import { formatNumber } from '../utils/helpers'
import SearchBar from '../utils/SearchBar'

function CustomerCreditLimit() {
  const [searchTerm, setSearchTerm] = useState('')
  const [data, setData] = useState([])
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const getList = () => {
    setLoading(true)
    _fetchApiGeneral(
      `${apiURL()}/account/customer-details?query_type=list`,
      (data) => {
        setLoading(false)
        if (data && data.results && data.results.length) {
          setData(data.results)
          // const account_name = data.results[0].accName
        }
      },
      (err) => {
        setLoading(false)
        console.log(err)
      },
    )
  }

  useEffect(() => {
    getList()
  }, [])

  let rows = []
  data.length &&
    data.forEach((item, index) => {
      if (item.accName.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1)
        return
      rows.push(
        <tr key={index}>
          <td>{index+1}</td>
          <td>{item.accName}</td>
          <td className="text-right">{formatNumber(item.utilized_credit)}</td>
          <td className="text-right">{formatNumber(item.credit_limit)}</td>
          <td className="text-right">{formatNumber(item.credit_allowance)}</td>
          <td>
            <Button
              outline
              color="success"
              size="sm"
              onClick={() =>
                history.push(
                  `/me/business/customer-account-entries?page_type=details&client_id=${item.acct}&date=all&branch_name=${item.accName}`,
                )
              }
            >
              View Details
            </Button>
            {/* <Button
              outline
              color="success"
              size="sm"
              onClick={() =>
                history.push(
                  `/me/business/customer-account-entries?page_type=stock-history&client_id=${item.acct}&date=all&branch_name=${item.accName}`,
                )
              }
            >
              View Stock
            </Button> */}
          </td>
        </tr>,
      )
    })

  return (
    <CustomScrollbar>
      <SearchBar
        filterText={searchTerm}
        onFilterTextChange={(searchTerm) => setSearchTerm(searchTerm)}
        placeholder="Search by Customer Name"
      />
      {loading && <Loading />}
      <div id="customer-account-balance">
        <PrintWrapper
          title={`Customers Account Balance as of ${moment().format(
            'DD/MM/YYYY HH:mm',
          )}`}
        >
          <Table bordered striped responsive size="sm">
            <thead>
              <tr>
                {/* <th className="text-center">Date</th> */}
                <th className="text-center">S/N</th>
                <th className="text-center">Customer Name</th>
                <th className="text-center">Utilized Credit</th>
                <th className="text-center">Credit Limit</th>
                <th className="text-center">Credit Allowance</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </PrintWrapper>
      </div>
    </CustomScrollbar>
  )
}

export default CustomerCreditLimit

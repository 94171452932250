import React from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";

const today = moment().format("YYYY-MM-DD");

function DaterangeSelector({
  from = today,
  to = today,
  handleChange = (f) => f,
}) {
  return (
    <Row>
      <Col md={4}>
        <FormGroup>
          <Label style={{ fontWeight: "bold" }}>From:</Label>
          <Input type="date" value={from} name="from" onChange={handleChange} />
        </FormGroup>
      </Col>

      <Col md={4} />

      <Col md={4}>
        <FormGroup>
          <Label style={{ fontWeight: "bold" }}>To:</Label>
          <Input type="date" value={to} name="to" onChange={handleChange} />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default DaterangeSelector;

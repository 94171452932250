import React, { Component, lazy } from "react";
import { Suspense } from "react";
import { connect } from "react-redux";
import { init, getAllSuppliers } from "../../redux/actions/pharmacy";
import FullscreenLoading from "../comp/components/FullscreenLoading";
// import { PharmacyGuide } from '../Guides';
// import { getPatients } from '../../redux/actions/records';
// import './pharmacy.css';
// import PharmNav from './components/PharmNav';
const PendingPharmacyRequest = lazy(() => import("./PendingPharmacyRequest"));
const DrugAlert = lazy(() => import("../comp/alert/DrugAlert"));
const PharmacyMenu = lazy(() => import("./PharmacyMenu"));
const PharmacyDashboard = lazy(() => import("./PharmacyDashboard"));
const { PharmTabs } = lazy(() => import("./PharmacyDashboard"));

class Pharmacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
      isRoute: true,
      processing: false,
      details: {},
      component: "Dashboard",
    };
  }

  componentDidMount() {
    this.props.init();
    // this.props.getPatients();
    this.props.getAllSuppliers();
  }

  setComponentToRender = (component) => this.setState({ component });

  toggleProcessingForm = () =>
    this.setState((prevState) => ({ processing: !prevState.processing }));

  getDetails = (details) => {
    this.setState({ details });
    this.toggleProcessingForm();
  };

  toggleRoute = () =>
    this.setState((prevState) => ({ isRoute: !prevState.isRoute }));

  render() {
    const type = "pharmacy";
    const {
      state: { details, processing, component },
      getDetails,
      toggleProcessingForm,
      setComponentToRender,
    } = this;
    return (
      <Suspense fallback={<FullscreenLoading />}>
        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3">
            {/* <PharmacyGuide /> */}
            {type === "pharmacy" ||
            type === "factory" ||
            type === "retailStore" ? (
              <PharmacyMenu
                component={component}
                setComponentToRender={setComponentToRender}
              />
            ) : (
              <PendingPharmacyRequest
                getDetails={getDetails}
                processing={processing}
              />
            )}
          </div>
          <div className="col-xs-12 col-s-12 col-md-7 col-lg-7">
            <PharmacyDashboard
              component={component}
              details={details}
              processing={processing}
              toggleProcessingForm={toggleProcessingForm}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            {type !== "pharmacy" ? (
              <PharmTabs
                component={component}
                setComponentToRender={setComponentToRender}
              />
            ) : null}
            <DrugAlert />
          </div>
        </div>
      </Suspense>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(init()),
  // getPatients: () => dispatch(getPatients()),
  getAllSuppliers: () => dispatch(getAllSuppliers()),
});

export default connect(null, mapDispatchToProps)(Pharmacy);

import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { useHistory } from "react-router";
import { _fetchApiGeneral } from "../../../redux/actions/api";
import { apiURL } from "../../../redux/actions";
import Loading from "../../loading";
import useQuery from "../../utils/UseQuery";
import { formatNumber } from "../../utils/helpers";
import CustomerCreditDetails from "../../pharmacy/pending-requests/CustomerCreditDetails";
import BackButton from "../../comp/components/BackButton";

export default function AccountEntriesDailySummary() {
  const query = useQuery();
  const client_id = query.get("client_id");
  const branch_name = query.get("branch_name");
  const user = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState("");
  const [list, setList] = useState([]);

  const history = useHistory();

  const getStatement = useCallback(() => {
    setLoading(true);
    // let roles = user;
    // let newArr = [];
    _fetchApiGeneral(
      `${apiURL()}/api/account/get-client-statement?clientId=${client_id}&facilityId=${
        user.facilityId
      }&query_type=daily_total`,
      (data) => {
        setLoading(false);
        if (data && data.results) {
          setList(data.results);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [client_id, user.facilityId]);

  useEffect(() => {
    getStatement();
  }, [getStatement]);

  return (
    <>
      <BackButton type="link" />
      <div className="my-2">
        <CustomerCreditDetails customer={branch_name} />
      </div>
      {loading ? <Loading /> : null}
      {/* <SearchBar
            filterText={searchTerm}
            onFilterTextChange={(searchTerm) => setSearchTerm(searchTerm)}
            placeholder="Search by Shop name, status and request no."
          /> */}
      <Table bordered striped size="sm">
        <thead>
          <tr>
            <th className="text-center">Date</th>
            <th className="text-center">Debit</th>
            <th className="text-center">Credit</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, index) => (
            <tr key={index}>
              <td>
                <a
                  href="#no-link"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(
                      `/me/auditor/pending-request/account-details?date=${item.createdAt}&client_id=${client_id}&branch_name=${branch_name}`
                    );
                  }}
                >
                  {item.createdAt}
                </a>
              </td>
              <td className="text-right">{formatNumber(item.cr)}</td>
              <td className="text-right">{formatNumber(item.dr)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import "react-responsive-carousel/lib/styles/carousel.min.css"
// import Maintenance from '.maintenance-page/maintenance'
// import Admin from './components/AdminModule/Admin'

import registerServiceWorker from './registerServiceWorker';

render(
  <Provider store={store}>
    <App /> 
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();

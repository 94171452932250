import React from 'react';
import {  View, Text, StyleSheet } from '@react-pdf/renderer';
 const FooterPdf = () => (
 <View style={styles.container}>
      <View>
        <View style={styles.flex}>
          <View style={styles.flex}>
            <Text style={styles.Approve}>Checked By : </Text>
            <Text style={styles.dotted1} />
          </View>
          <View style={styles.flex}>
            <Text style={styles.Approve1}>Approved By : </Text>
            <Text style={styles.dotted} />
          </View>
        </View>
      </View>

      <View>
        <View style={styles.flex}>
          <View style={styles.flex}>
            <Text style={styles.Approve}>Authorizes By : </Text>
            <Text style={styles.dottedAuth} />
          </View>
          <View style={styles.flex}>
            <Text style={styles.Approve1}>Recieved By : </Text>
            <Text style={styles.dotted} />
          </View>
        </View>
      </View>

      <View>
        <View style={styles.flex}>
          <View style={styles.flex}>
            <Text style={styles.Approve}>Issued By : </Text>
            <Text style={styles.dottedIssue} />
          </View>
          <View style={styles.flex}>
            <Text style={styles.Approve1}>Deliver By : </Text>
            <Text style={styles.dottedDeliver} />
          </View>
        </View>
      </View>

      <View>
        <View style={styles.flex}>
          <View style={styles.flex}>
            <Text style={styles.Approve}>Customer Sign & Date : </Text>
            <Text style={styles.dottedCustomer} />
          </View>
          <View style={styles.flex}>
            <Text style={styles.Approve1}>Sign & Date : </Text>
            <Text style={styles.dotted} />
          </View>
        </View>
      </View>
      </View>
);
export default FooterPdf
const styles = StyleSheet.create({
  container:{
    marginTop:5
  },
  dottedCustomer: {
    borderBottom: '1 dashed black',
    width: 150,
    marginBottom: 3,
    marginLeft: 2,
  },
  dottedIssue: {
    borderBottom: '1 dashed black',
    width: 215,
    marginBottom: 3,
    marginLeft: 2,
  },
  dottedDeliver: {
    borderBottom: '1 dashed black',
    width: 190,
    marginBottom: 3,
    marginLeft: 2,
  },
  dottedAuth: {
    borderBottom: '1 dashed black',
    width: 192,
    marginBottom: 3,
    marginLeft: 2,
  },
  dotted: {
    borderBottom: '1 dashed black',
    width: 180,
    marginBottom: 3,
    marginLeft: 2,
  },
  dotted1: {
    borderBottom: '1 dashed black',
    width: 197,
    marginBottom: 3,
    marginLeft: 2,
  },
  flex: {
    flexDirection: 'row',
    margin: 2,
  },
  Approve1: {
    fontSize: 12,
    marginLeft: 2,
  },
  Approve: {
    fontSize: 12,
  },
  body: {
    padding: 30,
    fontSize: 10,
  },
});

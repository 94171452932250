import React, { lazy, Suspense } from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
// import Login from './components/auth/login/login';
// import AuthenticatedContainer from './routes/AuthenticatedContainer';
import ErrorBoundary from "./components/utils/error-boundary/MyErrorBoundary";
import "./styles/styles.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-dropzone-uploader/dist/styles.css";
import "./styles/index.css";
import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toggleMobileView } from "./redux/actions/app";
import ForgetPassword from "./components/auth/login/ForgetPassword";
import PrivacyPolicy from './components/utils/PrivacyPolicy'
import Home from "./components/landingPage/Home"
import history from "./history";

import PageNotFound from "./components/comp/components/PageNotFound";
import FullscreenLoading from "./components/comp/components/FullscreenLoading";
import AuthenticatedContainer from "./routes/AuthenticatedContainer";
const Admin = lazy(() => import("./components/admin"));
const Login = lazy(() => import("./components/auth/login/login"));

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const resize = () => {
    dispatch(toggleMobileView(window.innerWidth <= 760));
  };

  // const logAPI = useCallback(() => {
  //   const xmlText = `<?xml version="1.0" encoding="UTF-8"?>
  //   <User>
  //   <username>{{api-username}}</username>
  //   <password>{{api-password}}</password>
  //   </User>`;

  //   fetch(`https://stagingapi.bizomstaging.in/`,
  //     {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'text/xml',
  //       'Access-Control-Allow-Origin':'*'  },
  //       body: xmlText,
  //     })
  //     .then((response) => {
  //       // console.log(response);
  //       if (response.status >= 400) {
  //         console.error(response);
  //       } else console.log(response);
  //     })
  //     .catch((err) => console.error(err));
  // })

  // useEffect(() => {
  //   logAPI()
  // })

  return (
    <ErrorBoundary>
      <Router basename={process.env.PUBLIC_URL} history={history}>
        <Suspense fallback={<FullscreenLoading />}>
          <Switch>
            <Redirect from="/" to="/auth" exact />
            <Route path="/auth" component={Login} />
            {/* <Route path="/landing" component={Home} /> */}
            <Route path="/forgotpassword" component={ForgetPassword} />
            <Route path="/me" component={AuthenticatedContainer} />
            {/* <Route path="/admin" component={Admin} /> */}
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            {/* <Route path="/user" component={PatientApp} /> */}
            <Route component={PageNotFound} />
          </Switch>
        </Suspense>
      </Router>
    </ErrorBoundary>
  );
}

export default App;

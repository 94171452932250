import moment from 'moment';
import React from 'react';

function Footer() {
  return (
    <p
      className="text-center"
      style={{
        borderTop: '1px solid #E7E7E7',
        textAlign: 'center',
        padding: '30px',
        left: '0',
        bottom: '0',
        height: '60px',
        width: '100%',
      }}
    >
      {`Copyright ${moment().format("YYYY")} - BITS Accounting, Powered by: Brainstorm IT Solution`}
    </p>
  );
}

export default Footer;

export const SOCKET_ENDPOINT = "https://hms-socket-server.herokuapp.com";

// // deploy setup
export function apiURL() {
  // if (!navigator.onLine) {
  //   return "https://yge.wvi.mybluehost.me/test/kirana-server";
  // } else {
  // return "http://127.0.0.1:42428";
  // return "https://yge.wvi.mybluehost.me/test/kirana-server";
  // return "https://sanda-tireda-d8af83ae6ea1.herokuapp.com";
  return "https://galaxybis.ebudgetkano.ng/sanda-server";
  // }
}

export const mylikitaURL = "https://mylikita.herokuapp.com";
// export const mylikitaURL = 'http://localhost:9000';

// export const twilioServer = 'http://localhost:6000';
export const twilioServer = "https://bits-video-server.herokuapp.com";

import { combineReducers } from 'redux';
import appReducer from './app';
import authReducer from './auth';
import pharmReducer from './pharmacy';
import recordsReducer from './records';
import transactionsReducer from './transactions';
import servicesReducer from './services';
import accountReducer from './account';
import facilityReducer from './facility';
import purchaseOrderReducer from './purchase-order'

const rootReducer = combineReducers({
  app: appReducer,
  facility: facilityReducer,
  auth: authReducer,
  records: recordsReducer,
  pharmacy: pharmReducer,
  transactions: transactionsReducer,
  services: servicesReducer,
  account: accountReducer,
  purchaseOrder: purchaseOrderReducer
});

export default rootReducer;

// dcloud
// !dicom@@2020
// Server=tcp:sqlserveri5c2oodqaad4q.database.windows.net,1433;Initial Catalog=DICOMcloud-db;Persist Security Info=False;User ID=dcloud;Password={your_password};MultipleActiveResultSets=False;Encrypt=True;TrustServerCertificate=False;Connection Timeout=30;

import React, { useState } from 'react'
import {Card,CardHeader,CardBody,Button,Row,Col} from 'reactstrap'
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import {useSelector} from 'react-redux'
import { apiURL } from "../../../redux/actions";
import CustomButton from "../../comp/components/Button";
import {_customNotify} from "../../utils/helpers";
import moment from 'moment'
import './excel.css'

function ImportPurchaseOrder ({ history }) {
	const [errorMessage, setErrorMessage] = useState('')
	const [cols, setCols] = useState([])
	const [rows, setRows] = useState([])
	const [finalData, setFinalData] = useState({})
	const [formattedRows, setFormattedRows] = useState([])
	const [loading, setLoading] = useState(false)

	const user = useSelector(state => state.auth.user)

	const checkFile = (file) => {
	    let errorMessage = "";
	    if (!file || !file[0]) {
	      return;
	    }
	    const isExcel =
	      file[0].type === "application/vnd.ms-excel" ||
	      file[0].type ===
	        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
	    if (!isExcel) {
	      errorMessage = "You can only upload Excel file!";
	    }
	    console.log("file", file[0].type);
	    const isLt2M = file[0].size / 1024 / 1024 < 2;
	    if (!isLt2M) {
	      errorMessage = "File must be smaller than 2MB!";
	    }
	    console.log("errorMessage", errorMessage);
	    return errorMessage;
	  }

	const fileHandler = event => {
	    let fileObj = event.target.files[0];;
		console.log("fileObj", fileObj);
	    if (!fileObj) {
	      setErrorMessage("No file uploaded!");
	      return false;
	    }
	    console.log("fileObj.type:", fileObj.type);
	    if (
	      !(
	        fileObj.type === "application/vnd.ms-excel" ||
	        fileObj.type ===
	          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
	      )
	    ) {
	      setErrorMessage("Unknown file format. Only Excel files are uploaded!");
	      return false;
	    }
	    //just pass the fileObj as parameter
	    ExcelRenderer(fileObj, (err, resp) => {
	      if (err) {
	        console.log(err);
	      } else {
	        let newRows = [];
	        resp.rows.slice(1).map((row, index) => {
	          if (row && row !== "undefined") {
	            newRows.push({
	              key: index,
	              date: row[0],
	              item_name: row[1],
	              item_code: row[2],
	              item_category: row[3],
	              price: row[4],
	              propose_quantity: row[5],
	              vendor: row[6],
	            });
	          }
	        });
	        if (newRows.length === 0) {
	          setErrorMessage("No data found in file!");
	          return false;
	        } else {
	        
	          setCols(resp.cols)
	          setRows(resp.rows)
	          setFormattedRows(newRows)
	          let ff = formatData(newRows)
	          setFinalData(ff)
	          setErrorMessage(null)
	        }
	      }
	    });
	    return false;
	}

	const formatData = list => {
		if(list.length){
			let totalAmount = list.reduce((a,b) => a + parseFloat(b.propose_quantity)*parseFloat(b.price), 0) || 0
			let firstItem = list[0]
			let formTitle = {
				PONo: "",
				auditor_remark: "",
				client: "",
				date: moment(firstItem.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
				exchange_rate: 1,
				exchange_type: "",
				general_remark: "",
				process_by: "admin-user",
				supplier_code: "39",
				total: totalAmount,
				type: "Local",
				vendor: firstItem.vendor
			}

			let tableData = [];

			list.forEach(item => {
				tableData.push({
					exchange_rate: 1,
					exchange_type: "",
					expired_status: "false",
					identifier: "new",
					item_category: item.item_category,
					item_code: item.item_code,
					item_name: item.item_name,
					po_id: "",
					price: item.price,
					propose_amount: parseFloat(item.propose_quantity) * parseFloat(item.price),
					propose_quantity: item.propose_quantity,
					quantity_available: null,
					remarks: "",
					remarks_id: 1,
					specification: "",
					status: "pending",
					type: "Local",
				})
			})

			return {
				formTitle,
				tableData,
				userId: user.id,
				facilityId: user.facilityId
			}
		} else {
			return {}
		}
	}

	const handleSubmit = () => {
		setLoading(true)
		// console.log(finalData)
		fetch(`${apiURL()}/account/add/purchase-order-new`, {
	      method: "POST",
	      headers: { "Content-Type": "application/json" },
	      body: JSON.stringify(finalData),
	    })
	      .then((raw) => raw.json())
	      .then((resp) => {
	        // newEvent("db-update:purchase-order", "WELLOLO");
	        // submitAdditionalExpenses(resp.PONo);
	        _customNotify("Purchase Order sent successfully!");
	        // getAllPurchases();
	        history.push("/me/purchase-order/make-purchases/");
	        setLoading(false);
	        // setTableData([]);
	        // handleSubmitRemarks("Initiator");
	      })
	      .catch((err) => setLoading(false));
	}

	return (
		<Card>
			<CardHeader>Import Purchase Order</CardHeader>
			<CardBody>
				<Row>
					<Col>
						<input type="file" onChange={fileHandler} style={{"padding":"10px"}} />
					</Col>
					<Col>
						<a 
							href={require('./Sample-Purchase-Order.xlsx')}
			              target="_blank"
			              rel="noopener noreferrer"
			              download>Download Sample Excel File</a>
					</Col>
				</Row>
				
				<div>
				{/*JSON.stringify({  finalData })*/}
				<OutTable data={rows} columns={cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
				</div>
				<CustomButton disabled={!rows.length} loading={loading} onClick={handleSubmit}>Submit</CustomButton>

			</CardBody>
		</Card>
	)
}

export default ImportPurchaseOrder
import React from "react";
import { Pane, Checkbox } from "evergreen-ui";

export default function CheckBoxItem({ label, name, handleCheck, value = "" }) {
  return (
    <Pane>
      <Checkbox
        checked={value.includes(label)}
        label={label}
        onChange={({ target }) => {
          handleCheck(name, target.checked, label);
        }}
      />
    </Pane>
  );
}

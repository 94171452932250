import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Table, Card, CardBody, CardHeader, Button, Spinner } from "reactstrap";
import { apiURL } from "../../redux/actions";
import { getRequestList } from "../../redux/actions/account";
import {
  _fetchApi,
  _fetchApiGeneral,
  _postApi,
  _updateApi,
} from "../../redux/actions/api";
import { getReqBranches } from "../../redux/actions/pharmacy";
import BackButton from "../comp/components/BackButton";
import Loading from "../comp/components/Loading";
import CustomerCreditDetails from "../pharmacy/pending-requests/CustomerCreditDetails";
import { _customNotify, generateReceiptNo } from "../utils/helpers";
import useQuery from "../utils/UseQuery";
import { getRequisitionNextStatus } from "./helpers";
import moment from "moment";

export default function PendingReqList({ getAllItemsBySub = (f) => f }) {
  const today = moment().format("YYYY-MM-DD");
  const query = useQuery();
  let requisition_no = query.get("requisition_no");
  const req_status = query.get("status");
  let branch_name = query.get("shopName");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user.role);
  const [inventoryItem, setInventoryItem] = useState([]);
  const shopBal = useSelector((s) => s.pharmacy.shopBal);

  const [loading, setLoading] = useState(false);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [nextStatus, setNextStatus] = useState(null);
  const [customerDetails, setCustomerDetails] = useState({});
  const [trn, setTrn] = useState(0);

  useEffect(() => {
    let grn = "trn";
    _fetchApi(
      `${apiURL()}/get/number/generator/${grn}`,
      (data) => {
        if (data.success) {
          setTrn(data.results.trn);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const allBranchReq =
    useSelector((state) => state.pharmacy.branchRegItem) || [];
  const [list, setList] = useState([]);

  const history = useHistory();

  const getInventory = () => {
    setLoadingInventory(true);
    _fetchApi(
      `${apiURL()}/account/new/item_chart-dashboard`,
      (data) => {
        setLoadingInventory(false);
        let finalData = data.results.map((i) => ({
          ...i,
          new_price: i.sale_price,
          new_balance: i.available_quantity,
          editMode: false,
        }));
        setInventoryItem(finalData);
      },
      (e) => {
        setLoadingInventory(false);
        console.log(e);
      }
    );
  };

  useEffect(() => {
    getInventory();
  }, []);

  const getAllRegNow = useCallback(() => {
    setLoading(true);
    const callback = (d) => {
      setLoading(false);
      setList(d);
    };

    dispatch(getReqBranches(null, requisition_no, "all-list", callback));
    //   setLoading(true);
  }, [requisition_no]);

  const updateRequestStatus = (status, item, query_type) => {
    _updateApi(
      `${apiURL()}/account/branch_req_requisition/single-update`,
      {
        req_status: status,
        requisition_no: item.requisition_no,
        query_type,
      },
      () => {
        console.log("success");
      },
      (err) => console.log(err)
    );
  };

  const getNextStatus = useCallback(() => {
    getRequisitionNextStatus(req_status, user, (next) => setNextStatus(next));
  }, [req_status, user]);

  // const approveRequest = () => {

  // }

  const approvingStatus = () => {
    generateReceiptNo((receiptsn, receiptno) => {
      const newArray = [];
      setLoading(true);
      let finalStat = "normal";

      let acc =
        customerDetails && customerDetails.acct ? customerDetails.acct : "";

      list.forEach((item) => {
        // const query = new URLSearchParams(this.props.location.search)
        const page_type = query.get("page_type");
        const outletErpId = customerDetails.erp_customer_id;
        const storeName = customerDetails.accName;
        let status = page_type === "transfer" ? "Received" : "Agent Approved";
        if (item.status === "Incomplete") {
          finalStat = "Incomplete";
        }
        // console.log(item.quantity, item.quantity_requested, finalStat)
        newArray.push({
          receive_date: today,
          item_name: item.item_name,
          po_no: item.po_no,
          qty_in: 0,
          qty_out: item.approved_qty,
          unit: item.unit,
          store_type: "STORE",
          grm_no: item.requisition_no,
          expiring_date: item.expiryDate,
          mark_up: item.markUp,
          selling_price: item.unitPrice,
          transfer_from: "STORE",
          transfer_to: storeName,
          query_type: "transfer",
          branch_name: storeName,
          unit_price: item.unitPrice,
          status,
          trn: trn,
          item_code: item.erpSkuId,
          requisition_no: item.requisition_no,
          amount: item.approved_qty * item.unitPrice,
          modeOfPayment: "Deposit",
          accountNo: acc,
          receiptsn,
          receiptno,
          transactionType: "deposit",
          item_category: item.item_category,
          invoiceNo: item.invoiceNo,
          outletErpId,
          req_status: "pending",
        });

        approveStatus(
          requisition_no,
          item.item_name,
          item.approved_qty,
          item.quantity
        );
      });
      let data = {
        newArray,
        trn: trn,
        // trn: this.state.trn,
      };

      _postApi(
        `${apiURL()}/account/add-new/tranfer`,
        data,
        () => {
          _updateApi(
            `${apiURL()}/account/branch_req_requisition/update`,
            {
              req_status: "Approved",
              user,
              items: list,
              branch_name,
              // query_type,
            },
            (success) => {
              // let query_type = req_status.includes('Incomplete')
              //   ? 'Incomplete'
              //   : 'normal'
              // updateRequestStatus('Approved', list[0], query_type)
              // _customNotify('Request Approved!')
              // // getAllItemsBySub(user);
              // dispatch(getRequestList())
              // history.push('/me/auditor/pending-request')
            },
            (err) => {
              console.log(err);
            }
          );
          let query_type = req_status.includes("Incomplete")
            ? "Incomplete"
            : "normal";

          updateRequestStatus("Approved", list[0], query_type);
          _customNotify("Order Approved!");
          // getAllItemsBySub(user);
          dispatch(getRequestList());
          history.push("/me/auditor/pending-request");
        },
        (e) => {
          console.log(e);
        }
      );
    });

    _updateApi(
      `${apiURL()}/account/branch_req_requisition/update`,
      {
        req_status: "Approved",
        user,
        items: list,
        branch_name,
        // query_type,
      },
      (success) => {
        let query_type = req_status.includes("Incomplete")
          ? "Incomplete"
          : "normal";
        updateRequestStatus("Approved", list[0], query_type);
        _customNotify("Request Approved!");
        // getAllItemsBySub(user);
        dispatch(getRequestList());
        history.push("/me/auditor/pending-request");
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const approveStatus = (
    requisition_no,
    item_name,
    approved_qty,
    quantity_requested
  ) => {
    // "Approved";
    let status = "Approved";
    // if (parseFloat(quantity_requested) > parseFloat(approved_qty)) {
    //   status = "Incomplete";
    // } else {
    //   status = "Approved";
    // }

    _updateApi(
      `${apiURL()}/account/update/req_branch/${status}/${requisition_no}?item_name=${item_name}&approved_qty=${approved_qty}`,
      {},
      (success) => {
        this.props.getRequisitionList();
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getAllRegNow();
    getNextStatus();
  }, [getAllRegNow, getNextStatus]);

  return (
    <>
      <BackButton type="link" />
      {/* {JSON.stringify({ list })} */}

      <center>
        <h4>Shop name: {branch_name}</h4>
      </center>
      <div className="my-2">
        <CustomerCreditDetails
          canClick={true}
          customer={branch_name}
          setCustomerDetails={(v) => setCustomerDetails(v)}
        />
      </div>

      <center>
        {(loading || loadingInventory) && (
          <span>
            <Spinner size={"sm"} /> Please wait...
          </span>
        )}
      </center>
      <Table bordered striped>
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Item</th>
            <th>Unit Price</th>
            <th>Qty Requested</th>
            <th>Qty Available</th>
            <th>Qty Approved</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, i) => {
            let catInfo =
              inventoryItem.find((a) => a.default_code === item.erpSkuId) || {};
            return (
              <tr key={i}>
                <td>{item.item_name}</td>
                <td>
                  {catInfo && catInfo.product_categ_id
                    ? catInfo.product_categ_id[1]
                    : item.item_category}
                </td>
                <td className="text-right">{catInfo.sale_price}</td>
                <td className="text-right">{item.quantity}</td>
                <td className="text-right">{catInfo.available_quantity}</td>
                <td>
                  <input
                    className="form-control"
                    type="number"
                    value={item.approved_qty}
                    onChange={({ target: { value } }) =>
                      setList((p) =>
                        p.map((a) =>
                          a.item_name === item.item_name
                            ? { ...a, approved_qty: value }
                            : a
                        )
                      )
                    }
                  />
                </td>
                {/* <td className="text-right">{parseInt(item.approved_qty) * parseFloat(item.sale_price)}</td> */}
                {/* {user === "Dispatch" || user === "Admin" ? ( */}
                {/* <td>{item.approved_qty}</td> */}
                {/* // ) : null} */}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <center>
        {/* {user === "Sale Agent" ? ( */}
        {nextStatus && (
          <Button
            color="warning"
            className="pr-5 pl-5"
            onClick={() => {
              approvingStatus();
            }}
          >
            Approve Request
          </Button>
        )}
        {/* ) : user === "Warehouse Manager" ? (
          <>
            <Button
              color="success"
              className="pr-5 pl-5 m-1"
              onClick={() => {
                approvingStatus("Completed");
              }}
            >
              Receipt Confirmed
            </Button>
          </>
        ) : user === "Dispatch" ? (
          <>
            {req_status === "Dispatched" ? (
              <Button
                color="success"
                className="pr-5 pl-5 m-1"
                onClick={() => {
                  approvingStatus("Delivered");
                }}
              >
                Goods Delivered
              </Button>
            ) : (
              <Button
                color="warning"
                className="pr-5 pl-5 m-1"
                onClick={() => {
                  approvingStatus("Dispatched");
                }}
              >
                Dispatch Goods
              </Button>
            )}
          </>
        ) : null} */}
      </center>
    </>
  );
}

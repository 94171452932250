import {
  GETTING_FACILITY_INFO,
  GET_FACILITY_INFO,
} from "../actions/actionTypes";

const initialState = {
  loadingInfo: false,
  info: {
    facility_id: "d8d7a732-1832-4e25-9a98-e68ddc3f0b26",
    facility_name: "Sanda Tireda LTD",
    code: "",
    logo:
      "https://res.cloudinary.com/emaitee/image/upload/v1590845025/logo1.png",
    type: "factory",
    admin: "admin",
  },
};

function facilityReducer(state = initialState, action) {
  switch (action.type) {
    case GETTING_FACILITY_INFO:
      return {
        ...state,
        loadingInfo: !state.loadingInfo,
      };
    case GET_FACILITY_INFO:
      return {
        ...state,
        info: action.payload,
      };
    default:
      return state;
  }
}

export default facilityReducer;
